import React from "react";
import clsx from "clsx";

export default function Tabs({ tabs }) {
  return (
    <div className="tabs">
      <ol className="tab-list">
        {tabs.map(({ onClick, isActive, label }, index) => (
          <li
            className={clsx(
              isActive ? "font-medium text-white cursor-pointer inline-block leading-10 text-sm" : "tab-item",
              index > 0 && "ml-3"
            )}
            style={!isActive ? {} : {
              boxShadow: "#fff 0px -2px 0px 0px inset",
            }}
            onClick={onClick}
            key={`tab-${label}-${index}`}
          >
            {label}
          </li>
        ))}
      </ol>
    </div>
  );
}

import React, { useState } from "react";
import { withClickmap, Clickmap, Areas } from "../Clickmaps";
import Loader from "../../Loader";
import Tabs from "./Tabs";
import { ClicksMapLayerTabs, tabsLabels } from './ClicksMapLayerTabs';

function ClickViews({
  clickmap,
  updateClickmapAreas,
  responses,
  imageUrl,
  loading,
}) {
  const [activeTab, setActiveTab] = useState(tabsLabels.HEATMAP);

  const tabs = Object.entries(tabsLabels).map(([, label]) => ({
    onClick: () => setActiveTab(label),
    isActive: activeTab === label,
    label
  }));

  if (loading) {
    return (
      <div className="flex">
        <div className="flex-auto rounded-2xl bg-gray-800 p-6">
          <Tabs tabs={tabs} />
          <div className="p-6">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="clickviews-container flex">
      <div className="rounded-2xl bg-gray-800 p-8 w-4/5">
        <Tabs tabs={tabs} />
        <div className="clickviews__clickmap pt-6">
          <Clickmap
            width={clickmap.width}
            height={clickmap.height}
            areas={clickmap.areas}
            updateAreas={updateClickmapAreas}
            responses={responses}
            imageUrl={imageUrl}
            activeTab={activeTab}
            clicksViewComponent={ClicksMapLayerTabs}
          />
        </div>
      </div>
      <div className="clickviews__areas flex-none px-6 w-2/5 max-w-[230px] min-w-[230px]">
        <Areas
          areas={clickmap.areas}
          updateAreas={updateClickmapAreas}
          responses={responses}
          width={clickmap.width}
          height={clickmap.height}
        />
      </div>
    </div>
  );
}

export default withClickmap(ClickViews);

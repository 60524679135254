import { actionTypes, storageName } from "./components/Report/Clickmaps/STORAGE";
import lodash from "lodash";
import _ from 'lodash';

export default function reducer(state, action) {
  switch (action.type) {
    case actionTypes.GET: {
      return {
        ...state,
        [storageName]: {
          [action.payload.ownerId]: action.payload,
        },
      };
    }
    case "UPDATE_CREDITS": {
      return {
        ...state,
        credits: {
          ...state.credits,
          ...action.payload,
        },
      };
    }
    case "GET_USER_DATA": {
      return {
        ...state,
        user: action.payload.user,
        tests: action.payload.tests,
        folders: action.payload.folders,
        credits: action.payload.credits,
        subscription: action.payload.subscription,
      };
    }
    case "SET_USER_DATA": {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
        },
      };
    }
    case "GET_TEST_CONTENT": {
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            content: action.payload.content,
          },
        },
      };
    }
    case "GET_TEST": {
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...action.payload.data,
          },
        },
      };
    }
    case "GET_TESTS": {
      return {
        ...state,
        tests: {
          ...state.tests,
          ...action.payload.tests,
        },
      };
    }
    case "GET_PUBLISHED_TEST_CONTENT": {
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            publishedContent: action.payload.content,
          },
        },
      };
    }
    case "UPLOAD_CONTENT_IMAGE": {
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            content: {
              ...state.tests[action.payload.testId].content,
              [action.payload.blockId]: {
                ...state.tests[action.payload.testId].content[
                action.payload.blockId
                ],
                image: action.payload.imageUrl,
              },
            },
          },
        },
      };
    }

    case "DELETE_CONTENT_IMAGE": {
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            content: {
              ...state.tests[action.payload.testId].content,
              [action.payload.blockId]: {
                ...state.tests[action.payload.testId].content[
                action.payload.blockId
                ],
                image: "",
              },
            },
          },
        },
      };
    }

    // TODO

    case "ADD_CONTENT_BLOCK": {
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            content: [
              ...state.tests[action.payload.testId].content,
              action.payload.data,
            ],
          },
        },
      };
    }

    case "DELETE_CONTENT_BLOCK": {
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            content: action.payload.updatedContent,
          },
        },
      };
    }

    case "UPDATE_CONTENT_BLOCK": {
      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            content: action.payload.updatedContent,
          },
        },
      };
    }

    case "UPDATE_TEST": {
      if (!state.tests[action.payload.testId]) {
        return state;
      }

      const subscription = lodash.cloneDeep(state.subscription);
      const currentTest = state.tests[action.payload.testId];
      const newTest = action.payload.data;

      if (
        newTest.answersCounter !== undefined &&
        newTest.answersCounter !== currentTest.answersCounter &&
        subscription.usage.answers
      ) {
        subscription.usage.answers.usage +=
          newTest.answersCounter - currentTest.answersCounter;
      }

      if (
        newTest.status === "deleted" &&
        newTest.status !== currentTest.status &&
        subscription.usage.tests
      ) {
        subscription.usage.tests.usage -= 1;
      }

      return {
        ...state,
        subscription,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            ...action.payload.data,
          },
        },
      };
    }

    case "UPDATE_FOLDER": {
      if (!state.folders[action.payload.folderId]) {
        return state;
      }

      return {
        ...state,
        folders: {
          ...state.folders,
          [action.payload.folderId]: {
            ...state.tests[action.payload.folderId],
            ...action.payload.data,
          },
        },
      };
    }

    case "DELETE_ANSWER": {
      if (!state.tests[action.payload.testId]) {
        return state;
      }

      const testHasDeletedAnswersArray =
        state.tests[action.payload.testId].deletedAnswers;

      return {
        ...state,
        tests: {
          ...state.tests,
          [action.payload.testId]: {
            ...state.tests[action.payload.testId],
            deletedAnswers: [
              ...(testHasDeletedAnswersArray
                ? state.tests[action.payload.testId].deletedAnswers
                : []),
              action.payload.answerId,
            ],
          },
        },
      };
    }

    case "CREATE_TEST": {
      const subscription = lodash.cloneDeep(state.subscription);

      if (subscription.usage.tests) {
        subscription.usage.tests.usage += 1;
      }

      return {
        ...state,
        subscription,
        tests: {
          ...state.tests,
          ...action.payload.data,
        },
      };
    }

    case "CREATE_SPLIT_GROUP": {
      return {
        ...state,
        splitGroups: [
          ...state.splitGroups,
          action.payload.data,
        ],
      };
    }

    case "UPDATE_SPLIT_GROUP": {
      return {
        ...state,
        splitGroups: [
          ...state.splitGroups.filter(s => s.id !== action.payload.data?.id),
          action.payload.data,
        ],
      };
    }

    case "DELETE_SPLIT_GROUP": {
      const subscription = lodash.cloneDeep(state.subscription);
      const deletedTestsIds = Object.entries(state.tests).filter(([id, t]) => t.splitGroup?.id === action.payload.data).map(([id, t]) => id);

      if (subscription.usage.tests) {
        subscription.usage.tests.usage -= deletedTestsIds.length;
      }

      return {
        ...state,
        tests: _.omit(state.tests, deletedTestsIds),
        splitGroups: [
          ...state.splitGroups.filter(s => s.id !== action.payload.data)
        ],
      };
    }

    case "GET_SPLIT_GROUP": {
      return {
        ...state,
        splitGroups: [
          ...state.splitGroups.filter(s => s.id !== action.payload.data?.id),
          action.payload.data,
        ],
      };
    }
    case "GET_SPLIT_GROUPS": {
      return {
        ...state,
        splitGroups: [...action.payload.data],
      };
    }

    case "CREATE_FOLDER": {
      return {
        ...state,
        folders: {
          ...state.folders,
          ...action.payload.data,
        },
      };
    }

    case "DELETE_FOLDER": {
      const subscription = lodash.cloneDeep(state.subscription);
      const newFolders = lodash.cloneDeep(state.folders);

      delete newFolders[action.payload.folderId];

      let numOfDeletedTests = 0;
      Object.keys(state.tests).forEach((testId) => {
        if (state.tests[testId].folderId === action.payload.folderId) {
          numOfDeletedTests++;
        }
      });

      if (subscription.usage.tests) {
        subscription.usage.tests.usage -= numOfDeletedTests;
      }

      return {
        ...state,
        subscription,
        folders: newFolders,
      };
    }

    case "SET_NOTIFICATION": {
      return {
        ...state,
        app: {
          ...state.app,
          notification: {
            type: action.payload.type,
            text: action.payload.text,
          },
        },
      };
    }

    case "SET_UI": {
      return {
        ...state,
        app: {
          ...state.app,
          ui: {
            ...state.app.ui,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    }

    case "GET_TEAM": {
      return {
        ...state,
        team: {
          ...state.team,
          ...action.payload.team,
        },
      };
    }

    case "UPDATE_ROLE": {
      const newTeamMembers = [...state.team.teamMembers];
      newTeamMembers.find(
        (member) => member.id === action.payload.memberId
      ).role = action.payload.role;

      return {
        ...state,
        team: {
          ...state.team,
          teamMembers: newTeamMembers,
        },
      };
    }

    case "REMOVE_MEMBER": {
      const newTeamMembers = [...state.team.teamMembers];

      return {
        ...state,
        team: {
          ...state.team,
          teamMembers: newTeamMembers.filter(
            (member) => member.id !== action.payload.memberId
          ),
        },
      };
    }

    case "UPDATE_TEAM": {
      return {
        ...state,
        team: {
          ...state.team,
          [action.payload.field]: action.payload.value,
        },
      };
    }

    default:
      return state;
  }
}

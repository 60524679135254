import React, { useContext, useEffect, useState } from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

import appContext from "../AppContext";

import { setNotification } from "../actions";

const Toast = () => {
  const { state, dispatch } = useContext(appContext);
  const [isFading, setIsFading] = useState(false);

  const text = state.app.notification.text;
  const type = state.app.notification.type;

  useEffect(() => {
    if (text) {
      setTimeout(() => {
        setIsFading(true);
        setTimeout(() => {
          dispatch(setNotification(null, null));
          setIsFading(false);
        }, 300);
      }, 3000);
    }
  }, [text]);

  if (!text) {
    return null;
  }

  return (
    <div
      style={{ zIndex: "99990", transform: "translate(-50%, 0)", left: "50%" }}
      className={clsx(
        "toast fixed bottom-8 text-white rounded-full p-8 font-medium",
        isFading && "fadeToast",
        !isFading && "toast",
        type === "success" && "bg-green-400",
        type === "info" && "bg-black",
        type === "error" && "bg-red-600",
        type === "warning" && "bg-orange-600"
      )}
    >
      {text}
    </div>
  );
};

export default Toast;

import React, { useEffect } from "react";
import { locale, interfaceText } from "../../helpers";
import { ReactComponent as IconHeart } from "../../icons/heart.svg";
import { pushToGTM } from "../../utils/gtm";
import Button from "../../components/Button";

export const CodeBlock = ({ verificationCode }) => {
  return (
    <div className="verification-code mt-6">
      <div className="verification-code__title text-sm text-gray-600">
        {interfaceText.verificationCode[locale]}
      </div>
      <div className="mt-2 p-4 text-lg rounded-md border-gray-300 border-2 inline-block">
        {verificationCode}
      </div>
    </div>
  );
};

const FinalStep = ({ verificationCode, testId, redirectUrl }) => {
  useEffect(() => {
    pushToGTM({
      event: "thankYouScreen",
      testId,
    });
  }, []);

  return (
    <div className="container mx-auto h-full flex justify-center items-center">
      <div className="text-center" id="successMessage">
        <div className="mb-4 text-4xl mx-auto">
          <IconHeart className="mx-auto" />
        </div>
        <div className="mb-4 text-3xl tracking-tighter">
          {interfaceText.thankYou[locale]}
        </div>
        {verificationCode && <CodeBlock verificationCode={verificationCode} />}
        {redirectUrl && (
          <div className="mt-6 flex justify-center w-full">
            <a
              href={redirectUrl}
              rel="nofollow noreferrer"
              className="object-center block rounded-full transition-all font-medium duration-75 ease-in cursor-pointer tracking-tight bg-blue-600 text-white hover:bg-blue-700 py-1 px-3 text-xl px-4"
            >
              {interfaceText.thankYouButton[locale]}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalStep;

import React from "react";
import { IconDelete } from "../../../icons";
import lodash from "lodash";
import { IClickmapProps } from './Clickmap';
import { isWithin } from './Utils';
import { IClick } from './Models';
import { IArea } from './STORAGE';

export type FigmaAreasProps = Pick<IClickmapProps, 'responses' | 'areas' | 'updateAreas'>;

export interface AreaWithTargetClicks extends IArea {
  targetClicks?: IClick[];
  missedClicks?: IClick[];
  testers?: IClick[];
  testersShare?: number;
}

export default function FigmaAreas({ responses, areas, updateAreas }: FigmaAreasProps) {

  const testersNum = lodash.uniqBy(responses, response => response.answerId).length;

  const areasWithWithTargetClicks: AreaWithTargetClicks[] = areas.map(a => ({ ...a }));

  responses.forEach(response => {
    const { clickData } = response;
    const clickX = clickData.left;
    const clickY = clickData.top;

    areasWithWithTargetClicks.forEach(area => {
      if (!area.clicksCount) { area.clicksCount = 0; }

      const areaMinX = area.left;
      const areaMaxX = area.left + area.width;
      const areaMinY = area.top;
      const areaMaxY = area.top + area.height;

      if (isWithin(clickX, areaMinX, areaMaxX) && isWithin(clickY, areaMinY, areaMaxY)) {
        if (!area.targetClicks) area.targetClicks = [];
        area.targetClicks?.push(response);
      }

    });
  });

  areasWithWithTargetClicks.forEach(area => {
    area.missedClicks = lodash.filter(area.targetClicks, ({ handled }) => !handled);
    area.testers = lodash.uniqBy(area.targetClicks, ({ answerId }) => answerId);
    area.testersShare = area.testers.length > 0 ? Math.round((area.testers.length / testersNum) * 100) : 0;
  })

  const removeArea = (index: number) => {
    const newAreas = areas.filter(a => a.index !== index).map((a, i) => ({ ...a, index: i + 1 }));
    updateAreas(newAreas);
  };

  return (
    <div>
      <div className="text-xl text-gray-600 tracking-tight">Click areas</div>

      {areasWithWithTargetClicks.length === 0 && (
        <div className="text-gray-600 mt-2 text-sm">
          Draw a rectangle on image to create a click area
        </div>
      )}

      {areasWithWithTargetClicks.map((area) => {

        return (
          <div className="flex items-start mt-3" key={area.index}>
            <div
              className="rounded-full bg-white text-gray-800 px-2 mr-3 text-center"
              style={{ minWidth: 32, lineHeight: "32px" }}
            >
              {area.index}
            </div>
            <div>
              <div>{area.targetClicks?.length} {area.targetClicks?.length == 1 ? 'click' : 'clicks'}</div>
              <div>{area.missedClicks?.length} {area.missedClicks?.length == 1 ? 'missclick' : 'missclicks'}</div>
              <div className="flex">
                <div className="mr-1">{area.testersShare ? `${area.testers?.length} of ${testersNum}` : area.testers?.length} testers</div>
                <div className="text-gray-600">({area.testersShare}%)</div>
              </div>
            </div>
            <div className="ml-auto">
              <IconDelete
                className={`transition duration-75 ease-in fill-current text-gray-600 cursor-pointer hover:text-gray-400`}
                onClick={() => removeArea(area.index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

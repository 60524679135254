import React, { useState, useEffect } from "react";
import NodeInHtml from "./NodeInHtml";
import lodash from "lodash";

const performScrollTo = ({ element, top, left }) => {
  element.scrollTo(left, top);

  /**
   * It's hotfix because of render bug in Safari
   */
  
  if (element.style.webkitTransform === "scale(1)") {
    element.style.webkitTransform = "translateZ(0)"
  } else {
    element.style.webkitTransform = "scale(1)";
  }
}

const FigmaTestView = ({
  onLoad,
  onClick,
  startNodeId,
  prototype: {
    nodesForHtml,
    images,
    settings: { imageScale },
  },
  className = "",
  style = {},
}) => {
  const [state, setState] = useState({
    activeNodeId: startNodeId,
  });

  const isActiveNode = (nodeId) => {
    return nodeId === state.activeNodeId;
  };

  const dropScrollPositions = (previousNodeId) => {
    const previousActiveNode = document.querySelector(
      `div[data-id="${previousNodeId}"]`
    );
    const previousScrollableNodes = previousActiveNode.querySelectorAll(
      'div[data-has-scroll="true"]'
    );
    if (previousScrollableNodes.length > 0) {
      previousScrollableNodes.forEach((scrollableNode) => {
        performScrollTo({
          element: scrollableNode.children[0], 
          top: 0, 
          left: 0
        });
      });
    }
  };

  const preserveScrollPositions = (destinationId) => {
    const matchNodes = (nodeA, nodeB) => {
      if (nodeA.getAttribute("data-name") === nodeB.getAttribute("data-name")) {
        return true;
      }
      if (
        nodeA.parentNode.style?.top === nodeB.parentNode.style?.top &&
        nodeA.parentNode.style?.left === nodeB.parentNode.style?.left &&
        nodeA.parentNode.style?.width === nodeB.parentNode.style?.width &&
        nodeA.parentNode.style?.height === nodeB.parentNode.style?.height
      ) {
        return true;
      }
      return false;
    };

    const currentActiveNode = document.querySelector(
      `div[data-id="${state.activeNodeId}"]`
    );

    const newActiveNode = document.querySelector(
      `div[data-id="${destinationId}"]`
    );

    const currentScrollableNodes = currentActiveNode.querySelectorAll(
      'div[data-has-scroll="true"]'
    );
    const newScrollableNodes = newActiveNode.querySelectorAll(
      'div[data-has-scroll="true"]'
    );
    
    if (currentScrollableNodes.length > 0 && newScrollableNodes.length > 0) {
      currentScrollableNodes.forEach((currentScrollableNode) => {
        newScrollableNodes.forEach((newScrollableNode) => {
          if (matchNodes(currentScrollableNode, newScrollableNode)) {
            performScrollTo({
              element: newScrollableNode.children[0], 
              top: currentScrollableNode.children[0].scrollTop, 
              left: currentScrollableNode.children[0].scrollLeft
            });
          }
        });
      });
    }
  };

  const setActiveNode = (activeNodeId) => {
    setState((state) =>
      lodash.merge(lodash.cloneDeep(state), { activeNodeId })
    );
  };

  useEffect(() => {
    const loadingImages = images.map(
      (url) =>
        new Promise((resolve) => {
          const image = new Image();
          image.onload = resolve;
          image.src = url;
        })
    );
    Promise.all(loadingImages).then(onLoad);
  }, []);

  return (
    <div className={["relative", className].join(" ")} style={style}>
      {lodash.values(nodesForHtml).map((node) => (
        <div
          key={`node-${node.id}`}
          style={{
            visibility: isActiveNode(node.id) ? "visible" : "hidden",
            zIndex: isActiveNode(node.id) ? 10 : 0,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <NodeInHtml
            display="fitWidth"
            onClick={(data) => {
              const currentNodeId = lodash.clone(state.activeNodeId);
              const destinationId = lodash.get(data, "action.destinationId");

              if (destinationId) {
                // preserve scroll positions
                const transitionType = lodash.get(
                  data, "action.transition.type"
                );
                const preserveScrollPosition = lodash.get(
                  data, "action.preserveScrollPosition"
                );

                if (
                  preserveScrollPosition ||
                  transitionType === "SMART_ANIMATE"
                ) {
                  preserveScrollPositions(destinationId);
                }

                // set new active node
                if (destinationId) setActiveNode(destinationId);

                // always dropping old scroll positions
                if (currentNodeId !== destinationId) {
                  dropScrollPositions(currentNodeId);
                }
              }

              onClick(data);
            }}
            imageScale={imageScale}
            node={node}
          />
        </div>
      ))}
    </div>
  );
};

export default FigmaTestView;

import { getAuthToken } from '../firebase';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS';

export async function makeAuthorizedRequest(url: string, method: HttpMethod = 'GET', body?: any, signal?: AbortSignal) {
	const authToken = await getAuthToken();
	return fetch(url, {
		method,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Barrier ${authToken}`,
		},
		body: typeof (body) === 'undefined' ? undefined : JSON.stringify(body),
		signal
	});
}

export async function makeRequest(url: string, method: HttpMethod = 'GET', body?: any, signal?: AbortSignal) {
	return fetch(url, {
		method,
		headers: {
			"Content-Type": "application/json",
		},
		body: typeof (body) === 'undefined' ? undefined : JSON.stringify(body),
		signal
	});
}
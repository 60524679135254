import React from "react";
import lodash from "lodash";

import NodeInHtml from "../../Figma/NodeInHtml";

const Preview = ({ image, name, onClick, width, settings, node }) => {
  if (node && settings) {
    return (
      <div 
        className="flex flex-nowrap rounded-md mr-2 overflow-hidden cursor-pointer transition-all duration-75 ease-in border-2 border-gray-300 hover:border-blue-600 box-content"
        style={{ width, height: width }}
        onClick={onClick}
      >
        <div 
          style={{
            transformOrigin: "0 0",
            transform: `scale(${width / node.width})`,
          }}
        >
          <NodeInHtml
            display="actualSize"
            imageScale={lodash.get(settings, "imageScale")}
            node={node}
          />
        </div>
      </div>
    );
  }
  
  return (
    <div className="flex flex-nowrap">
      <div
        className="overflow-hidden mr-2 rounded-md cursor-pointer transition-all duration-75 ease-in border-2 border-transparent hover:border-blue-600"
        style={{ width, height: width }}
        onClick={onClick}
      >
        <img src={image} alt={name} />
      </div>
    </div>
  );
}

export default Preview;

import React from "react";
import "../../tailwind.generated.css";

const FirstClickPointer = ({
  position,
  opacity,
  withPopover,
  popoverContentBlock,
}) => {
  const pointerPositionStyle = {
    left: position.left * 100 + "%",
    top: position.top * 100 + "%",
    transform: "translate3d(-50%, -50%, 0)",
  };

  const confirmationPopoverPosition = {
    left: pointerPositionStyle.left,
    top: `calc(${pointerPositionStyle.top} - 64px)`,
    transform: "translate3d(-50%, -50%, 0)",
  };

  return (
    <>
      <div
        style={pointerPositionStyle}
        className={`absolute bg-green-400 w-4 h-4 rounded-full z-999 opacity-${opacity}`}
        id="firstClickPointer"
      ></div>
      {withPopover && (
        <div
          style={confirmationPopoverPosition}
          className="absolute bg-white rounded-lg p-3 firstClickConfirmationPopover z-999"
        >
          {popoverContentBlock}
        </div>
      )}
    </>
  );
};

export default FirstClickPointer;

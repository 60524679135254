import React from "react";
import "../../tailwind.generated.css";

import Modal from "../Modal";
import Button from "../Button";
import { ReactComponent as SuccessIll } from "../../icons/figma_success.svg";

import { locale, interfaceText } from "../../helpers";

const Success = ({ onClick, isSuccessModalOpen, designConfig }) => {
  return (
    <Modal
      isOpen={isSuccessModalOpen}
      setIsOpen={() => {}}
      width="440px"
      disallowClickOutside
      backdrop
      backgroundColor="transparent"
      noRoudnedCorners
    >
      <div className="p-6 mx-auto flex flex-col justify-between h-full">
        <div>
          <SuccessIll className="mb-4" />
          <div className="flex text-3xl mb-2 font-bold font-display text-gray-200">
            {interfaceText.figmaTestingSuccess[locale].header}
          </div>
          <div className="mt-2 tracking-tighter text-gray-200">
            {interfaceText.figmaTestingSuccess[locale].text}
          </div>
        </div>
        <Button
          className="mt-4"
          name={interfaceText.figmaTestingSuccess[locale].button}
          handler={onClick}
          large
          type="primary"
        />
      </div>
    </Modal>
  );
};

export default Success;

import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  fetchTest,
  createAnswer,
  sendAnswer,
  aproveAnswer,
  fetchPrototype,
} from "../actions";
import { testValidator } from "../utils";
import { getIsTestCompleted, getRedirectUrl } from "../utils/lstorage";
import { locale, interfaceText } from "../helpers";

import ContentBlock from "./Test/ContentBlock";
import Loader from "./Loader";
import PageNotFound from "./PageNotFound";
import AnswersLimitExceeded from "./Test/AnswersLimitExceeded";
import FinalStep from "./Test/FinalStep";
import Button from "./Button";

import lodash from "lodash";
import lstorage from "store";

import { getFormattedPrototype } from "../utils/figma";
import {
  getTesterSource,
  getUrlParams,
  hasCustomPanelParams,
  getFormattedPanelUrl,
  getBlockLogic,
} from "../utils/tests";

const isTestPublished = (test, source) => {
  if (test.status === "published") {
    return true;
  }

  if (source !== "link") {
    return true;
  }

  return false;
}

const Test = ({ isPreview }) => {
  const { testId } = useParams();

  const source = getTesterSource();
  const urlParams = getUrlParams();
  const history = useHistory();

  const showVerificationCode = window.location.search.includes("code");
  const getVerificationCode = () => {
    if (!showVerificationCode) return undefined;
    const localStorageCode = lodash.get(lstorage.get(testId), "code");
    return localStorageCode ? localStorageCode : verificationCode;
  };

  const [asyncProcessesNum, setAsyncProcessesNum] = useState(0);
  const failedOperatons = useRef([]);

  const processOperation = async (run) => {
    // Increase processes counter
    setAsyncProcessesNum((asyncProcessesNum) => {
      return asyncProcessesNum + 1;
    });

    try {
      await run();
    } catch (error) {
      failedOperatons.current.push(() => {
        if (process.env.NODE_ENV === "production") {
          // TODO: Log error to Sentry
        } else {
          console.error(error);
        }

        processOperation(run);
      });
    }

    // Decrise processes counter
    setAsyncProcessesNum((asyncProcessesNum) => {
      return asyncProcessesNum - 1;
    });
  };

  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [isPublished, setIsPublished] = useState(null);
  const [progressBar, setProgressBar] = useState(null);
  const [answerId, setAnswerId] = useState(history.location?.state?.answer?.answerId);
  const [answerData, setAnswerData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState(null);
  const [designConfig, setDesignConfig] = useState({
    backgroundColor: null,
    buttonsColor: null,
    buttonsTextColor: null,
    textColor: null,
  });
  const [isAnswersLimitExceeded, setIsAnswersLimitExceeded] = useState(null);

  const isTestCompleted = getIsTestCompleted(testId);
  const customPanelRedirectUrl = useRef(getRedirectUrl(testId));

  const totalSteps = lodash.get(content, "length", -1) + 1;
  const isFinalStep =
    currentStep === totalSteps ||
    (isTestCompleted &&
      localStorage.getItem("isAdmin") !== "true" &&
      !isPreview);

  useEffect(() => {
    window.Intercom("update", {
      hide_default_launcher: true,
    });
    return () => {
      window.Intercom("update", {
        hide_default_launcher: false,
      });
    };
  }, []);

  useEffect(() => {
    processOperation(async () => {
      const sharingToken = new URLSearchParams(window.location.search).get("sharingToken");

      const fetchTestResponse = await fetchTest(testId, sharingToken);

      if (!fetchTestResponse.ok) {
        setIsLoading(false);
        if (fetchTestResponse.status >= 500) {
          throw "Something wrong with server";
        }
        return;
      }

      const test = await fetchTestResponse.json();

      setDesignConfig({
        backgroundColor: test.backgroundColor,
        buttonsColor: test.buttonsColor,
        buttonsTextColor: test.buttonsTextColor,
        textColor: test.textColor,
      });

      setIsPublished(isTestPublished(test, source));
      setProgressBar(test.settings?.progressBar);

      if (source === "customPanel" && hasCustomPanelParams(test)) {
        setContent([
          ...test.customPanelScreeningQuestions,
          ...test.publishedContent,
        ]);
      } else {
        setContent(isPreview ? test.content : test.publishedContent);
      }

      if (!isPreview && !answerId) {
        const createAnswerResponse = await createAnswer(testId, { source: source, }, urlParams);

        if (!createAnswerResponse.ok) {
          setIsLoading(false);
          throw "Answer creation is failed";
        }


        const answerData = await createAnswerResponse.json();
        setAnswerId(answerData.answerId);
        setVerificationCode(answerData.code);
        setIsAnswersLimitExceeded(test.isAnswersLimitExceeded);
      }

      setIsLoading(false);
      await new Promise((r) => setTimeout(r, 100));
    });
  }, [testId]);

  useEffect(() => {
    document.body.style.backgroundColor = designConfig.backgroundColor;
    return () => {
      document.body.style.backgroundColor = "#F5F5F5";
    };
  }, [designConfig]);

  useEffect(() => {
    if (
      (isFinalStep || (isAnswersLimitExceeded && showVerificationCode)) &&
      !isTestCompleted &&
      !isPreview
    ) {
      processOperation(async () => {
        if (showVerificationCode) {
          const aproveAnswerResponse = await aproveAnswer(answerId, testId, isAnswersLimitExceeded);
          if (!aproveAnswerResponse.ok) throw "Approval of the answer failed";
          lstorage.set(testId, { code: verificationCode });

        } else if (source === "customPanel") {
          const aproveAnswerResponse = await aproveAnswer(answerId, testId);
          if (!aproveAnswerResponse.ok) throw "Approval of the answer failed";
          const { redirectUrl } = await aproveAnswerResponse.json();

          const formattedRedirectUrl = getFormattedPanelUrl(redirectUrl);
          lstorage.set(testId, { code: "PASSED", redirectUrl: formattedRedirectUrl });
          customPanelRedirectUrl.current = formattedRedirectUrl;

        } else {
          lstorage.set(testId, { code: "PASSED" });
        }
      });
    }
  }, [currentStep, isAnswersLimitExceeded]);

  useEffect(() => {
    const { type, prototypeId, prototypeData } = lodash.get(
      content,
      [currentStep - 1],
      {}
    );

    if (type === "figma" && prototypeId && !prototypeData) {
      processOperation(async () => {
        const fetchPrototypeResponse = await fetchPrototype(prototypeId);

        if (!fetchPrototypeResponse.ok) throw "Unable to load the prototype";

        const prototypeData = getFormattedPrototype(
          await fetchPrototypeResponse.json()
        );
        setContent((content) => {
          const newContent = lodash.cloneDeep(content);
          newContent[currentStep - 1].prototypeData = prototypeData;
          return newContent;
        });
      });
    }
  }, [currentStep, content]);

  const getNextStep = (blockContent, blockAnswer) => {
    const logic = getBlockLogic(blockContent);
    const blockType = blockContent.type;
    const resolveSubcondition = (blockType, blockAnswer, condition, value) => {
      switch (condition) {
        case "contains":
          switch (blockType) {
            case "openquestion":
              return blockAnswer.textValue.includes(value);
            case "choice":
              return blockAnswer.selectedOptions.find(
                (option) => option.id === value
              )
                ? true
                : false;
            case "preference":
              return blockAnswer.selectedOptions.find(
                (option) => option.id === value
              )
                ? true
                : false;
          }
        case "doesNotContain":
          switch (blockType) {
            case "openquestion":
              return !blockAnswer.textValue.includes(value);
            case "choice":
              return blockAnswer.selectedOptions.find(
                (option) => option.id === value
              )
                ? false
                : true;
            case "preference":
              return blockAnswer.selectedOptions.find(
                (option) => option.id === value
              )
                ? false
                : true;
          }
      }
    };

    const logicBlockResult = (statement) => {
      return Boolean(
        statement.subconditions.reduce((acc, subcondition) => {
          const isConditionFulfilled = resolveSubcondition(
            blockType,
            blockAnswer,
            subcondition.condition,
            subcondition.value
          );

          if (subcondition.operator === "and") {
            return acc === undefined
              ? isConditionFulfilled
              : acc && isConditionFulfilled;
          } else {
            return acc === undefined
              ? isConditionFulfilled
              : acc || isConditionFulfilled;
          }
        }, undefined)
      );
    };

    const getBlockIndexById = (blockId) => {
      if (blockId === "thankyouscreen") {
        return content.length;
      }
      if (blockId === "nextquestion") {
        return getBlockIndexById(blockContent.blockId) + 1;
      }
      return content.findIndex((block) => block.blockId === blockId);
    };

    const resolvedStatementIndex = logic.statements.findIndex((statement) => {
      return logicBlockResult(statement);
    });

    return resolvedStatementIndex >= 0
      ? getBlockIndexById(logic.statements[resolvedStatementIndex].jumpTo) + 1
      : getBlockIndexById(logic.elseJumpTo) + 1;
  };

  const showNextBlock = (blockAnswer) => {
    const currentBlockContent = content[currentStep - 1];
    if (!getBlockLogic(currentBlockContent)) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(getNextStep(currentBlockContent, blockAnswer));
    }
  };

  const handleSendAnswer = (value, blockId, nextBlock = null) => processOperation(async () => {
      if (!isPreview) {
        const response = await sendAnswer(value, answerId, blockId, testId);
        if (!response.ok) throw "Invalid response";
      }

      setAnswerData((data) => ({ ...data, [blockId]: value }));

      if (nextBlock) {
        showNextBlock(nextBlock);
        await new Promise((r) => setTimeout(r, 100));
      }
  });

  // Loader
  if (asyncProcessesNum > 0 || isLoading) {
    return <Loader />;
  }

  // Repeat request screen
  if (failedOperatons.current.length > 0) {
    return (
      <div className="container mx-auto h-full flex justify-center items-center">
        <div className="text-center">
          <div className="mb-4 text-3xl tracking-tighter">
            {interfaceText.requestFailed[locale].header}
          </div>
          <Button
            name={interfaceText.requestFailed[locale].button}
            large
            className="inline-block"
            handler={() => {
              failedOperatons.current.forEach((fn) => fn());
              failedOperatons.current = [];
            }}
          />
        </div>
      </div>
    );
  }

  // If answers limit is exceeded
  if (isAnswersLimitExceeded) {
    return <AnswersLimitExceeded verificationCode={getVerificationCode()} />;
  }

  // If invalid :testId param received
  if (isPublished === null) {
    return <PageNotFound />;
  }

  // If trying to access an unpublished test
  if (!isPreview && !isPublished) {
    return <PageNotFound />;
  }

  // If trying to preview an invalid test
  if (!content || testValidator(content).isValid === false) {
    return <PageNotFound />;
  }

  if (isFinalStep) {
    return (
      <FinalStep
        redirectUrl={customPanelRedirectUrl.current}
        verificationCode={getVerificationCode()}
        testId={testId}
        answerId={answerId}
        answerData={answerData}
      />
    );
  }

  return (
    <ContentBlock
      withProgress={progressBar && totalSteps > 2}
      currentStep={currentStep}
      totalSteps={totalSteps}
      data={content[currentStep - 1]}
      blockId={content[currentStep - 1].blockId}
      testId={testId}
      showNextBlock={(blockAnswer) =>
        processOperation(async () => {
          showNextBlock(blockAnswer);
          await new Promise((r) => setTimeout(r, 100));
        })
      }
      answerId={answerId}
      isPreview={isPreview}
      designConfig={designConfig}
      respondentSource={source}
      sendAnswer={handleSendAnswer}
    />
  );
};

export default Test;

import React, { useState, useEffect } from "react";
import {
  auth,
  signInWithGoogle,
  signInWithFacebook,
  signInWithEmail,
} from "../firebase";
import { ReactComponent as GoogleIcon } from "../icons/google.svg";
import { ReactComponent as EmailIcon } from "../icons/email.svg";
import { ReactComponent as FacebookIcon } from "../icons/facebook.svg";
import PropTypes from "prop-types";
import Loader from "./Loader";
import Button from "./Button";
import { supportLink } from "../helpers";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { getRedirectUrl, addActualParamsToUrl } from "../utils";

import SignInLayout from "./Layouts/SignInLayout";

const loadingStatuses = {
  idle: "idle",
  loading: "loading",
  error: "error",
  success: "success",
};

const getButtonClassName = (params = {}) => {
  const signInButtonClassName =
    "my-2 py-2 px-5 rounded-full object-center mx-auto text-xl font-medium tracking-tight flex items-center transition-all duration-75 ease-in";
  if (!params.disabled)
    return (
      signInButtonClassName +
      " bg-white hover:bg-gray-100 text-gray-800 cursor-pointer"
    );
  else
    return (
      signInButtonClassName + " bg-gray-100 text-gray-600 cursor-not-allowed"
    );
};

const SignInButton = ({ type, onSuccess, onFailed }) => {
  const signInButtonClassName = getButtonClassName();

  if (type === "email") {
    return (
      <Link className={signInButtonClassName} to={addActualParamsToUrl("/login/with-email")}>
        <EmailIcon width={32} height={32} className="mr-2" /> Sign in with Email
      </Link>
    );
  }

  return (
    <button
      className={signInButtonClassName}
      onClick={() => {
        if (type === "google") {
          signInWithGoogle().then(onSuccess).catch(onFailed);
        }
        if (type === "facebook") {
          signInWithFacebook().then(onSuccess).catch(onFailed);
        }
      }}
    >
      {type === "google" && (
        <GoogleIcon width={24} height={24} className="mr-2" />
      )}
      {type === "google" && "Sign in with Google"}

      {type === "facebook" && (
        <FacebookIcon width={24} height={24} className="mr-2" />
      )}
      {type === "facebook" && "Sign in with Facebook"}
    </button>
  );
};

SignInButton.propTypes = {
  type: PropTypes.oneOf(["facebook", "google", "email"]),
};

const SignIn = ({ withEmail }) => {
  const [userEmail, setUserEmail] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(
    auth.isSignInWithEmailLink(window.location.href)
      ? loadingStatuses.loading
      : loadingStatuses.idle
  );

  useEffect(() => {
    if (!auth.isSignInWithEmailLink(window.location.href)) return;

    const email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      setLoadingStatus(loadingStatuses.error);
      return;
    }

    auth
      .signInWithEmailLink(email, window.location.href)
      .then(() => {
        window.localStorage.removeItem("emailForSignIn");
      })
      .catch((error) => {
        console.error(error);
        setLoadingStatus(loadingStatuses.error);
      });
  }, []);

  const buttonParams = {
    onSuccess: () => setLoadingStatus(loadingStatuses.loading),
    onFailed: () => setLoadingStatus(loadingStatuses.error),
  };

  // https://app.pthwy.design/login/with-email
  // apiKey=AIzaSyALXWX7d21iLZ6ZKL_pt7xOWJJXMOav8m0
  // oobCode=YUxLyPrV_kF7AvRGm5O89AcA1om_0RfoTYyj7FuEiXkAAAGBBdSGdQ
  // mode=signIn
  // lang=en

  if (withEmail) {
    return (
      <SignInLayout title="Sign in with Email" backUrl={addActualParamsToUrl("/login")}>
        {loadingStatus === loadingStatuses.idle && (
          <Formik
            initialValues={{ email: "" }}
            validate={({ email }) => {
              if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
                return { email: "Email is incorrect" };
              return {};
            }}
            onSubmit={({ email }) => {
              signInWithEmail(email, getRedirectUrl())
                .then(() => {
                  window.localStorage.setItem("emailForSignIn", email);
                  setUserEmail(email);
                  setLoadingStatus(loadingStatuses.success);
                })
                .catch((error) => {
                  console.error(error);
                  setLoadingStatus(loadingStatuses.error);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="max-w-xs mx-auto mb-6">
                  Enter the email address below, and we’ll send a magic link to
                  your inbox.
                </div>
                <input
                  name="email"
                  disabled={isSubmitting}
                  className="mb-2 text-2xl leading-tight px-1 py-2 text-center"
                  style={{ width: 280 }}
                  placeholder="Enter your email here"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <div className="mb-2 text-sm font-medium text-red-600">
                    {errors.email}
                  </div>
                )}
                <button
                  className={getButtonClassName({
                    disabled: (errors.email && touched.email) || isSubmitting,
                  })}
                  type="sumbit"
                >
                  {isSubmitting ? "Please, wait..." : "Continue"}
                </button>
              </form>
            )}
          </Formik>
        )}

        {loadingStatus === loadingStatuses.success && (
          <div className="max-w-xs text-center mx-auto">
            Click the link we sent to{" "}
            <span className="font-medium">{userEmail}</span> to sign in.
          </div>
        )}

        {loadingStatus === loadingStatuses.error && (
          <div>
            <div className="text-sm font-medium text-red-600 mb-4">
              Oops, something went wrong.
              <br />
              Please try again or contact us.
            </div>
            <button
              className={getButtonClassName()}
              onClick={() => setLoadingStatus(loadingStatuses.idle)}
            >
              Try again
            </button>
          </div>
        )}

        {loadingStatus === loadingStatuses.loading && (
          <div>
            <Loader />
          </div>
        )}
      </SignInLayout>
    );
  }

  return (
    <SignInLayout title="Sign in">
      {loadingStatus === loadingStatuses.idle && (
        <div className="mt-8 flex flex-col">
          <SignInButton type="google" {...buttonParams} />
          <SignInButton type="email" {...buttonParams} />
        </div>
      )}

      {loadingStatus === loadingStatuses.loading && (
        <div className="mt-8">
          <Loader />
        </div>
      )}

      {loadingStatus === loadingStatuses.error && (
        <div className="mt-8">
          <div className="mb-4 text-md tracking-tighter">
            Oops, something went wrong.
            <br />
            Please try again or{" "}
            <a
              className="border-b-2"
              href={supportLink}
              target="_blank"
              rel="noopener noreferrer"
              type="text"
            >
              contact us
            </a>
            .
          </div>
          <Button
            large
            name="Try again"
            className="inline-block"
            handler={() => setLoadingStatus(loadingStatuses.idle)}
          />
        </div>
      )}
    </SignInLayout>
  );
};

export default SignIn;

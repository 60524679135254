import lodash from "lodash";

export const getFigmaNodeKey = (nodeId, version, ...params) => {
  return [nodeId, version, ...params].join("-");
};

export const getNodeAsset = ([nodeId, version, ...params], source) => {
  const defaultValue = lodash.get(source, nodeId);
  return lodash.get(
    source,
    getFigmaNodeKey(nodeId, version, ...params),
    defaultValue
  );
};

export const getPrototypeUrl = (
  blockId,
  fileId,
  [nodeId, version, options]
) => {
  const scaling = lodash.get(options, "scaling", "scale-down");
  const embedUrl = `https://www.figma.com/proto/${fileId}/?node-id=${nodeId}&hide-ui=1&hotspot-hints=0&scaling=${scaling}&version-id=${version}&disable-default-keyboard-nav=1`;
  return `https://www.figma.com/embed?embed_host=${
    window.location.host
  }&url=${encodeURIComponent(embedUrl)}&pathwayBlockId=${blockId}`;
};

export const getNodeParams = ({
  absoluteBoundingBox,
  image,
  transitionNodeID,
  isFixed,
  constraints,
  children,
  ...rest
}) => {
  let width = absoluteBoundingBox.width;
  let height = absoluteBoundingBox.height;

  return {
    ...absoluteBoundingBox,
    ...constraints,
    width,
    height,
    image,
    transitionNodeID,
    isFixed,
    children,
    ...rest,
  };
};

export const parseNodesForHtml = (nodes, startNodeId) => {
  const nodesForHtml = {};
  const imagesForHtml = {};
  const paths = {};

  const nodesMap = lodash.keyBy(nodes, "id");

  const addToHtml = ({ children, ...node }, parent) => {
    const newNode = lodash.omit(lodash.cloneDeep(node), [
      "flowStartingPoints",
      "exportSettings",
      "background",
      "fills",
      "strokes",
      "rectangleCornerRadii",
      "relativeTransform",
      "layoutGrids",
      "effects",
    ]);

    newNode.fills = [];
    if (node.fills) {
      node.fills.forEach(({ type, visible, color }) => {
        if (type === "SOLID")
          newNode.fills.push(
            lodash.omitBy({ type, visible, color }, lodash.isUndefined)
          );
      });
    }

    if (parent) {
      if (parent.children) parent.children.push(newNode);
      else parent.children = [newNode];
      paths[node.id] = [
        ...paths[parent.id],
        "children",
        parent.children.length - 1,
      ];
    } else {
      nodesForHtml[node.id] = newNode;
      paths[node.id] = [node.id];
    }

    return newNode;
  };

  const addToImages = ({ id }) => {
    imagesForHtml[id] = [...paths[id], "image"];
  };

  const findTransitions = (node, parent) => {
    node.children.forEach((childrenNode) => {
      const { transitionNodeID, children } = childrenNode;
      const thisNode = getNodeParams(childrenNode);
      let parentLink = parent;

      if (parent.overflowDirection) {
        parentLink = addToHtml(childrenNode, parent);
        addToImages(childrenNode);
      } else if (thisNode.isFixed) {
        parentLink = addToHtml(childrenNode, parent);
      } else if (thisNode.transitionNodeID) {
        parentLink = addToHtml(childrenNode, parent);
      } else if (thisNode.overflowDirection) {
        parentLink = addToHtml(childrenNode, parent);
      }

      if (
        transitionNodeID &&
        !nodesForHtml[transitionNodeID] &&
        nodesMap[transitionNodeID]
      ) {
        const link = addToHtml(nodesMap[transitionNodeID]);
        addToImages(nodesMap[transitionNodeID]);

        findTransitions(nodesMap[transitionNodeID], link);
      }

      if (children) {
        findTransitions(childrenNode, parentLink);
      }
    });
  };

  const startNode = nodesMap[startNodeId];

  if (startNode) {
    const parent = addToHtml(startNode);
    addToImages(startNode);

    if (startNode.children) {
      findTransitions(startNode, parent);
    }
  }

  return {
    nodesForHtml,
    imagesForHtml,
  };
};

export const getOverflowParams = (node) => {
  const parent = getNodeParams(node);

  let x0 = parent.x;
  let x1 = parent.x + parent.width;
  let y0 = parent.y;
  let y1 = parent.y + parent.height;

  parent.children.forEach((node) => {
    const { x, y, width, height } = getNodeParams(node);
    x0 = Math.min(x0, x);
    x1 = Math.max(x1, x + width);
    y0 = Math.min(y0, y);
    y1 = Math.max(y1, y + height);
  });

  return {
    width: x1 - x0,
    height: y1 - y0,
    scrollLeft: parent.x - x0,
    scrollTop: parent.y - y0,
  };
};

const figmaFeatures = {
  display: {
    actualSize: {
      name: "displayActualSize",
      test: (block) => {
        return lodash.get(block, "prototypeOptions.scaling") === "min-zoom";
      },
    },
    fitToScreen: {
      name: "displayFitToScreen",
      test: (block) => {
        return lodash.get(block, "prototypeOptions.scaling") === "scale-down";
      },
    },
    fitWidth: {
      name: "displayFitWidth",
      test: (block) => {
        return (
          lodash.get(block, "prototypeOptions.scaling") === "scale-down-width"
        );
      },
    },
    fitSceeen: {
      name: "displayFitSceeen",
      test: (block) => {
        return lodash.get(block, "prototypeOptions.scaling") === "contain";
      },
    },
  },
  prototype: {
    device: {
      name: "prototypeDevice",
      test: (block) => {
        return (
          lodash.get(block, "prototypeSettings.prototypeDevice.type") !== "NONE"
        );
      },
    },
    background: {
      name: "prototypeBackground",
      test: () => false,
    },
    interactiveComponents: {
      name: "prototypeInteractiveComponents",
      test: () => false,
    },
  },
  blocks: {
    overflowScrolling: {
      name: "blockOverflowScrolling",
      test: () => false,
    },
  },
  triggers: {
    onClick: {
      name: "triggerOnClick",
      test: () => false,
    },
    onDrag: {
      name: "triggerOnDrag",
      test: () => false,
    },
    whileHovering: {
      name: "triggerWhileHovering",
      test: () => false,
    },
    whilePressing: {
      name: "triggerWhilePressing",
      test: () => false,
    },
    keyOrGamepad: {
      name: "triggerKeyOrGamepad",
      test: () => false,
    },
    mouseEnter: {
      name: "triggerMouseEnter",
      test: () => false,
    },
    mouseLeave: {
      name: "triggerMouseLeave",
      test: () => false,
    },
    mouseDown: {
      name: "triggerMouseDown",
      test: () => false,
    },
    mouseUp: {
      name: "triggerMouseUp",
      test: () => false,
    },
  },
  actions: {
    navigateTo: {
      name: "actionNavigateTo",
      test: () => false,
    },
    changeTo: {
      name: "actionChangeTo",
      test: () => false,
    },
    swapOverlay: {
      name: "actionSwapOverlay",
      test: () => false,
    },
    closeOverlay: {
      name: "actionCloseOverlay",
      test: () => false,
    },
    back: {
      name: "actionBack",
      test: () => false,
    },
    scrollTo: {
      name: "actionScrollTo",
      test: () => false,
    },
    openLink: {
      name: "actionOpenLink",
      test: () => false,
    },
  },
};

export const supportedFeaturesUrl = "https://carnelian-station-4ad.notion.site/Figma-prototypes-supported-features-92accf5242db4c898defbfc56d9a8f32";
export const figmaPluginUrl = "https://www.figma.com/community/plugin/1102613500351696592";

export const isSupportedPrototype = (block) => {
  return figmaFeatures.actions.navigateTo.test(block);
};

export const canBeTurnedIntoHtml = (block) => {
  const unsupportedFeatures = [
    figmaFeatures.display.actualSize,
    figmaFeatures.display.fitToScreen,
    figmaFeatures.display.fitSceeen,
    figmaFeatures.prototype.device,
  ];

  return unsupportedFeatures.every((feature) => {
    return feature.test(block) === false;
  });
};

export const getFormattedPrototype = ({ nodesForHtml: nodes, ...prototype }) => {
  const images = [];

  const getFormattedNode = (node, parent) => {
    const result = lodash.cloneDeep(node);

    if (!parent) {
      result.x = 0;
      result.y = 0;
    }

    if (node.image) {
      images.push(node.image);
    }

    if (node.overflowParams && node.overflowParams.image) {
      images.push(node.overflowParams.image);
    }

    if (result.reactions) {
      const reactions = {};
      result.reactions.forEach(reaction => {
        if (reaction.trigger && reaction.action && reaction.action.destinationId) {
          reactions[reaction.trigger.type] = reaction;
        }
      });
      result.reactions = reactions;
    }

    if (result.children) {
      result.children = result.children.map(
        kid => getFormattedNode(kid, result)
      );
    }

    if (parent) {
      result.x = result.absoluteTransform[0].value[2] - parent.absoluteTransform[0].value[2];
      result.y = result.absoluteTransform[1].value[2] - parent.absoluteTransform[1].value[2];
    }

    return result;
  };
  
  const nodesForHtml = lodash.mapValues(nodes, (node) => {
    return getFormattedNode(node);
  });

  return {
    ...prototype,
    nodesForHtml,
    images,
  };
};

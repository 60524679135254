import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import lodash from "lodash";
import clsx from "clsx";
import Loader from "../Loader";
import Button from "../Button";

import HtmlView from "./HtmlView";
import FrameView from "./FrameView";
import SupportedFeatures from "./SupportedFeatures";
import PluginInstructions from "./PluginInstructions";
import OptimizationSwither from "./OptimizationSwither";
import FigmaTestView from "./FigmaTestView";
import NodeInHtml from "./NodeInHtml";

import {
  getNodeAsset,
  getPrototypeUrl,
  canBeTurnedIntoHtml,
  getFormattedPrototype,
} from "../../utils/figma";

import { fetchPrototype } from "../../actions";

const PrototypeBox = ({ message, children }) => {
  return (
    <div className="relative rounded-2xl bg-black my-2" style={{ height: 520 }}>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-white font-medium text-center">
          <Loader />
          <div className="mt-4 text-base font-normal text-gray-500">
            {message}
          </div>
        </div>
      </div>
      <div className="relative z-10">{children}</div>
    </div>
  );
};

const Heading = ({ title, caption, error, className }) => {
  return (
    <div className={className}>
      <div
        className={clsx(
          "font-medium text-sm leading-none",
          error ? "text-red-600" : "text-gray-700"
        )}
      >
        {title}
      </div>
      {error && <div className="text-sm text-red-600 mt-1">{error}</div>}
      {caption && <div className="text-sm text-gray-600 mt-1">{caption}</div>}
    </div>
  );
};

const GoalScreen = ({
  startNode,
  goalNode,
  isGoalStatusLoading,
  nodeImage,
}) => {
  return (
    <div className="mt-4">
      <Heading
        title="Goal screen"
        caption="Click on the prototype above to set your goal screen."
        error={
          startNode === goalNode &&
          "Your Goal Screen can't be the same as your Start Screen"
        }
      />

      <div
        className="my-2 rounded-lg mr-2 shadow-sm overflow-hidden drop-shadow"
        style={{ width: 120, height: 120 }}
      >
        {isGoalStatusLoading ? <Loader /> : nodeImage}
      </div>
    </div>
  );
};

const FigmaPrototype = ({
  blockId,
  fileId,
  goalNode,
  startNode,
  fileVersion,
  nodeImages,
  prototypeOptions = {},
  nodesForHtml,
  figmaHtmlSwitcher,
  touchFigmaHtmlSwitcher,
  canBeTurnedIntoHtml,
  setGoalNode,
}) => {
  const loadingStatuses = { idle: "idle", loading: "loading" };
  const prototypeUrl = getPrototypeUrl(blockId, fileId, [
    goalNode,
    fileVersion,
    prototypeOptions,
  ]);

  const [frameStatus, setFrameStatus] = useState(loadingStatuses.loading);
  const [goalStatus, setGoalStatus] = useState(loadingStatuses.idle);
  const [activeNode, setActiveNode] = useState(goalNode);
  const [frameSrc, setFrameSrc] = useState(prototypeUrl);

  const activeNodeRef = useRef(activeNode);

  useEffect(() => {
    activeNodeRef.current = activeNode;
  });

  useEffect(() => {
    setFrameStatus(loadingStatuses.loading);
  }, [figmaHtmlSwitcher]);

  useEffect(() => {
    if (activeNode === goalNode) {
      if (goalStatus !== loadingStatuses.idle)
        setGoalStatus(loadingStatuses.idle);
      return;
    }
    setGoalStatus(loadingStatuses.loading);
    setGoalNode(activeNode);
  }, [goalNode, activeNode]);

  useEffect(() => {
    setFrameSrc(prototypeUrl);
  }, [fileId, fileVersion]);

  return (
    <>
      <div className="mt-4">
        <Heading title="Prototype" className="mb-2" />
        <SupportedFeatures />

        {figmaHtmlSwitcher && (
          <HtmlView
            className="bg-black rounded-2xl"
            style={{ height: 520, width: "100%" }}
            nodesForHtml={nodesForHtml}
            startNodeId={goalNode || startNode}
            nodeImages={nodeImages}
            onClick={(data) => {
              if (data.transitionNodeID) {
                setActiveNode(data.transitionNodeID);
              }
            }}
            onLoad={() => {
              setFrameStatus(loadingStatuses.idle);
            }}
          />
        )}

        {!figmaHtmlSwitcher && (
          <FrameView
            className="bg-black rounded-2xl"
            style={{ height: 520, width: "100%" }}
            frameSrc={frameSrc}
            onNodeChanged={({ presentedNodeId }) => {
              if (activeNodeRef.current !== presentedNodeId) {
                setActiveNode(presentedNodeId);
              }
            }}
            onLoad={() => {
              setFrameStatus(loadingStatuses.idle);
            }}
          />
        )}
      </div>

      {frameStatus === loadingStatuses.idle && (
        <GoalScreen
          startNode={startNode}
          goalNode={goalNode}
          isGoalStatusLoading={goalStatus === loadingStatuses.loading}
          nodeImage={
            <img
              width="100%"
              src={getNodeAsset([goalNode, fileVersion], nodeImages)}
            />
          }
        />
      )}

      <div className="mt-4">
        <OptimizationSwither
          disabled={canBeTurnedIntoHtml !== true}
          onChange={() => touchFigmaHtmlSwitcher(!figmaHtmlSwitcher)}
          isOn={figmaHtmlSwitcher}
        />
      </div>
    </>
  );
};

const Prototype = ({ prototypeId, goalNode, setGoalNode }) => {
  const loadingStatuses = { idle: "idle", loading: "loading", error: "error" };
  const prototypeData = useRef({});

  const [prototypeStatus, setPrototypeStatus] = useState(
    loadingStatuses.loading
  );
  const [activeNode, setActiveNode] = useState(goalNode);

  useEffect(() => {
    (async () => {
      const response = await fetchPrototype(prototypeId);

      if (response.ok) {
        prototypeData.current = getFormattedPrototype(await response.json());
        setPrototypeStatus(loadingStatuses.idle);
        return;
      }

      setPrototypeStatus(loadingStatuses.error);
    })();
  }, []);

  useEffect(() => {
    if (activeNode !== goalNode) setGoalNode(activeNode);
  }, [goalNode, activeNode]);

  const getGoalNodeImageComponent = () => {
    const node =
      prototypeData.current.nodesForHtml[
        goalNode || prototypeData.current.startNodeId
      ];

    return (
      <div
        style={{
          transformOrigin: "0 0",
          transform: `scale(${120 / node.width})`,
        }}
      >
        <NodeInHtml
          display="actualSize"
          imageScale={lodash.get(prototypeData, "current.settings.imageScale")}
          node={node}
        />
      </div>
    );
  };

  return (
    <>
      <Heading className="mt-4" title="Prototype" />

      {prototypeStatus === loadingStatuses.loading && (
        <PrototypeBox message="The prototype is loading..." />
      )}

      {prototypeStatus === loadingStatuses.error && (
        <PrototypeBox message="Failed to load a prototype." />
      )}

      {prototypeStatus === loadingStatuses.idle && (
        <>
          <FigmaTestView
            style={{ height: 520 }}
            className="relative rounded-xl bg-black my-2 overflow-hidden"
            prototype={prototypeData.current}
            startNodeId={goalNode || prototypeData.current.startNodeId}
            onLoad={() => setPrototypeStatus(loadingStatuses.idle)}
            onClick={(data) => {
              const destinationId = lodash.get(data, "action.destinationId");
              if (destinationId) setActiveNode(destinationId);
            }}
          />
          <GoalScreen
            startNode={prototypeData.current.startNodeId}
            goalNode={goalNode || prototypeData.current.startNodeId}
            nodeImage={getGoalNodeImageComponent()}
          />
        </>
      )}
    </>
  );
};

const PrototypeImportByCode = ({ updateContent }) => {
  const initialValues = { code: "" };

  const validate = ({ code }) => {
    if (!code) return { code: "Please, paste an import code" };
    if (!/^[a-zA-Z0-9]*$/.test(code))
      return { code: "Invalid import code, check the correctness of this one" };
    return {};
  };

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    const response = await fetchPrototype(values.code);

    if (!response.ok) {
      setErrors({
        code: "Failed to load the prototype, сheck the correctness of the code",
      });
      setSubmitting(false);
      return;
    }

    await updateContent({ prototypeId: values.code });
    setSubmitting(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form className="my-4" onSubmit={handleSubmit}>
          <PluginInstructions />
          <Heading title="Import code" error={errors.code} />

          {isSubmitting && <PrototypeBox message="Loading prototype..." />}

          {!isSubmitting && (
            <>
              <input
                name="code"
                className="w-full my-2"
                placeholder="Please, paste your import code here"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <button type="sumbit">
                <Button
                  type="secondary"
                  disabled={errors.code || isSubmitting}
                  name="Import"
                />
              </button>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

const Figma = ({
  blockData,
  blockData: { blockId, fileId, prototypeId },
  updateTestBlock,
}) => {
  if (prototypeId) {
    return (
      <Prototype
        {...blockData}
        key={`figma-prototype-${blockId}`}
        setGoalNode={(goalNode) => {
          updateTestBlock({ goalNode }, blockId);
        }}
      />
    );
  }

  // legacy
  if (fileId) {
    return (
      <FigmaPrototype
        {...blockData}
        canBeTurnedIntoHtml={canBeTurnedIntoHtml(blockData)}
        blockId={blockId}
        key={`figma-prototype-${blockId}`}
        touchFigmaHtmlSwitcher={(figmaHtmlSwitcher) => {
          updateTestBlock({ figmaHtmlSwitcher }, blockId);
        }}
        setGoalNode={(goalNode) => {
          updateTestBlock({ goalNode }, blockId);
        }}
      />
    );
  }

  return (
    <PrototypeImportByCode
      updateContent={(params) => updateTestBlock(params, blockId)}
    />
  );
};

export default Figma;

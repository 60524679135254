import React from "react";
import { figmaPluginUrl } from "../../utils/figma";

export default () => {
  return (
    <div className="flex py-4 px-5 my-4 rounded-lg bg-blue-100">
      <div>
        <div className="font-medium text-sm">
          How to import your Figma prototype?
        </div>
        <div className="flex items-center mt-2">
          <span className="w-5 h-5 rounded-full border-2 border-solid border-black flex items-center justify-center font-medium mr-2 text-xs">
            1
          </span>
          <span className="text-sm">
            Open{" "}
            <a
              href={figmaPluginUrl}
              className="text-blue-600"
              target="_blank"
              rel="noopener noreferer"
            >
              Pathway Figma plugin ↗
            </a>
            , select your prototype flow, click Import.
          </span>
        </div>
        <div className="flex items-center mt-2">
          <span className="w-5 h-5 rounded-full border-2 border-solid border-black flex items-center justify-center font-medium mr-2 text-xs">
            2
          </span>
          <span className="text-sm">
            You’ll get a prototype code — paste it below.
          </span>
        </div>
      </div>
    </div>
  );
};

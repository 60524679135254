import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";
import "../../tailwind.generated.css";

import Task from "./Task";
import Success from "./Success";
import FigmaIframe from "./FigmaIframe";
import Select from "./Select";
import PreferenceSelect from "./PreferenceSelect";
import Button from "../../components/Button";
import FirstClickPointer from "./FirstClickPointer";
import Loader from "../Loader";
import Input from "./Input";
import FigmaTestView from "../Figma/FigmaTestView";
import CardSort from "./CardSort";

import { ReactComponent as IconImage } from "../../icons/i-image-large.svg";
import { ReactComponent as IconCheck } from "../../icons/i-check.svg";
import { ReactComponent as IconCancel } from "../../icons/i-cross.svg";
import { ReactComponent as IconPreview } from "../../icons/preview.svg";

import { getFirstClickCoordinates, responseValidator } from "../../utils";
import { locale, interfaceText } from "../../helpers";
import _ from "lodash";

import { pushToGTM } from "../../utils/gtm";
import { HtmlView } from "../Figma";

const hasImagePreloader = (type) =>
  ["openquestion", "context", "choice", "preference", "fiveseconds"].includes(
    type
  );

const ImagesPreloader = ({ showImagesLoader, blockId }) => {
  const [currentBlockId, setCurrentBlockId] = useState(blockId);

  useEffect(() => {
    setCurrentBlockId(blockId);
  }, [blockId]);

  if (showImagesLoader || currentBlockId !== blockId) {
    return (
      <div
        className="fixed inset-0 z-10"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <Loader />
      </div>
    );
  }

  return null;
};

const Preview = () => (
  <div
    className={`flex w-full min-h-12 items-center justify-center bg-blue-200 px-2`}
  >
    <IconPreview className="fill-current text-black mr-2" />
    <span className="text-sm">
      This is a test preview, answers won't be recorded
    </span>
  </div>
);

const Stepper = ({ currentStep, totalSteps }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center w-full px-6 z-30 relative`}
    >
      <div className="bg-gray-300 rounded-full h-1 w-full mt-6">
        <div
          className="bg-blue-600 h-1 rounded-full transition-all duration-75 ease-in"
          style={{ width: Math.round((currentStep / totalSteps) * 100) + "%" }}
        ></div>
      </div>
      <div className="text-xs font-medium text-gray-600 mt-2">
        {currentStep} / {totalSteps}
      </div>
    </div>
  );
};

const ContentWrapper = ({
  hasButton,
  hasImage,
  image,
  content,
  nextStepOnClick,
  isValidated,
  isOptional,
  currentStep,
  totalSteps,
  contentType,
  withProgress,
  showImagesLoader,
  block,
  isPreview,
}) => {
  const [honeypotLoader, setHoneypotLoader] = useState(
    block.honeypotDelay ? true : false
  );

  useEffect(() => {
    setHoneypotLoader(block.honeypotDelay ? true : false);
  }, [block.blockId]);

  useEffect(() => {
    if (showImagesLoader === false && block.honeypotDelay) {
      setTimeout(() => {
        setHoneypotLoader(false);
      }, block.honeypotDelay);
    }
  }, [showImagesLoader]);

  if (!hasImage) {
    return (
      <>
        <ImagesPreloader
          showImagesLoader={showImagesLoader}
          blockId={block.blockId}
        />
        <div className="min-h-full flex flex-col items-center">
          {isPreview && <Preview />}
          {withProgress && (
            <Stepper
              currentStep={currentStep}
              totalSteps={totalSteps}
              contentType={contentType}
            />
          )}
          <div className="flex flex-col items-center justify-center h-full px-4 flex-1 w-full md:w-3/5 lg:w-1/2 xl:w-1/3">
            {content}
            {hasButton && (
              <div className="my-2 flex items-start w-full">
                <Button
                  id="nextButton"
                  type="primary"
                  name={interfaceText.test[locale].nextButton}
                  handler={() => {
                    nextStepOnClick();
                  }}
                  large
                  disabled={!isValidated && !isOptional}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="min-h-full flex flex-col items-center">
      <ImagesPreloader
        showImagesLoader={showImagesLoader}
        blockId={block.blockId}
      />
      {isPreview && <Preview />}
      {withProgress && (
        <Stepper
          currentStep={currentStep}
          totalSteps={totalSteps}
          contentType={contentType}
        />
      )}
      <div className="grid p-8 grid-auto gap-8 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 w-full flex-1">
        <div className="flex justify-center items-center">
          {honeypotLoader && <Loader />}
          <div className={honeypotLoader ? "hidden" : ""}>{image}</div>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-full px-4 flex-1">
          <div className="w-full xl:w-3/5">
            {content}
            <div className="my-4 w-full">
              {hasButton && (
                <Button
                  type="primary"
                  name={interfaceText.test[locale].nextButton}
                  handler={() => {
                    nextStepOnClick();
                  }}
                  large
                  disabled={!isValidated && !isOptional}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContentBlock = ({
  currentStep,
  totalSteps,
  data,
  blockId,
  testId,
  answerId,
  showNextBlock,
  designConfig,
  sendAnswer,
  withProgress,
  isPreview,
}) => {
  const getFigmaInitialData = () => ({
    nodeEventData: {
      0: {
        nodeId: data.startNode,
        timeSpent: 0,
        clicks: [],
      },
    },
    currentNode: 0,
    path: [data.startNode || _.get(data, "prototypeData.startNodeId")],
    currentNodeResponseStart: null,
    responseStart: null,
  });

  const [figmaData, setFigmaData] = useState(getFigmaInitialData());
  const figmaDataRef = useRef(figmaData);

  const submitFigmaBlockAnswer = (data) => {
    const {
      responseStart,
      path,
      nodeEventData,
      currentNode,
      currentNodeResponseStart,
    } = figmaData;

    sendAnswer(
      {
        givenUp: Boolean(_.get(data, "givenUp")),
        time: _.now() - responseStart,
        path,
        submittedAt: _.now(),
        nodeEventData: _.merge(nodeEventData, {
          [currentNode]: { timeSpent: _.now() - currentNodeResponseStart },
        }),
      },
      blockId
    );
  };

  const updateFigmaData = (handler) => {
    const customizer = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    };
    setFigmaData((figmaData) => {
      const newState = _.cloneDeep(figmaData);
      return _.mergeWith(newState, handler(figmaData), customizer);
    });
  };

  const addTransitionToFigmaData = (data) => {
    const transitionNodeID = data.transitionNodeID
      ? data.transitionNodeID
      : data.action.destinationId;

    updateFigmaData(({ currentNode, currentNodeResponseStart, path }) => ({
      currentNode: path.length,
      currentNodeResponseStart: _.now(),
      path: [transitionNodeID],
      nodeEventData: {
        [currentNode]: {
          timeSpent: _.now() - currentNodeResponseStart,
        },
        [path.length]: {
          nodeId: transitionNodeID,
          timeSpent: 0,
          clicks: [],
        },
      },
    }));
  };

  const addClickToFigmaData = (data) => {
    const clickData = data.clickData;
    const destinationId = _.get(data, "action.destinationId");
    const transitionNodeID = destinationId || data.transitionNodeID;

    updateFigmaData(({ currentNode }) => ({
      nodeEventData: {
        [currentNode]: {
          clicks: [
            {
              handled: Boolean(transitionNodeID),
              timestamp: _.now(),
              ...clickData, // x, y, nodeId
            },
          ],
        },
      },
    }));
  };

  useEffect(() => {
    figmaDataRef.current = figmaData;
    if (data.type === "figma" && _.last(figmaData.path) === data.goalNode) {
      setisSuccessModalOpen(true);
    }
  }, [figmaData]);

  const [isLoading, setIsLoading] = useState(true);
  const [blockAnswer, setBlockAnswer] = useState({});
  const [responseStart, setResponseStart] = useState(null);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [showFiveSecondsImage, setShowFiveSecondsImage] = useState(null);

  const hasImage = data.image && data.image !== "";
  const isOptional = data.isOptional;

  const [imageStatuses, setImageStatus] = useState({});
  const isBlockImagesLoaded = _.every(
    _.values(imageStatuses),
    (status) => status === "loaded"
  );

  const onImageLoaded = (imagePath) => {
    setImageStatus((state) => ({
      ...state,
      [imagePath]: "loaded",
    }));
  };

  const getInitialImageStatuses = (block) => {
    const statuses = {};

    if (hasImagePreloader(block.type)) {
      if (block.type === "preference") {
        block.replies.forEach((reply) => {
          statuses[`reply.${reply.id}.image`] = undefined;
        });
      }
      if (block.image) {
        statuses["image"] = undefined;
      }
    }

    return statuses;
  };

  useEffect(() => {
    if (hasImagePreloader(data.type) && isBlockImagesLoaded === true) {
      setResponseStart(_.now());
    }
  }, [isBlockImagesLoaded]);

  useEffect(() => {
    pushToGTM({ event: "openQuestion", blockId, testId, answerId });
    // Reset state variables when blockId is updated
    setFigmaData(getFigmaInitialData());
    setIsLoading(true);
    setResponseStart(null);
    setShowFiveSecondsImage(null);
    setImageStatus(getInitialImageStatuses(data)); // preload images
  }, [blockId, testId, answerId]);

  const handleBlockAnswer = (key, value) => {
    setBlockAnswer({ ...blockAnswer, [key]: value });
  };

  const handleSubmitBlockAnswer = () => {
    const answerData = {
      ...(hasImagePreloader(data.type)
        ? { responseTime: _.now() - responseStart }
        : {}),
      ...blockAnswer,
      submittedAt: _.now(),
    };

    const nextBlock = { ...blockAnswer };
    sendAnswer(answerData, blockId, nextBlock);
    setBlockAnswer({});
    responseStart && setResponseStart(null);
  };

  const isValidated = responseValidator(
    data.type,
    data.replyType,
    blockAnswer,
    data
  ).isValid;

  const nextStepOnClick = () => {
    if (!isValidated && isOptional) {
      showNextBlock();
    } else {
      isValidated && handleSubmitBlockAnswer();
    }
  };

  if (data.type === "openquestion") {
    return (
      <ContentWrapper
        hasButton={true}
        currentStep={currentStep}
        totalSteps={totalSteps}
        contentType={data.type}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={nextStepOnClick}
        isValidated={isValidated}
        isOptional={isOptional}
        designConfig={designConfig}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        block={data}
        image={
          <img
            src={data.image}
            alt=""
            className="max-h-75 lg:mt-0 max-w-full object-contain block`"
            onLoad={() => {
              onImageLoaded("image");
            }}
          />
        }
        hasImage={hasImage}
        content={
          <>
            <div
              className="w-full text-xl my-2 flex items-start"
              style={{ color: designConfig.textColor }}
            >
              <div>{parse(data.questionHtml || data.text)}</div>
            </div>
            <div className="w-full flex justify-center">
              <Input
                singleLine={data.isShort}
                placeholder={interfaceText.test[locale].inputPlaceholder}
                id={`${blockId}-response`}
                handler={handleBlockAnswer}
                designConfig={designConfig}
                className="test-textarea"
              />
            </div>
          </>
        }
      />
    );
  }

  if (data.type === "context") {
    return (
      <ContentWrapper
        hasButton={true}
        currentStep={currentStep}
        totalSteps={totalSteps}
        contentType={data.type}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={() => {
          showNextBlock();
        }}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        isValidated={true}
        designConfig={designConfig}
        block={data}
        image={
          <img
            src={data.image}
            alt=""
            className="max-h-75 lg:mt-0 max-w-full object-contain block"
            onLoad={() => {
              onImageLoaded("image");
            }}
          />
        }
        hasImage={hasImage}
        content={
          <div className="text-xl w-full">
            <div
              className="whitespace-pre-line"
              style={{ color: designConfig.textColor }}
            >
              <div>{parse(data.questionHtml || data.text)}</div>
            </div>
          </div>
        }
      />
    );
  }

  if (data.type === "choice") {
    return (
      <ContentWrapper
        hasButton={true}
        currentStep={currentStep}
        totalSteps={totalSteps}
        contentType={data.type}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={nextStepOnClick}
        isValidated={isValidated}
        isOptional={isOptional}
        designConfig={designConfig}
        block={data}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        image={
          <img
            src={data.image}
            alt=""
            className="max-h-75 lg:mt-0 max-w-full object-contain block"
            onLoad={() => {
              onImageLoaded("image");
            }}
          />
        }
        hasImage={hasImage}
        content={
          <>
            <div
              className="w-full text-xl my-2 flex items-start"
              style={{ color: designConfig.textColor }}
            >
              <div>{parse(data.questionHtml || data.text)}</div>
            </div>

            <div className="my-2 w-full">
              <Select
                type={data.replyType}
                data={data.replies}
                isRandomized={data.randomized}
                withOther={data.other}
                handler={handleBlockAnswer}
                selectedOptions={blockAnswer.selectedOptions || []}
              />
            </div>
          </>
        }
      />
    );
  }

  if (data.type === "preference") {
    return (
      <ContentWrapper
        hasButton={false}
        currentStep={currentStep}
        totalSteps={totalSteps}
        contentType={data.type}
        withProgress={withProgress}
        isPreview={isPreview}
        nextStepOnClick={nextStepOnClick}
        isValidated={isValidated}
        designConfig={designConfig}
        hasImage={false}
        block={data}
        showImagesLoader={hasImagePreloader(data.type) && !isBlockImagesLoaded}
        content={
          <>
            <div className="text-center text-xl py-6 w-full">
              <div
                className="max-w-screen-sm mx-auto"
                style={{ color: designConfig.textColor }}
              >
                <div>{parse(data.questionHtml || data.text)}</div>
              </div>
            </div>
            <PreferenceSelect
              type={data.replyType}
              data={data.replies}
              isRandomized={data.randomized}
              doNotShowZoomedImage={data.doNotShowZoomedImage}
              handler={handleBlockAnswer}
              onImageLoaded={onImageLoaded}
              nextStepOnClick={nextStepOnClick}
            />
          </>
        }
      />
    );
  }

  if (data.type === "cardsort") {
    return (
      <div className="flex flex-col h-full">
        <ImagesPreloader
          showImagesLoader={
            hasImagePreloader(data.type) && !isBlockImagesLoaded
          }
          blockId={data.blockId}
        />
        {isPreview && <Preview />}
        {withProgress && (
          <Stepper currentStep={currentStep} totalSteps={totalSteps} />
        )}
        <div className="flex justify-center text-xl flex-1 w-full">
          <div className="w-full">
            <CardSort
              blockData={data}
              responseStart={responseStart}
              setResponseStart={setResponseStart}
              isValidated={isValidated}
              nextStepOnClick={nextStepOnClick}
              blockAnswer={blockAnswer}
              handleBlockAnswer={handleBlockAnswer}
            />
          </div>
        </div>
      </div>
    );
  }

  if (data.type === "firstclick") {
    const position = blockAnswer.clickData;
    return (
      <div className="flex items-center flex-col min-h-full">
        {isPreview && <Preview />}
        {withProgress && (
          <Stepper currentStep={currentStep} totalSteps={totalSteps} />
        )}
        <div className="flex items-center justify-center text-xl flex-1 w-full">
          <div className="w-full">
            <div
              className={`text-center text-xl my-8 max-w-md mx-auto px-2 md:px-4 whitespace-pre-line ${
                !responseStart && "flex flex-col items-center justify-center"
              }`}
              style={{ color: designConfig.textColor }}
            >
              <div className="max-w-screen-sm mx-auto">
                <div>{parse(data.questionHtml || data.text)}</div>
              </div>
              {!responseStart && (
                <Button
                  name={interfaceText.firstclickTesting[locale].showImage}
                  handler={() => {
                    setResponseStart(_.now());
                  }}
                  large
                  className="mt-4 mx-auto"
                  icon={
                    <IconImage
                      width={24}
                      height={24}
                      className="fill-current text-white mr-2"
                    />
                  }
                />
              )}
            </div>
            <div
              className={`flex justify-center items-center mx-auto p-2 md:p-4 my-12 ${
                responseStart ? "block" : "hidden"
              }`}
            >
              <div className="relative inline">
                {position && (
                  <FirstClickPointer
                    position={position}
                    withPopover
                    opacity={100}
                    popoverContentBlock={
                      <div className="flex flex-col items-center justify-center">
                        <Button
                          name={""}
                          handler={() => {
                            nextStepOnClick();
                          }}
                          icon={
                            <IconCheck
                              width={24}
                              height={24}
                              className="fill-current text-white"
                            />
                          }
                          small
                          disabled={!isValidated}
                          customButtonColor={designConfig.buttonsColor}
                          customButtonTextColor={designConfig.buttonsTextColor}
                          className="mb-2"
                        />
                        <Button
                          small
                          type="ghost"
                          name={""}
                          icon={
                            <IconCancel
                              width={24}
                              height={24}
                              className="fill-current text-gray-600 hover:text-black transition-all ease-in duration-75"
                            />
                          }
                          handler={() => {
                            handleBlockAnswer("clickData", null);
                          }}
                        />
                      </div>
                    }
                  />
                )}
                <img
                  id="firstClickImage"
                  onClick={(e) => {
                    if (!position) {
                      handleBlockAnswer("clickData", {
                        left: getFirstClickCoordinates(e).left,
                        top: getFirstClickCoordinates(e).top,
                        responseTime: _.now() - responseStart,
                      });
                    }
                  }}
                  src={data.image}
                  alt=""
                  className={`lg:mt-0 max-w-full transition-all duration-150 ease-in ${
                    responseStart ? "block" : "hidden"
                  }`}
                  style={position ? { filter: "brightness(60%)" } : undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // type === "fiveseconds"

  if (data.type === "fiveseconds") {
    return (
      <div className="flex flex-col items-center min-h-full">
        <ImagesPreloader
          showImagesLoader={
            hasImagePreloader(data.type) && !isBlockImagesLoaded
          }
          blockId={data.blockId}
        />
        {isPreview && <Preview />}
        {withProgress && (
          <Stepper currentStep={currentStep} totalSteps={totalSteps} />
        )}
        <div className="flex items-center justify-center text-xl flex-1">
          <div className="w-full">
            <div
              className={`text-center text-xl max-w-md mx-auto px-2 md:px-4 whitespace-pre-line ${
                !showFiveSecondsImage &&
                "flex flex-col items-center justify-center"
              }`}
              style={{ color: designConfig.textColor }}
            >
              {!showFiveSecondsImage && (
                <>
                  <p className="text-center font-bold mb-2">
                    {interfaceText.test[locale].fiveSecondsHeader}
                  </p>
                  <p className="text-center text-base">
                    {interfaceText.test[locale].fiveSecondsText}
                  </p>
                  <Button
                    name={interfaceText.firstclickTesting[locale].showImage}
                    handler={() => {
                      setShowFiveSecondsImage(true);
                      setTimeout(() => {
                        showNextBlock();
                      }, data.time * 1000);
                    }}
                    large
                    className="mt-4 mx-auto"
                    icon={
                      <IconImage
                        width={24}
                        height={24}
                        className="fill-current text-white mr-2"
                      />
                    }
                  />
                </>
              )}
            </div>
            <div
              className={`flex justify-center items-center mx-auto p-2 md:p-4 ${
                showFiveSecondsImage ? "block" : "hidden"
              }`}
            >
              <div className="inline">
                <img
                  id="firstClickImage"
                  src={data.image}
                  alt=""
                  className="lg:mt-0 max-w-full transition-all duration-150 ease-in"
                  onLoad={() => {
                    onImageLoaded("image");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Figma prototype testing

  if (data.type === "figma" && data.figmaHtmlSwitcher) {
    return (
      <div className="w-full h-full relative" id="testContentBlock">
        <Task
          withProgress={withProgress}
          progress={
            <Stepper
              currentStep={currentStep}
              totalSteps={totalSteps}
              fullWidth
            />
          }
          text={parse(data.questionHtml || data.text)}
          designConfig={designConfig}
          onStart={() => {
            updateFigmaData(() => ({
              currentNodeResponseStart: _.now(),
              responseStart: _.now(),
            }));
          }}
          onGiveUp={() => {
            submitFigmaBlockAnswer({ givenUp: true });
            setBlockAnswer({});
            showNextBlock();
          }}
          isLoading={isLoading}
          blockId={blockId}
        />
        <Success
          onClick={() => {
            setisSuccessModalOpen(false);
            submitFigmaBlockAnswer();
            setBlockAnswer({});
            showNextBlock();
          }}
          isSuccessModalOpen={isSuccessModalOpen}
          designConfig={designConfig}
        />

        <HtmlView
          className="w-full h-full"
          style={{ height: "100%", width: "100%" }}
          nodesForHtml={data.nodesForHtml}
          startNodeId={data.startNode}
          nodeImages={data.nodeImages}
          key={data.blockId}
          onClick={(data) => {
            addClickToFigmaData(data);
            if (data.transitionNodeID) {
              addTransitionToFigmaData(data);
            }
          }}
          onLoad={() => {
            setIsLoading(false);
          }}
        />
      </div>
    );
  }

  if (data.type === "figma" && data.prototypeId) {
    return (
      <div
        className="w-full h-full flex flex-col relative"
        id="testContentBlock"
      >
        {isPreview && <Preview />}
        <div className="h-full relative">
          <Task
            withProgress={withProgress}
            progress={
              <Stepper
                currentStep={currentStep}
                totalSteps={totalSteps}
                fullWidth
              />
            }
            text={parse(data.questionHtml || data.text)}
            designConfig={designConfig}
            onStart={() => {
              updateFigmaData(() => ({
                currentNodeResponseStart: _.now(),
                responseStart: _.now(),
              }));
            }}
            onGiveUp={() => {
              submitFigmaBlockAnswer({ givenUp: true });
              setBlockAnswer({});
              showNextBlock();
            }}
            isLoading={isLoading}
            blockId={blockId}
          />

          <Success
            onClick={() => {
              setisSuccessModalOpen(false);
              submitFigmaBlockAnswer();
              setBlockAnswer({});
              showNextBlock();
            }}
            isSuccessModalOpen={isSuccessModalOpen}
            designConfig={designConfig}
          />

          {data.prototypeData && (
            <FigmaTestView
              className="w-full h-full bg-black"
              startNodeId={data.prototypeData.startNodeId}
              prototype={data.prototypeData}
              onLoad={() => {
                setFigmaData(getFigmaInitialData());
                setIsLoading(false);
              }}
              onClick={(data) => {
                addClickToFigmaData(data);
                if (_.get(data, "action.destinationId")) {
                  addTransitionToFigmaData(data);
                }
              }}
            />
          )}
        </div>
      </div>
    );
  }

  if (data.type === "figma") {
    const handlePrototypeEvent = (eventType, eventData) => {
      if (eventType === "INITIAL_LOAD") {
        setIsLoading(false);
      }

      if (eventType === "MOUSE_PRESS_OR_RELEASE" && eventData.targetNodeId) {
        const {
          nearestScrollingFrameMousePosition,
          nearestScrollingFrameOffset,
          handled,
        } = eventData;

        addClickToFigmaData({
          clickData: {
            handled,
            coordinates: {
              x:
                nearestScrollingFrameMousePosition.x +
                nearestScrollingFrameOffset.x,
              y:
                nearestScrollingFrameMousePosition.y +
                nearestScrollingFrameOffset.y,
            },
          },
        });
      }

      if (
        eventType === "PRESENTED_NODE_CHANGED" &&
        figmaDataRef.current.responseStart
      ) {
        addTransitionToFigmaData({
          transitionNodeID: eventData.presentedNodeId,
        });
      }
    };

    return (
      <>
        <div className="w-full h-full relative" id="testContentBlock">
          <Task
            withProgress={withProgress}
            isPreview={isPreview}
            preview={<Preview />}
            progress={
              <Stepper
                currentStep={currentStep}
                totalSteps={totalSteps}
                fullWidth
              />
            }
            text={parse(data.questionHtml || data.text)}
            designConfig={designConfig}
            onStart={() => {
              updateFigmaData(() => ({
                currentNodeResponseStart: _.now(),
                responseStart: _.now(),
              }));
            }}
            onGiveUp={() => {
              submitFigmaBlockAnswer({ givenUp: true });
              setBlockAnswer({});
              showNextBlock();
            }}
            isLoading={isLoading}
            blockId={blockId}
          />
          <Success
            onClick={() => {
              setisSuccessModalOpen(false);
              submitFigmaBlockAnswer();
              setBlockAnswer({});
              showNextBlock();
            }}
            isSuccessModalOpen={isSuccessModalOpen}
            designConfig={designConfig}
          />
          <FigmaIframe
            fileId={data.fileId}
            fileVersion={data.fileVersion}
            startNodeId={data.startNode}
            prototypeOptions={data.prototypeOptions}
            handlePrototypeEvent={handlePrototypeEvent}
            width="100%"
            height="100%"
            scaleToFit
            className="w-full h-full"
            isLoading={isLoading}
            blockId={blockId}
          />
        </div>
      </>
    );
  }
};

export default ContentBlock;

import React, { useContext } from "react";
import "../../tailwind.generated.css";
import { ReactComponent as IconCheck } from "../../icons/i-check.svg";

import { getLocale } from "../../helpers";

import { setUi } from "../../actions";

import Modal from "../Modal";
import Button from "../Button";

import appContext from "../../AppContext";

const UpgradeModal = () => {
  const { state, dispatch } = useContext(appContext);

  const isUpgradeModalOpen = state.app.ui.isUpgradeModalOpen;

  const setIsUpgradeModalOpen = () => {
    dispatch(setUi("isUpgradeModalOpen", false));
  };

  if (isUpgradeModalOpen) {
    return (
      <Modal
        isOpen={isUpgradeModalOpen}
        setIsOpen={setIsUpgradeModalOpen}
        width="560px"
        background
      >
        <div className="p-6">
          <div className="flex text-3xl tracking-tighter mb-4 font-bold font-display">
            Upgrade to Teams plan
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div className="rounded-xl bg-gray-400 p-5 flex flex-col justify-between font-display">
              <div>
                <span className="font-bold px-2 py-0 mb-2 bg-orange-600 rounded-full inline-block text-white">
                  Teams
                </span>
              </div>
              <ul className="text-xl">
                <li className="flex items-start">
                  <div className="pt-2 mr-2 flex-shrink-0">
                    <IconCheck
                      width={16}
                      height={16}
                      className="fill-current text-black"
                    />
                  </div>
                  ∞ responses
                </li>
                <li className="flex items-start">
                  <div className="pt-2 mr-2 flex-shrink-0">
                    <IconCheck
                      width={16}
                      height={16}
                      className="fill-current text-black"
                    />
                  </div>
                  ∞ active tests
                </li>
                <li className="flex items-start">
                  <div className="pt-2 mr-2 flex-shrink-0">
                    <IconCheck
                      width={16}
                      height={16}
                      className="fill-current text-black"
                    />
                  </div>
                  Individual support and workshops
                </li>
                <li className="flex items-start">
                  <div className="pt-2 mr-2 flex-shrink-0">
                    <IconCheck
                      width={16}
                      height={16}
                      className="fill-current text-black"
                    />
                  </div>
                  Custom search for respondents
                </li>
                <li className="flex items-start">
                  <div className="pt-2 mr-2 flex-shrink-0">
                    <IconCheck
                      width={16}
                      height={16}
                      className="fill-current text-black"
                    />
                  </div>
                  Collaborative work
                </li>
                <li className="flex items-start">
                  <div className="pt-2 mr-2 flex-shrink-0">
                    <IconCheck
                      width={16}
                      height={16}
                      className="fill-current text-black"
                    />
                  </div>
                  <span className="block leading-tight">
                    8 ₽ per respondent from our panel
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <span className="block mt-6">
            Please, send as a message in the support chat to upgrade.
          </span>
          <Button
            name="Talk to us"
            handler={() => {
              window.Intercom(
                "showNewMessage",
                getLocale() === "ru"
                  ? "Привет! Мне интересен тариф Teams, расскажите, как можно оформить подписку?"
                  : "Hi! I'm interested in the Teams plan"
              );
            }}
            type="secondary"
            className="mt-2"
          />
        </div>
      </Modal>
    );
  }

  return null;
};

export default UpgradeModal;

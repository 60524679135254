import React from "react";
import { v4 as uuidv4 } from "uuid";

export const replyTypes = [
  { name: "Free text input", type: "freeform" },
  { name: "Single Choise", type: "single" },
  { name: "Multiple Choise", type: "multi" },
  { name: "Scale 0 to 5", type: "scale" },
];

function generateId() {
  return uuidv4();
};

export const initialContent = () => {
  const getReplies = (withImage) => {
    return [
      { id: generateId(), replyValue: "", ...(withImage ? { image: "" } : {}) },
      { id: generateId(), replyValue: "", ...(withImage ? { image: "" } : {}) },
    ];
  };

  return {
    openquestion: {
      image: "",
      text: "",
      type: "openquestion",
      isOptional: false,
    },
    choice: {
      image: "",
      replies: getReplies(false),
      replyType: "single",
      randomized: false,
      other: false,
      isOptional: false,
      text: "",
      type: "choice",
    },
    preference: {
      replies: getReplies(true),
      replyType: "single",
      randomized: true,
      text: "",
      type: "preference",
      doNotShowZoomedImage: false,
    },
    firstclick: {
      image: "",
      text: "",
      type: "firstclick",
    },
    fiveseconds: {
      image: "",
      time: 5,
      type: "fiveseconds",
    },
    figma: {
      fileId: "",
      text: "",
      goalNode: "",
      type: "figma",
      nodeImages: {},
      nodeNames: {},
    },
    context: {
      image: "",
      text: "",
      type: "context",
    },
    cardsort: {
      cards: [],
      categories: [],
      shuffleCards: true,
      shuffleCategories: true,
      doNotRequireToSortAll: false,
      withImages: false,
      withDescription: false,
      type: "cardsort",
    },
  };
};

export const hostName = "app.pthwy.design";

export function getLocale() {
  const lang = navigator.language;
  // flag for demo test
  const setEn = window.location.href.includes("pEGg41U2IsIDXQqvEot0");
  if (setEn === true) {
    return "en";
  }
  if (lang.includes("ru")) {
    return "ru";
  } else if (lang.includes("en" || "UK" || "US")) {
    return "en";
  } else {
    return "en";
  }
};

export const locale = getLocale();

export const translate = (text) => {
  const locale = getLocale();
  if (locale !== "en") {
    return localizationTexts[text] ? localizationTexts[text] : text;
  } else {
    return text;
  }
};

const localizationTexts = {};

export const interfaceText = {
  firstclickTesting: {
    ru: {
      confirmButton: "Подтвердить",
      cancelButton: "Отмена",
      showImage: "Показать изображение",
    },
    en: {
      confirmButton: "Confirm click",
      cancelButton: "Cancel",
      showImage: "Show image",
    },
  },
  figmaTestingIntro: {
    ru: {
      header: "Задание с прототипом",
      text: "Сейчас вы увидите прототип продукта с интерактивными элементами, на которые можно кликнуть. Это не финальный продукт, некоторые элементы могут не работать или быть некликабельными.",
      button: "Хорошо",
    },
    en: {
      header: "Prototype task",
      text: "You'll see a prototype with interactive elements. It’s not a final product, some elements may not work or be clickable.",
      button: "Got it",
    },
  },
  figmaTestingMission: {
    ru: {
      header: "Ваше задание",
      button: "Начать",
      buttonWhenMissionStarted: "Продолжить",
      loading: "Подождите немного...",
    },
    en: {
      header: "Your mission",
      button: "Let's start",
      buttonWhenMissionStarted: "Back to mission",
      loading: "Hold on for a second...",
    },
  },
  figmaTesting: {
    ru: {
      showTaskTip:
        "Если вы забыли задание или хотите сдаться — нажмите на синюю полоску",
      showTaskTipButton: "Хорошо",
      giveUpButton: "Не могу выполнить задание",
      missionButton: "Показать задание",
    },
    en: {
      showTaskTip:
        "In case you forgot the task or want to give up — click on this blue stripe.",
      showTaskTipButton: "Ok",
      giveUpButton: "Give up",
      missionButton: "Show mission",
    },
  },
  figmaTestingSuccess: {
    ru: {
      header: "Поздравляем!",
      button: "Далее",
      text: "Вы успешно справились с заданием.",
    },
    en: {
      header: "Congratulations",
      button: "Next",
      text: "You successfully accomplished the mission.",
    },
  },
  thankYou: {
    ru: "Спасибо за участие",
    en: "Thank you for participating",
  },
  thankYouButton: {
    ru: "Завершить тест",
    en: "Complete the test",
  },
  pageNotFound: {
    ru: { header: "Страница не найдена", text: "Простите, здесь ничего нет" },
    en: { header: "Page Not Found", text: "Sorry, nothing here" },
  },
  testIsntAcceptingNewResponses: {
    ru: "Этот тест больше не принимает новые ответы",
    en: "This test isn’t accepting new responses.",
  },
  requestFailed: {
    ru: { header: "Не удалось загрузить данные", button: "Попробовать еще" },
    en: { header: "Failed to load data", button: "Try again" },
  },
  verificationCode: {
    ru: "Код подтверждения:",
    en: "Verification code:",
  },
  home: {
    ru: {
      settings: "Настройки",
      needHelp: "Нужна помощь?",
      createTest: "+ Новый тест",
      signOut: "Выйти",
      myProjects: "Мои тесты",
    },
    en: {
      settings: "Settings",
      needHelp: "Need help?",
      createTest: "+ New test",
      signOut: "Sign out",
      myProjects: "My tests",
    },
  },
  test: {
    ru: {
      fiveSecondsHeader: "Сосредоточьтесь на изображении",
      fiveSecondsText:
        "Вы будете просматривать изображение в течение ограниченного времени. Постарайтесь понять, о чем это изображение, и запомните как можно больше информации.",
      inputPlaceholder: "Введите текст ответа",
      nextButton: "Далее",
      preferenceNextButton: "Выбрать",
      multipleChoiceTip: "Вы можете выбрать несколько вариантов ответа",
      other: "Другое",
      otherPlaceholder: "Введите текст ответа",
      cardSortFinished: "Все карточки отсортированы",
      cardSortCategories: "Категории",
      cardSortCards: "Карточки",
      cardSortLeft: "осталось",
      cardSortNextButton: "Завершить сортировку",
      cardSortStart: "Начать",
      cardSortTask: (
        <>
          Пожалуйста, отсортируйте карточки по представленным категориям. <br />
          <br />В этом задании нет правильных или неправильных ответов, просто
          распределите карточки так, как вам кажется наиболее подходящим.
        </>
      ),
      cardSort: "Сортировка карточек",
    },
    en: {
      fiveSecondsHeader: "Focus on the image",
      fiveSecondsText:
        "You will view an image for a limited time. Try to understand what the image is about and remember as much information as you can.",
      inputPlaceholder: "Enter reply here",
      nextButton: "Next",
      preferenceNextButton: "Choose this one",
      multipleChoiceTip: "You can select multiple options",
      other: "Other",
      otherPlaceholder: "Enter reply here",
      cardSortFinished: "You've sorted all of the cards",
      cardSortCategories: "Categories",
      cardSortCards: "Cards",
      cardSortLeft: "left",
      cardSortNextButton: "Finish sorting",
      cardSortStart: "Let's start",
      cardSortTask: (
        <>
          Sort each card into the category that makes sense to you.
          <br />
          <br />
          Do what comes naturally, there is no right or wrong answers.
        </>
      ),
      cardSort: "Card sort",
    },
  },
};

export const supportLink =
  "https://carnelian-station-4ad.notion.site/Help-Support-53eb46557aa1498f92e9bc9181f9e0c4";

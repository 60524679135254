import React from "react";
import "../tailwind.generated.css";

const Switcher = ({ text, handler, caption, isOn, disabled }) => {
  return (
    <div className="flex justify-start items-start">
      <div
        style={{ minWidth: "32px" }}
        className={`rounded-full p-1 ${
          isOn ? "bg-blue-600" : "bg-gray-500"
        } w-8 h-4 mt-1 ${
          disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={(e) => {
          if (disabled) return;
          e.preventDefault();
          e.stopPropagation();
          handler(!isOn);
        }}
      >
        <div
          className={`rounded-full bg-white w-2 h-2 ${isOn && "ml-4"}`}
        ></div>
      </div>
      <div className={`ml-2 tracking-tight ${disabled ? "text-gray-600" : ""}`}>
        <span>{text}</span>
        {caption && <p className="text-sm mt-1 text-gray-600">{caption}</p>}
      </div>
    </div>
  );
};

export default Switcher;

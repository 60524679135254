// component boilerplate
import React from "react";
import "../tailwind.generated.css";

import Tooltip from "./Tooltip";
import { ReactComponent as IconQuestion } from "../icons/q.svg";
import clsx from "clsx";

const TooltipWithIcon = ({ text, size, className }) => {
  return (
    <Tooltip
      anchorClassName={clsx(
        "fill-current text-gray-600",
        className && className
      )}
      position="top"
      content={<div className="w-48">{text}</div>}
    >
      <IconQuestion width={size || 16} height={size || 16} />
    </Tooltip>
  );
};

export default TooltipWithIcon;

import React from "react";
import Switcher from "../Switcher";
import { supportedFeaturesUrl } from "../../utils/figma";

export default ({ disabled = false, onChange, isOn }) => {
  const supportedCaption = (
    <span>
      The prototype will load faster and perform better on old devices.
    </span>
  );
  const unsupportedCaption = (
    <span>
      The prototype will load faster and perform better on old devices. Can't be
      turned on for your prototype because it uses{" "}
      <a className="text-blue-600" target="_blank" href={supportedFeaturesUrl}>
        some unsupported features
      </a>
      .
    </span>
  );

  return (
    <Switcher
      text={
        <>
          <span className="mr-1">Optimize prototype</span>
          <span className="bg-gray-700 text-white text-xs font-medium rounded-full px-1 py-1">
            Beta
          </span>
        </>
      }
      disabled={disabled}
      caption={disabled ? unsupportedCaption : supportedCaption}
      handler={onChange}
      isOn={isOn}
    />
  );
};

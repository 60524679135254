import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import appContext from "../../AppContext";

import { ReactComponent as IconWave } from "../../icons/wave.svg";
import TextDropdown from "../TextDropdown";
import SmartInput from "../SmartInput";
import Button from "../Button";
import Logo from "../Logo";
import { setUserData } from "../../actions";
import SignOut from "../SignOut";

const Questionnaire = () => {
  const { state, dispatch } = useContext(appContext);
  const history = useHistory();

  const [role, setRole] = useState(null);
  const [company, setCompany] = useState(null);
  const [source, setSource] = useState(null);
  const [name, setName] = useState(state.user.displayName);

  const questionnaireValidator = () => {
    return role?.length && company?.length && source?.length && name?.length;
  };

  const handleQuestionnaireSubmit = async () => {
    dispatch(
      await setUserData(state.user.userId, {
        displayName: name,
        questionnaireData: {
          role: role,
          company: company,
          source: source,
        },
        questionnaireCompleted: true,
      })
    );
  };

  return (
    <div className="container mx-auto flex flex-col min-h-full pb-20">
      <div className="container mx-auto flex pt-10 pb-10 items-center justify-between px-6">
        <Logo clickable={false} />
        <SignOut />
      </div>
      <div className="container mx-auto h-full flex justify-center items-center flex-1">
        <div className="max-w-xl">
          <div>
            <div className="flex items-center">
              <div className="text-center font-bold font-display text-5xl leading-tight tracking-tight">
                Hi, nice to meet you.
              </div>
            </div>
            <div className="font-bold font-display text-5xl leading-tight tracking-tight">
              Please, tell us a bit more about yourself:
            </div>
          </div>
          <div>
            <div className="my-4">
              <span className="font-medium block">What is your name?</span>
              <SmartInput
                className="w-full"
                extralarge
                initialValue={name}
                placeholder="Enter your name here"
                onChange={(value) => {
                  setName(value);
                }}
              />
            </div>
            <div className="my-4">
              <span className="font-medium">Your current role</span>
              <TextDropdown
                large
                type="single"
                items={[
                  { name: "UX Researcher", value: "researcher" },
                  { name: "Designer", value: "designer" },
                  { name: "Product Manager", value: "product" },
                  { name: "Marketing specialist", value: "marketing" },
                  { name: "Other", value: "other" },
                ]}
                value={role}
                placeholder="Choose your role"
                onChange={(value) => {
                  setRole(value);
                }}
              />
            </div>
            <div className="my-4">
              <span className="font-medium block">Where do you work?</span>
              <SmartInput
                className="w-full"
                extralarge
                initialValue={""}
                placeholder="Enter name of the company here"
                onChange={(value) => {
                  setCompany(value);
                }}
              />
            </div>
            <div className="my-4">
              <span className="font-medium block">
                How did you know about Pathway?
              </span>
              <SmartInput
                className="w-full"
                extralarge
                initialValue={""}
                placeholder="How did you find us?"
                onChange={(value) => {
                  setSource(value);
                }}
              />
            </div>
            <Button
              id="submitQuestionnaire"
              disabled={!questionnaireValidator()}
              type="primary"
              fullWidth
              name="Continue"
              large
              handler={async () => {
                await handleQuestionnaireSubmit();
                history.push("/");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;

import React, { useEffect, useRef } from "react";
import NodeInHtml from "./NodeInHtml";
import lodash from "lodash";

const getNodesWithOverflow = (node) => {
  const nodesWithOverflow = [];
  if (node.children) {
    node.children.forEach((kidNode) => {
      if (kidNode.overflowParams) nodesWithOverflow.push(kidNode);
      if (kidNode.children) nodesWithOverflow.push(...getNodesWithOverflow(kidNode));
    });
  }
  return nodesWithOverflow;
}

const FigmaReportView = ({
  onLoad,
  nodeKey,
  prototype: {
    nodesForHtml,
    images,
    settings: {
      imageScale,
    },
  },
}) => {
  const mounted = useRef(false);
  const refs = useRef({ root: null, nodes: [] });

  useEffect(() => {
    mounted.current = true;

    const loadingImages = images.map(url => new Promise((resolve) => {
      const image = new Image();
      image.onload = resolve;
      image.src = url;
    }));

    Promise.all(loadingImages).then(() => {
      if (!mounted.current) return;

      const nodePositions = {};

      const root = refs.current.root;
      const { left: rootLeft, top: rootTop } = root.getBoundingClientRect();
      const { clientWidth, clientHeight } = root;

      const blocks = refs.current.nodes.length > 0
        ? lodash.reverse(refs.current.nodes)
        : [root];

      blocks.forEach((container) => {
        container.querySelectorAll("[data-id][data-name]").forEach((element) => {
          const nodeId = element.dataset.id;
          if (nodePositions[nodeId]) return;

          const { left, top } = element.getBoundingClientRect();
          nodePositions[nodeId] = { left: left - rootLeft, top: top - rootTop };
        });
      });

      onLoad({
        width: clientWidth,
        height: clientHeight,
        nodePositions,
      });
    });

    return () => {
      mounted.current = false;
    }
  }, []);

  const nodes = [nodesForHtml[nodeKey]];
  nodes.push(...getNodesWithOverflow(nodesForHtml[nodeKey]));

  if (nodes.length === 1) {
    return (
      <div 
        className="figma-reports-view flex flex-col items-center gap-3 w-fit-content relative"
        ref={element => refs.current.root = element}
      >
        <NodeInHtml
          display="reportView"
          imageScale={imageScale}
          node={nodes[0]}
        />
      </div>
    );
  }

  return (
    <div 
      className="figma-reports-view flex flex-col items-center gap-3 w-fit-content relative"
      ref={element => refs.current.root = element}
    >
      {nodes.map((node, index) => (
        <div 
          key={`figmaReportView-${node.id}`} 
          ref={element => refs.current.nodes[index] = element}
        >
          <div>{`№${index + 1}`}</div>
          <NodeInHtml
            display="reportView"
            imageScale={imageScale}
            node={node}
          />
        </div>
      ))}
    </div>
  );
};

export default FigmaReportView;
import React from "react";

import { ReactComponent as Logo } from "../../icons/pathway-logo.svg";
import { ReactComponent as BackIcon } from "../../icons/back.svg";
import { supportLink } from "../../helpers";
import { Link } from "react-router-dom";

type SignInButtonParams = {
  disabled?: boolean,
};

export const getSignInButtonClassName = (params: SignInButtonParams = {}) => {
  const signInButtonClassName = "my-2 py-2 px-5 rounded-full object-center mx-auto text-xl font-medium tracking-tight flex items-center transition-all duration-75 ease-in";
  return params.disabled
    ? signInButtonClassName + " bg-gray-100 text-gray-600 cursor-not-allowed"
    : signInButtonClassName + " bg-white hover:bg-gray-100 text-gray-800 cursor-pointer";
};

type SignInLayoutProps = {
  title: string,
  backUrl?: string,
  children?: any,
};

const SignInLayout = ({ title, backUrl, children }: SignInLayoutProps) => {
  return (
    <div className="container mx-auto h-full text-center flex flex-col">
      <div>
        <Logo width={200} className="mx-auto mt-12" />
      </div>
      <div className="flex justify-center items-center flex-1">
        <div>
          <div
            className="relative text-3xl font-bold font-display tracking-tighter mb-8"
            style={{ padding: backUrl ? "0 60px" : 0 }}
          >
            {backUrl && (
              <Link
                className="block absolute left-0"
                to={backUrl}
                style={{ top: "50%", marginTop: "-16px" }}
              >
                <BackIcon
                  width={32}
                  height={32}
                  className="fill-current text-black cursor-pointer hover:text-gray-700 transition-all duration-75 ease-in"
                />
              </Link>
            )}
            {title}
          </div>

          {children}

          <p className="mt-8 text-sm text-gray-600">© 2022 Pathway LLC</p>
          <p className="text-sm">
            <a
              className="border-b-2"
              href={supportLink}
              target="_blank"
              rel="noopener noreferrer"
              type="text"
            >
              Contact us
            </a>
            &nbsp;•&nbsp;
            <a
              className="border-b-2"
              href="https://pthwy.design/legal/license-agreement.pdf"
              target="_blank"
              type="text"
            >
              License agreement
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInLayout;
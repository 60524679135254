import firebase from "firebase/compat";

export type LogicCondition = {
  id: string;
  value: string;
  condition: "contains" | "doesNotContain";
  operator: "and" | "or";
};

export type LogicStatement = {
  id: string;
  subconditions: LogicCondition[];
  jumpTo: string;
};

export type Logic = {
  statements: LogicStatement[];
  elseJumpTo: string;
  // Legacy
  conditions?: LogicCondition[];
  jumpTo?: string;
};

export type OpenQuestionBlock = {
  createdAt: firebase.firestore.Timestamp;
  text: string;
  image: string;
  type: "openquestion";
  questionHtml: string;
  logic?: Logic;
  blockId: string;
  isShort: boolean;
  isOptional: boolean;
};

export type ChoiceBlock = {
  createdAt: firebase.firestore.Timestamp;
  text: string;
  image: string;
  type: "choice";
  questionHtml: string;
  logic?: Logic;
  blockId: string;
  replies: Array<{ id: string; replyValue: string }>;
  other: boolean;
  randomized: boolean;
  isOptional: boolean;
  replyType: "multi" | "single";
};

export type Block = OpenQuestionBlock | ChoiceBlock;

export type ScreeningQuestions = OpenQuestionBlock | ChoiceBlock;

export type Test = {
  content: Block[];
  customPanelHiringCounter?: number;
  customPanelScreeningQuestions?: ChoiceBlock[];
  customPanelRedirects?: {
    screeningFailed: string;
    testCompleted: string;
    redundantAnswer: string;
  };
};

export const hasCustomPanelParams = (test: Test) => {
  return (
    test &&
    test.customPanelScreeningQuestions &&
    test.customPanelScreeningQuestions.length > 0
  );
};

export const getTesterSource = () => {
  const params: any = new URLSearchParams(window.location.search);

  const code = params.get("code") ? "toloka" : "link";
  const isCustomPanelTest = params.get("customPanel") === "true" ? true : false;

  if (isCustomPanelTest && code === "link") {
    return "customPanel";
  }

  return code;
};

export const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};
  urlParams.forEach((value, key) => {
    if (key !== "code" && key !== "customPanel") {
      params[key] = value;
    }
  });
  return params;
};

export const getFormattedPanelUrl = (panelUrl: string) => {
  const params: any = new URLSearchParams(window.location.search);

  const httpPattern = /^((http|https):\/\/)/;

  const formattedPanelUrl = new URL(
    httpPattern.test(panelUrl) ? panelUrl : `https://${panelUrl}`
  );

  for (const [key, value] of params.entries()) {
    formattedPanelUrl.searchParams.set(key, value);
  }

  return formattedPanelUrl.toString();
};

type GetLogic = (block: Block) => Logic | undefined;

export const getBlockLogic: GetLogic = (block: Block) => {
  if (!block.logic) {
    return undefined;
  }

  const { conditions, statements, elseJumpTo, jumpTo } = block.logic;

  if (statements) {
    return {
      elseJumpTo,
      statements,
    };
  }

  if (conditions && conditions.length > 0 && jumpTo) {
    return {
      elseJumpTo,
      statements: [
        {
          id: "statement-0",
          subconditions: conditions,
          jumpTo,
        },
      ],
    };
  }

  if (!elseJumpTo && jumpTo) {
    return {
      elseJumpTo: jumpTo,
      statements: [],
    };
  }

  return {
    elseJumpTo,
    statements: [],
  };
};

import React, { useState, useEffect } from "react";
import "../../tailwind.generated.css";
import clsx from "clsx";

import Modal from "../Modal";
import Button from "../Button";

import _ from "lodash";

import { locale, interfaceText } from "../../helpers";

import figmaIllustration from "../../illustrations/figma_instruction.png";

const Task = ({ 
  text,
  onStart,
  onGiveUp,
  isLoading,
  blockId,
  withProgress,
  progress,
}) => {
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(true);
  const [step, setStep] = useState(1);
  const [isTipPopoverOpen, setIsTipPopoverOpen] = useState(false);
  const [wasTipPopoverSeen, setWasTipPopoverSeen] = useState(false);
  const [isMissionInProgress, setIsMissionInProgress] = useState(false);

  useEffect(() => {
    setIsTaskModalOpen(true);
    setIsMissionInProgress(false);
  }, [blockId]);

  const isMobile = window.screen.width <= 480;

  return (
    <>
      <div
        className="absolute top-2 w-full cursor-pointer z-30"
        onClick={() => {
          setIsTaskModalOpen(true);
        }}
      >
        <div className="bg-blue-600 rounded-full mx-2 h-2"></div>
      </div>
      <div
        className={`tooltip absolute top-6 z-30 rounded-2xl text-white tracking-tighter text-xl p-4 leading-0 mx-auto transition-all duration-75 ease-in ${
          isTipPopoverOpen ? "visible" : "invisible"
        }`}
      >
        {interfaceText.figmaTesting[locale].showTaskTip}
        <Button
          className="mt-2"
          name={interfaceText.figmaTesting[locale].showTaskTipButton}
          handler={() => {
            setIsTipPopoverOpen(false);
            setWasTipPopoverSeen(true);
          }}
          large
          type="primary"
        />
      </div>
      <Modal
        isOpen={isTaskModalOpen}
        setIsOpen={setIsTaskModalOpen}
        width="440px"
        disallowClickOutside
        background
      >
        <div className="h-full flex flex-col items-center justify-center">
          {withProgress && progress}

          <div
            className={`p-6 mx-auto flex flex-col flex-1`}
            style={{ maxWidth: "480px" }}
          >
            <div
              className={clsx(
                isMobile ? "justify-between" : "justify-center",
                "flex h-full flex-col"
              )}
            >
              <div>
                <div className="flex text-3xl tracking-tighter font-bold font-display">
                  {interfaceText.figmaTestingIntro[locale].header}
                </div>
                <img src={figmaIllustration} className="my-4 rounded-lg" />
                <div className={clsx(step === 2 && "prototypeTestingIntro")}>
                  <div className="tracking-tighter leading-1">
                    {interfaceText.figmaTestingIntro[locale].text}
                  </div>
                </div>
                {step === 2 && (
                  <div>
                    <div className="tracking-tight mt-4">
                      <div className="flex">
                        <div>
                          <span className="font-bold font-display">
                            {interfaceText.figmaTestingMission[locale].header}
                          </span>
                          <span className="block mt-2">{text}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {step === 1 && (
                <div>
                  <Button
                    className="mt-4"
                    name={interfaceText.figmaTestingIntro[locale].button}
                    handler={() => {
                      setStep(2);
                    }}
                    large
                    type="primary"
                  />
                </div>
              )}
              {step === 2 && (
                <div>
                  <Button
                    className="mt-4"
                    name={
                      isLoading
                        ? interfaceText.figmaTestingMission[locale].loading
                        : isMissionInProgress
                        ? interfaceText.figmaTestingMission[locale]
                            .buttonWhenMissionStarted
                        : interfaceText.figmaTestingMission[locale].button
                    }
                    handler={() => {
                      if (!isLoading) {
                        setIsTaskModalOpen(false);
                        setIsMissionInProgress(true);
                        if (!wasTipPopoverSeen) {
                          setIsTipPopoverOpen(true);
                        }
                        onStart();
                      }
                    }}
                    large
                    disabled={isLoading}
                    type="primary"
                  />
                  {isMissionInProgress && (
                    <span
                      className="block text-gray-600 font-medium text-xl mt-4 cursor-pointer hover:opacity-60"
                      onClick={() => {
                        onGiveUp();
                      }}
                    >
                      {interfaceText.figmaTesting[locale].giveUpButton}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Task;

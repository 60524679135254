import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { auth } from "./firebase";
import appContext from "./AppContext";
import { setNotification, setUi } from "./actions";

export const useAuth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return user;
};

export const useSendNotification = () => {
  const { dispatch } = useContext(appContext);
  const sendNotification = (type, text) => {
    dispatch(setNotification(type, text));
    return;
  };
  return sendNotification;
};

export const useSetUi = () => {
  const { dispatch } = useContext(appContext);
  const updateUi = (key, value) => {
    dispatch(setUi(key, value));
    return;
  };
  return updateUi;
};

export const useFlag = (flag) => {
  const { state } = useContext(appContext);
  const isOn = state.user.flags?.[flag] === true;
  return isOn;
};

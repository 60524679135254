
export enum BlockType {
	openquestion = 'openquestion',
	choice = 'choice',
	firstclick = 'firstclick',
	context = 'context',
	figma = 'figma',
	preference = 'preference',
	fiveseconds = 'fiveseconds',
	cardsort = 'cardsort'
}

import React, { useState, useEffect, useRef } from "react";
import "../../tailwind.generated.css";

const Input = ({
  placeholder,
  initialValue,
  handler,
  id,
  className,
  designConfig,
  singleLine,
}) => {
  const [value, setValue] = useState(initialValue || "");

  const defaultBorderColor = "#EDEDED";
  const textareaRef = useRef(null);

  const handleChange = (e) => {
    if (handler) {
      handler("textValue", e.target.value);
    }
    setValue(e.target.value);
  };

  const setBorderColor = () => {
    textareaRef.current.style.borderColor = designConfig.buttonsColor;
  };

  const resetBorderColor = () => {
    textareaRef.current.style.borderColor = defaultBorderColor;
  };

  useEffect(() => {
    textareaRef.current.focus();
  }, []);

  useEffect(() => {
    if (value !== "") {
      setValue("");
    }
  }, [id]);

  useEffect(() => {
    if (singleLine) {
      const PreventTextAreaEnter = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      };
      textareaRef.current.addEventListener("keydown", PreventTextAreaEnter);
      return () => {
        if (textareaRef.current) {
          textareaRef.current.removeEventListener(
            "keydown",
            PreventTextAreaEnter
          );
        }
      };
    }
  }, []);

  return (
    <textarea
      ref={textareaRef}
      onMouseOver={() => {
        setBorderColor();
      }}
      onMouseOut={() => {
        textareaRef.current !== document.activeElement && resetBorderColor();
      }}
      onFocus={() => {
        setBorderColor();
      }}
      onBlur={() => {
        resetBorderColor();
      }}
      rows={singleLine ? 1 : 3}
      draggable="false"
      className={className}
      style={{
        overflowY: "hidden",
        minWidth: "200px",
      }}
      id={id}
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        handleChange(e);
      }}
    />
  );
};

export default Input;

import React from "react";
import { ReactComponent as IconQuestion } from "../../icons/q.svg";
import { supportedFeaturesUrl } from "../../utils/figma";

export default () => {
  return (
    <div className="flex p-4 my-2 rounded-lg bg-blue-100">
      <div className="text-sm">
        Please, note that currently some prototype features are not supported.
        <a
          className="text-blue-600 block mt-1"
          target="_blank"
          href={supportedFeaturesUrl}
        >
          Check supported features list ↗
        </a>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import "../../tailwind.generated.css";

import SmartInput from "../SmartInput";

import { ReactComponent as IconCheck } from "../../icons/i-check.svg";

import { locale, interfaceText } from "../../helpers";

import _ from "lodash";

const Select = ({
  type,
  data,
  handler,
  isRandomized,
  withOther,
  selectedOptions,
}) => {
  const [options, setOptions] = useState(null);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherValue, setOtherValue] = useState(null);

  const isMobile = window.screen.width <= 480;
  const otherPlaceholder = interfaceText.test[locale].otherPlaceholder;

  useEffect(() => {
    setOptions(isRandomized ? _.shuffle(data) : data);
  }, [data, isRandomized]);

  useEffect(() => {
    if (otherValue !== null) {
      if (otherValue.length > 0) {
        const newSelectedOptions = (type) => {
          switch (type) {
            case "single":
              return [{ id: "other", value: otherValue }];
            case "multi":
              return [
                ...selectedOptions.filter((option) => option.id !== "other"),
                { id: "other", value: otherValue },
              ];
            default:
              return null;
          }
        };
        handler("selectedOptions", newSelectedOptions(type));
      } else {
        handler("selectedOptions", [
          ...selectedOptions.filter((option) => option.id !== "other"),
        ]);
      }
    }
  }, [otherValue]);

  const isOptionSelected = (optionId) => {
    if (!selectedOptions) {
      return false;
    }
    return selectedOptions.some((option) => option.id === optionId);
  };

  const handleOtherValueChange = (value) => {
    setOtherValue(value);
  };

  const handleSelect = (optionId) => {
    if (isOptionSelected(optionId)) {
      handler(
        "selectedOptions",
        [...selectedOptions.filter((option) => option.id !== optionId)] // todo
      );
      return;
    }

    const chosenItem = {
      id: optionId,
      value: options.find((option) => option.id === optionId).replyValue,
    };

    const newSelectedOptions = (type) => {
      switch (type) {
        case "single":
          return [chosenItem];
        case "multi":
          return [...selectedOptions, chosenItem];
        default:
          return null;
      }
    };
    handler("selectedOptions", newSelectedOptions(type));
  };

  return (
    <>
      {options &&
        options.map((item) => (
          <div
            className={`py-1 px-4 text-xl my-3 rounded-lg border-2 cursor-pointer transition-all duration-75 ease-in flex items-center justify-between hover:border-blue-600 w-full  ${
              isOptionSelected(item.id) ? "border-blue-600" : "border-gray-200"
            } `}
            key={item.id}
            onClick={() => {
              if (withOther && showOtherInput && type === "single") {
                setShowOtherInput(false);
              }
              handleSelect(item.id);
            }}
          >
            {item.replyValue}
            {type === "multi" && (
              <IconCheck
                className={`
                ${
                  isOptionSelected(item.id) ? "visible" : "invisible"
                } flex-shrink-0 fill-current text-black
              `}
              />
            )}
          </div>
        ))}
      {withOther && (
        <>
          <div
            className={clsx(
              "py-1 px-4 text-xl my-3 rounded-lg border-2 cursor-pointer transition-all duration-75 ease-in flex items-center justify-between",
              isOptionSelected("other") ? "border-blue-600" : "border-gray-200",
              !isMobile && "hover:border-blue-600"
            )}
            onClick={() => {
              setShowOtherInput(true);
            }}
            placeholder="Other"
            id="other"
          >
            {showOtherInput ? (
              <div className="flex-1">
                <SmartInput
                  className="w-full"
                  id="other-input"
                  placeholder={otherPlaceholder}
                  initialValue=""
                  onChange={(value) => {
                    handleOtherValueChange(value);
                  }}
                  onBlur={(value) => {
                    if (value.length === 0) {
                      setShowOtherInput(false);
                    }
                  }}
                  focusOnMount
                />
              </div>
            ) : (
              interfaceText.test[locale].other
            )}
            {type === "multi" && (
              <IconCheck
                className={`
                ${
                  isOptionSelected("other") ? "visible" : "invisible"
                } flex-shrink-0 fill-current text-black
              `}
              />
            )}
          </div>
        </>
      )}
      {type === "multi" && (
        <div className="mt-4 text-xs text-gray-600">
          {interfaceText.test[locale].multipleChoiceTip}
        </div>
      )}
    </>
  );
};

export default Select;

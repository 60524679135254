import React, { useEffect, useState } from "react";
import {
  withClickmap,
  Clickmap,
  FigmaAreas,
  FigmaClicks,
} from "../Clickmaps";
import { FigmaClicksMapLayerTabs, tabsLabels } from './FigmaClicksMapLayerTabs';
import Tabs from "../FirstClickReport/Tabs";
import ReactDOM from "react-dom";
import Switcher from "../../Switcher";
import { ReactComponent as IconFigma } from "../../../icons/figma.svg";
import Loader from "../../Loader";
import lodash from "lodash";

const rightColumnStyle = {
  minWidth: 230,
  maxWidth: 230,
};

const keyboardButtonHintClass = 'inline-block px-1 border-default rounded-md';
function ModalHeader({ name, caption }) {
  return (
    <div className="flex justify-between w-full p-6 text-white">
      <div className="flex font-medium text-3xl">
        <IconFigma width={48} height={48} />
        <div className="ml-2">
          <div>{name}</div>
          <div className="opacity-50">{caption}</div>
        </div>
      </div>
      <div className="opacity-50 text-xs" style={rightColumnStyle}>
        Press{" "}
        <div className={keyboardButtonHintClass}>
          esc
        </div>{" "}
        to close,{" "}
        <div className={keyboardButtonHintClass}>←</div> and{" "}
        <div className={keyboardButtonHintClass}>→</div>
        <br />
        to switch between screens.
      </div>
    </div>
  );
}

function ScreenStats({ stats }) {
  return (
    <div className="figma-screen-stats text-xl flex flex-col gap-1">
      {stats.map(([name, value], index) => (
        <>
          {index > 0 && <div
            className="figma-screen-stats__delimiter bg-gray-300 opacity-25"
            style={{ height: "2px" }}
          />}
          <div className="figma-screen-stats__item flex justify-between">
            <span>{name}</span>
            <span>{value}</span>
          </div>
        </>
      ))}
    </div>
  );
}

function ModalBody({
  loading,
  screen,
  type,
  clickmap,
  updateClickmapAreas,
  figmaImage,
}) {
  const { stats, name, caption, image } = screen;

  const [activeTab, setActiveTab] = useState(tabsLabels.CLICKS);
  const [showClicksOrder, setShowClicksOrder] = useState(true);

  const tabs = Object.entries(tabsLabels).map(([, label]) => ({
    onClick: () => setActiveTab(label),
    isActive: activeTab === label,
    label,
  }));

  let clicks = [];

  if (figmaImage) {
    screen.clicks.forEach((click) => {
      const { nodeId: clickNodeId } = click.clickData;
      const left = lodash.get(figmaImage, ["nodePositions", clickNodeId, "left"], 0);
      const top = lodash.get(figmaImage, ["nodePositions", clickNodeId, "top"], 0);
      const clickCopy = lodash.cloneDeep(click);

      clickCopy.clickData.left += left;
      clickCopy.clickData.top += top;
      clicks.push(clickCopy);
    });
  } else {
    clicks = screen.clicks;
  }

  const canShowClicksOrder = !clicks.some((click) => {
    return !click.timestamp;
  });

  if (canShowClicksOrder) {
    clicks.sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
  }

  const isByScreenMode = type === "byScreen";

  return (
    <div className="figma-modal-body overflow-scroll h-screen w-full">
      <ModalHeader name={name} caption={caption} />
      <div className="figma-modal-body__container flex p-6 pt-0 text-white">

        {loading &&
          <div className="figma-modal-body__loader flex-auto rounded-2xl bg-gray-800 p-8 relative">
            <Loader />
            <div className="invisible w-fit-content inset-0 w-px h-px overflow-auto absolute">
              {figmaImage && figmaImage.component}
            </div>
          </div>
        }
        {!loading && isByScreenMode &&
          <div className="figma-modal-body figma-modal-body_mode_byscreen flex-auto rounded-2xl w-5/6 bg-gray-800 p-8">
            <Tabs tabs={tabs} />
            <div className="pt-6">
              <Clickmap
                width={clickmap.width}
                height={clickmap.height}
                areas={clickmap.areas}
                updateAreas={updateClickmapAreas}
                clicks={clicks}
                imageUrl={image}
                figmaImage={figmaImage}
                activeTab={activeTab}
                clicksViewComponent={FigmaClicksMapLayerTabs}
                showClicksOrder={showClicksOrder}
              />
            </div>
          </div>
        }
        {!loading && !isByScreenMode &&
          <div className="figma-modal-body figma-modal-body_mode_regular flex-auto rounded-2xl w-5/6 bg-gray-800 p-8">
            <Clickmap
              width={clickmap.width}
              height={clickmap.height}
              areas={clickmap.areas}
              updateAreas={updateClickmapAreas}
              hideAreas={true}
              clicks={clicks}
              imageUrl={image}
              figmaImage={figmaImage}
              activeTab={activeTab}
              clicksViewComponent={FigmaClicks}
              showClicksOrder={showClicksOrder}
            />
          </div>
        }
        <div className="flex-none ml-6" style={rightColumnStyle}>
          <ScreenStats stats={stats} />
          <div className="pt-3">
            {!loading && isByScreenMode &&
              <FigmaAreas
                areas={clickmap.areas}
                updateAreas={updateClickmapAreas}
                responses={clicks}
                width={clickmap.width}
                height={clickmap.height}
              />
            }
            {!loading && !isByScreenMode && canShowClicksOrder && (
              <div className="mt-4">
                <Switcher
                  text="Show clicks order"
                  handler={setShowClicksOrder}
                  isOn={showClicksOrder}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const FigmaScreenModal = (props) => {
  const { screen, setActiveScreenPath } = props;

  useEffect(() => {
    const onKeydown = ({ key }) => {
      if (key === "Escape") setActiveScreenPath(null);

      if (key === "ArrowRight" && screen.nextScreenPath)
        setActiveScreenPath(screen.nextScreenPath);

      if (key === "ArrowLeft" && screen.prevScreenPath) {
        setActiveScreenPath(screen.prevScreenPath);
      }
    };

    document.addEventListener("keydown", onKeydown);
    document.body.style.overflow = "hidden";

    return () => {
      document.removeEventListener("keydown", onKeydown);
      document.body.style.overflow = "visible";
    };
  }, [screen]);

  return ReactDOM.createPortal(
    <div className="modal-container block">
      <ModalBody
        screen={props.screen}
        clickmap={props.clickmap}
        figmaImage={props.figmaImage}
        loading={props.loading}
        updateClickmapAreas={props.updateClickmapAreas}
        prototype={props.prototype}
        key={props.key}
        setActiveScreenPath={props.setActiveScreenPath}
        clickmapOwnerId={props.clickmapOwnerId}
        nodeForHtml={props.nodeForHtml}
        imageUrl={props.imageUrl}
        type={props.type}
      />
    </div>,
    document.body
  );
};

export default withClickmap(FigmaScreenModal);

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyALXWX7d21iLZ6ZKL_pt7xOWJJXMOav8m0",
  authDomain: "userpath-6801d.firebaseapp.com",
  databaseURL: "https://userpath-6801d.firebaseio.com",
  projectId: "userpath-6801d",
  storageBucket: "userpath-6801d.appspot.com",
  messagingSenderId: "706645617409",
  appId: "1:706645617409:web:bb30c0bce34ea8698c4900",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const FieldValue = firebase.firestore.FieldValue;
export const Timestamp = firebase.firestore.Timestamp;

export const getAuthToken = () =>
  new Promise((resolve) => {
    //@ts-ignore
    auth.currentUser.getIdToken(true).then(resolve);
  });

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return auth.signInWithPopup(provider);
};

export const signInWithFacebook = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  return auth.signInWithPopup(provider);
};

export const signInWithEmail = (email: string, redirectUrl: string) => {
  let url =
    process.env.NODE_ENV === "development"
      ? `http://${window.location.host}/login/with-email`
      : "https://app.pthwy.design/login/with-email";

  if (redirectUrl) {
    url = url + `?redirectUrl=${redirectUrl}`;
  }

  return auth.sendSignInLinkToEmail(email, {
    url,
    handleCodeInApp: true,
  });
};

import React from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

import { ReactComponent as IconDropdown } from "../icons/i-dropdown.svg";

import Dropdown, { DropdownOption } from "./Dropdown";
import Loader from "./Loader";

const TextDropdown = ({
  title = "",
  small,
  large = false,
  items,
  onChange,
  value,
  placeholder,
  className,
  isLoadingValue = false,
  light,
}: {
  title?: string;
  small?: boolean;
  large?: boolean;
  position?: string;
  items: DropdownOption[];
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  className?: string;
  isLoadingValue?: boolean;
  light?: boolean;
}) => {
  const getItemNamebyValue = (value: string | undefined) => {
    const selectedItem = items.find((item) => item.value === value);
    const selectedItemName = selectedItem?.name;
    if (!selectedItemName) {
      return placeholder;
    } else {
      return selectedItemName;
    }
  };

  const isEmpty = value === "" || value === null || value === undefined;

  return (
    <div className={className}>
      {title && (
        <div className="font-medium text-sm my-1 text-gray-700">{title}</div>
      )}
      <div className="flex items-center">
        <Dropdown
          type="single"
          items={items}
          handler={onChange}
          value={value ? value : undefined}
          light={light}
        >
          <div className="flex items-center justify-start">
            <div
              className={clsx(
                "tracking-tight hover:opacity-75",
                items.length === 0 && "cursor-not-allowed",
                small && "text-base",
                large && "text-2xl",
                !(small || large) && "text-xl",
                isEmpty && large && "text-gray-500",
                isEmpty && !large && "text-gray-600",
                items.length !== 0 && "cursor-pointer"
              )}
            >
              {isLoadingValue ? <Loader /> : getItemNamebyValue(value)}
            </div>
            <IconDropdown className="ml-1 cursor-pointer" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default TextDropdown;

import React, { useState, useRef } from "react";
import clsx from "clsx";
import "../tailwind.generated.css";

export type DropdownOption = {
  name: string | JSX.Element;
  value: string;
  disabled?: boolean;
};

export type Section = {
  sectionName: string;
};

type DropdownProps = {
  type: "single" | "none";
  handler: (value: string) => void;
  items: Array<DropdownOption | Section>;
  header?: string;
  children?: React.ReactNode;
  value?: string;
  light?: boolean;
};

const Dropdown = ({
  type,
  items,
  header,
  handler,
  children,
  value,
  light,
}: DropdownProps) => {
  // const positionStyles = {
  //   left: {},
  //   center: { left: "50%", transform: "translate(-50%, 0%)" },
  //   right: { right: "100%", transform: "translate(50%, 0%)" },
  // };

  const [isOpen, setIsOpen] = useState(false);
  const [_, setSelectedOptions] = useState(value);

  const dropdownRef = useRef(null);

  const isEmpty = items.length === 0;

  const handleSelect = (itemValue: string) => {
    if (type === "none") {
      handler(itemValue);
      setIsOpen(false);
    }

    if (type === "single") {
      handler(itemValue);
      setSelectedOptions(itemValue);
      setIsOpen(false);
    }
  };

  return (
    <div
      className="dropdownController relative"
      tabIndex={-1}
      onBlur={() => setIsOpen(false)}
    >
      <div
        ref={dropdownRef}
        onClick={(e) => {
          if (!isEmpty) {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }
        }}
      >
        {children}
      </div>
      <div className={`z-999 absolute ${isOpen ? "block" : "hidden"}`}>
        <div
          className={clsx(
            "rounded-lg p-2 mt-1 font-medium",
            light ? "bg-white shadow-md border-1 border-gray-300" : "bg-black"
          )}
        >
          {header && header}
          {items.map((item) => {
            if ("sectionName" in item) {
              return (
                <div
                  className="text-sm text-gray-600 p-1"
                  key={item.sectionName}
                >
                  {item.sectionName}
                </div>
              );
            }
            return (
              <div
                key={item.value}
                className={`flex items-center cursor-pointer rounded-md p-1 w-full ${light ? "text-black shadow-none" : "text-white"
                  } hover:opacity-75 transition-all duration-75 ease-in`}
                onClick={(e) => {
                  if (!item.disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelect(item.value);
                  }
                }}
              >
                <div
                  className={`cursor-pointer rounded-md w-full whitespace-nowrap mr-4 ${item.disabled
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer"
                    }`}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;

import React from "react";
import "../../../tailwind.generated.css";

import ClickViews from "./ClickViews";
import TooltipWithIcon from "../../TooltipWithIcon";

import { calcMedian } from "../utils.ts";

function FirstClickReport({ text, imageUrl, responses, testId, blockId }) {
  // calc average response time

  const averageResponseTime = (
    Math.floor(
      responses.reduce((acc, curr) => {
        return acc + curr.clickData.responseTime;
      }, 0) / responses.length
    ) / 1000
  ).toFixed(2);

  // calculate median response time

  const numbers = responses.map((response) => response.clickData.responseTime);

  const medianResponseTime = (calcMedian(numbers) / 1000).toFixed(2);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="firstclick-report__name text-2xl font-medium w-2/3">{text}</div>
      </div>

      <div className="firstclick-report__metric my-4 text-gray-700 text-sm font-medium">
        Average response time: {averageResponseTime} s
      </div>
      <div className="firstclick-report__metric mt-4 mb-6 text-gray-700 text-sm font-medium flex items-center">
        <span>Median response time: {medianResponseTime} s</span>
        <TooltipWithIcon
          className="ml-1 inline"
          size={16}
          text={
            <>
              Median is the value separating the higher half from the lower half
              of testers responses.
              <br />
              <br />
              It is generally unaffected by outliers: responses with a very long
              response time.
            </>
          }
        />
      </div>

      <ClickViews
        imageUrl={imageUrl}
        responses={responses}
        clickmapOwnerId={`firstclick-${testId}-${blockId}`}
      />
    </>
  );
}

export default FirstClickReport;

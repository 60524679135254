import React from "react";
import PropTypes from "prop-types";
import Preview from "./Preview";
import Tabs from "../../Tabs";
import lodash from "lodash";

const FigmaReportByTester = ({
  byTester,
  setActiveScreenPath,
  isSummaryReport,
  prototype,
}) => (
  <>
    {byTester.map(({ number, givenUp, time, screens }) => (
      <div className="flex justify-items-stretch my-6" key={number}>
        <div style={{ whiteSpace: "nowrap", minWidth: 170 }}>
          {isSummaryReport && <div className="text-2xl">{number}</div>}
          <div
            className={`${
              isSummaryReport
                ? "text-xs font-medium mt-2"
                : "text-xl tracking-tight"
            } text-gray-600`}
          >
            {givenUp && <span className="text-orange-600">Gave up</span>}
            {!givenUp && <span className="text-green-700">Succeed</span>}
            <br />
            Total time: <span className="text-black">{time}</span>
          </div>
        </div>
        <div className="ml-6">
          <div className="flex flex-wrap">
            {screens.map(({ path, image, name, nodeKey }, index) => (
              <div className="flex flex-nowrap mr-4 mb-4" key={index}>
                <Preview
                  onClick={() => setActiveScreenPath(path)}
                  image={image}
                  name={name}
                  width={72}
                  settings={lodash.get(prototype, "settings")}
                  node={lodash.get(prototype, ["nodesForHtml", nodeKey])}
                />
                {index < screens.length - 1 && (
                  <div className="flex items-center justify-center font-medium">
                    <span>→</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    ))}
  </>
);

const FigmaReportByScreen = ({ byScreen, setActiveScreenPath, prototype }) => (
  <div className="flex flex-wrap my-8">
    {byScreen.screens.map(({ path, image, name, nodeKey }, index) => (
      <Preview
        key={index}
        width={148}
        onClick={() => setActiveScreenPath(path)}
        image={image}
        name={name}
        settings={lodash.get(prototype, "settings")}
        node={lodash.get(prototype, ["nodesForHtml", nodeKey])}
      />
    ))}
  </div>
);

const FigmaReportView = ({
  byScreen,
  byTester,
  setActiveScreenPath,
  isSummaryReport,
  prototype,
}) => (
  <>
    {isSummaryReport ? (
      <Tabs>
        <div label="By Tester">
          <FigmaReportByTester
            byTester={byTester}
            setActiveScreenPath={setActiveScreenPath}
            isSummaryReport={isSummaryReport}
            prototype={prototype}
          />
        </div>
        <div label="By Screen">
          <FigmaReportByScreen
            byScreen={byScreen}
            setActiveScreenPath={setActiveScreenPath}
            prototype={prototype}
          />
        </div>
      </Tabs>
    ) : (
      <div label="By Tester">
        <FigmaReportByTester
          byTester={byTester}
          setActiveScreenPath={setActiveScreenPath}
          prototype={prototype}
        />
      </div>
    )}
  </>
);

FigmaReportView.propTypes = {
  byScreen: PropTypes.shape({
    screens: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        path: PropTypes.array,
        nextScreenPath: PropTypes.array,
        prevScreenPath: PropTypes.array,
        clicks: PropTypes.array,
        stats: PropTypes.array,
      })
    ),
  }),
  byTester: PropTypes.arrayOf(PropTypes.shape({
    answerId: PropTypes.string,
    givenUp: PropTypes.bool,
    time: PropTypes.string,
    number: PropTypes.number,
    screens: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        path: PropTypes.array,
        nextScreenPath: PropTypes.array,
        prevScreenPath: PropTypes.array,
        clicks: PropTypes.array,
        stats: PropTypes.array,
      })
    ),
  })),
  setActiveScreenPath: PropTypes.func,
};

export default FigmaReportView;

import React, { useEffect, useRef } from "react";
import HeatmapJS from "heatmap.js";
import lodash from "lodash";
import { IClickmapProps } from './Clickmap';

type FigmaHeatmapProps = Pick<IClickmapProps, 'width' | 'height' | 'clicks'>;

function FigmaHeatmap({ width, height, clicks }: FigmaHeatmapProps) {
  const heatmapRef = useRef<HTMLDivElement & { heatmapInstance: HeatmapJS.Heatmap<"value", "x", "y"> }>(null);

  useEffect(() => {
    if (!heatmapRef.current || !clicks) return;
    const clicksWeight: Record<string, number> = {};

    const heatmapInstance = !heatmapRef.current.heatmapInstance
      ? HeatmapJS.create({ container: heatmapRef.current })
      : heatmapRef.current.heatmapInstance;

    heatmapRef.current.heatmapInstance = heatmapInstance;

    clicks.forEach(({ clickData }) => {
      const x = lodash.round(clickData.left, -1);
      const y = lodash.round(clickData.top, -1);

      const key = `${x}${y}`;

      clicksWeight[key] = lodash.get(clicksWeight, key, 0) + 1;
    });

    const points = clicks.map(({ clickData }) => ({
      x: Math.floor(clickData.left),
      y: Math.floor(clickData.top),
      value: 1,
    }));

    heatmapInstance.setData({
      max: lodash.max(lodash.values(clicksWeight)) as number,
      min: 0,
      data: points
    });
  }, [width, height, clicks]);

  return (
    <div ref={heatmapRef} style={{ width, height }} />
  );
}

export default FigmaHeatmap;

import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";
import "../tailwind.generated.css";

const Tooltip = ({
  content,
  anchorClassName,
  children,
  position,
  disabled,
}) => {
  const tooltipAnchorRef = useRef();
  const tooltipRef = useRef();
  const [coordinates, setCoordinates] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const calcCoordinates = () => {
      if (!tooltipAnchorRef.current || !tooltipRef.current) {
        return null;
      }

      const anchor = tooltipAnchorRef.current.getBoundingClientRect();
      const tooltip = tooltipRef.current.getBoundingClientRect();

      const getMargin = (position) => {
        switch (position) {
          case "top":
            return {
              top: -tooltip.height - 8,
              left: anchor.width / 2 - tooltip.width / 2,
            };
          case "top-left":
            return {
              top: -tooltip.height - 8,
              left: 0,
            };
          case "right":
            return {
              top: anchor.height / 2 - tooltip.height / 2,
              left: tooltip.width + 8,
            };
          case "bottom":
            return {
              top: anchor.height + 8,
              left: anchor.width / 2 - tooltip.width / 2,
            };
          case "bottom-left":
            return {
              top: anchor.height + 8,
              left: 0,
            };
          case "left":
            return {
              top: anchor.height / 2 - tooltip.height / 2,
              left: -tooltip.width - 8,
            };
        }
      };
      setCoordinates({
        top: Math.round(anchor.top + window.scrollY + getMargin(position).top),
        left: Math.round(
          anchor.left + window.scrollX + getMargin(position).left
        ),
      });
    };
    // Wait for effects to execute
    setTimeout(() => {
      calcCoordinates();
    }, 500);
    window.addEventListener("resize", calcCoordinates);
    window.addEventListener("scroll", calcCoordinates, true);
    return () => {
      window.removeEventListener("resize", calcCoordinates);
      window.removeEventListener("scroll", calcCoordinates);
    };
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <div
          className={clsx(
            "absolute top-0 left-0 rounded-xl bg-gray-900 p-3 text-white text-sm font-normal z-9999 transition-all duration-75 ease-in opacity-0",
            isVisible ? "opacity-100 visible" : "invisible"
          )}
          style={
            coordinates && {
              transform: `translate3d(${coordinates.left}px, ${coordinates.top}px, 0)`,
            }
          }
          ref={tooltipRef}
        >
          {content}
        </div>,
        document.body
      )}
      <div
        className={clsx("cursor-pointer", anchorClassName && anchorClassName)}
        ref={tooltipAnchorRef}
        onMouseMove={() => {
          if (!disabled) {
            setIsVisible(true);
          }
        }}
        onMouseLeave={() => {
          setIsVisible(false);
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Tooltip;

import React from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

const Loader = ({ className = "" }) => {
  return (
    <div className={clsx(className, "spinner")}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default Loader;

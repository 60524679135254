import React, { useEffect, useRef } from "react";
import HeatmapJS from "heatmap.js";
import lodash from "lodash";
import { IClickmapProps } from './Clickmap';

type HeatmapProps = Pick<IClickmapProps, 'width' | 'height' | 'responses'>;

function Heatmap({ width, height, responses }: HeatmapProps) {
  const heatmapRef = useRef<HTMLDivElement & { heatmapInstance: HeatmapJS.Heatmap<"value", "x", "y"> }>(null);

  useEffect(() => {

    if (!heatmapRef.current) return;

    const clicksWeight: Record<string, number> = {};

    const heatmapInstance = !heatmapRef.current.heatmapInstance
      ? HeatmapJS.create({ container: heatmapRef.current })
      : heatmapRef.current.heatmapInstance;

    heatmapRef.current.heatmapInstance = heatmapInstance;

    responses.forEach(({ clickData }) => {
      const x = lodash.round(clickData.left * width, -1);
      const y = lodash.round(clickData.top * height, -1);

      const key = `${x}${y}`;

      clicksWeight[key] = lodash.get(clicksWeight, key, 0) + 1;
    });

    const points = responses.map(({ clickData }) => ({
      x: Math.floor(clickData.left * width),
      y: Math.floor(clickData.top * height),
    } as HeatmapJS.DataPoint<"value", "x", "y">));

    heatmapInstance.setData({
      max: lodash.max(lodash.values(clicksWeight)) as number,
      min: 0,
      data: points
    });
  }, [width, height, responses]);

  return (
    <div ref={heatmapRef} style={{ width, height }} />
  );
}

export default Heatmap;

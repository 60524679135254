import React from "react";
import "../tailwind.generated.css";
import { auth } from "../firebase.ts";
import { useHistory } from "react-router-dom";

import Button from "../components/Button";

const SignOut = () => {
  const history = useHistory();

  return (
    <Button
      handler={() => {
        auth.signOut();
      }}
      name="Sign out"
      type="secondary"
    />
  );
};

export default SignOut;

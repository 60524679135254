import React from "react";
import "../tailwind.generated.css";
import { useHistory } from "react-router-dom";

import { ReactComponent as IconLogo } from "../icons/pathway-logo.svg";

const Logo = ({ clickable = true }) => {
  const history = useHistory();

  if (clickable) {
    return (
      <div
        className="hover:opacity-75 cursor-pointer"
        onClick={() => {
          history.push("/");
        }}
      >
        <IconLogo />
      </div>
    );
  }

  return <IconLogo />;
};

export default Logo;

import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import lodash from "lodash";

const getClickData = (
  { clientX, clientY, target },
  thisNode,
  horizontalCompressionCoef
) => {
  const { left, top } = target.getBoundingClientRect();

  const onClickAction = lodash.get(thisNode, "reactions.ON_CLICK.action");
  const action =
    onClickAction && onClickAction.navigation === "NAVIGATE"
      ? onClickAction
      : undefined;

  return {
    action, // https://www.figma.com/plugin-docs/api/Action/
    clickData: {
      nodeId: thisNode.id,
      x: (clientX - left) / horizontalCompressionCoef,
      y: (clientY - top) / horizontalCompressionCoef,
    },
  };
};

const throttledOnClick = lodash.throttle((onClick, data) => {
  onClick(data);
}, 100);

const getChildren = ({ numberOfFixedChildren = 0, children = [] }) => ({
  staticChildren: lodash.dropRight(children, numberOfFixedChildren),
  fixedChildren: lodash.takeRight(children, numberOfFixedChildren),
});

const WithScroll = ({ children, initialParams, viewProps = {}, ...params }) => {
  const scrollRef = useRef();

  useEffect(() => {
    const { current: scrollBlock } = scrollRef;
    const viewBlock = scrollBlock.view;

    let position = {
      top: 0,
      left: 0,
      x: 0,
      y: 0,
    };

    if (initialParams) {
      const { scrollLeft, scrollTop } = initialParams;
      if (scrollLeft) scrollBlock.scrollLeft(scrollLeft);
      if (scrollTop) scrollBlock.scrollTop(scrollTop);
    }

    viewBlock.style.cursor = "default";

    const mouseMoveHandler = (e) => {
      // How far the mouse has been moved
      const dx = e.clientX - position.x;
      const dy = e.clientY - position.y;

      // Scroll the element
      scrollBlock.scrollTop(position.top - dy);
      scrollBlock.scrollLeft(position.left - dx);
    };

    const mouseUpHandler = () => {
      viewBlock.style.cursor = "default";
      viewBlock.style.removeProperty("user-select");

      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

    const mouseDownHandler = (e) => {
      viewBlock.style.cursor = "default";
      viewBlock.style.userSelect = "none";

      position.left = scrollBlock.getScrollLeft();
      position.top = scrollBlock.getScrollTop();
      position.x = e.clientX;
      position.y = e.clientY;

      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    viewBlock.addEventListener("mousedown", mouseDownHandler);

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };
  }, []);

  return (
    <Scrollbars
      {...params}
      renderView={(props) => <div {...props} {...viewProps} />}
      ref={scrollRef}
    >
      {children}
    </Scrollbars>
  );
};

const NodeView = ({
  nodeWrapper,
  parent,
  imageScale,
  horizontalCompressionCoef,
  onClick,
  node,
  fixed,
  display,
}) => {
  const { staticChildren, fixedChildren } = getChildren(node);

  const nodeProps = {
    style: {
      position: "absolute",
      width: node.width * imageScale,
      height: node.height * imageScale,
      overflow: "hidden",
    },
  };

  if (onClick) {
    nodeProps.onClick = (event) => {
      const hasReactions =
        node.reactions && Object.keys(node.reactions).length > 0;

      if (hasReactions) {
        event.stopPropagation();
      }

      if (hasReactions || !event.didClickHandled) {
        throttledOnClick(
          onClick,
          getClickData(event, node, horizontalCompressionCoef)
        );
        event.didClickHandled = true;
      }
    };
  }

  if (!lodash.isEmpty(node.reactions)) {
    if (lodash.get(node, "reactions.ON_CLICK.action") && onClick) {
      nodeProps.style.cursor = "pointer";
    }
  }

  if (parent) {
    lodash.set(nodeProps, "style.left", `${node.x * imageScale}px`);
    lodash.set(nodeProps, "style.top", `${node.y * imageScale}px`);
  }

  if (fixed) {
    lodash.set(nodeProps, "style.zIndex", "20");

    if (parent && parent.image) {
      lodash.set(
        nodeProps,
        "style.background",
        `url(${parent.image}) -${node.x * imageScale}px -${
          node.y * imageScale
        }px no-repeat`
      );
    }

    if (node.constraints && parent) {
      if (node.constraints.horizontal === "MAX") {
        lodash.set(
          nodeProps,
          "style.right",
          `${parent.width - node.x - node.width}px`
        );
        lodash.set(nodeProps, "style.left", "");
      }
      if (node.constraints.vertical === "MAX") {
        lodash.set(
          nodeProps,
          "style.bottom",
          `${parent.height - node.y - node.height}px`
        );
        lodash.set(nodeProps, "style.top", "");
      }
    }
  }

  if (node.image) {
    lodash.set(
      nodeProps,
      "style.background",
      `url(${node.image}) 0 0 no-repeat`
    );
  }

  const staticNodes = staticChildren.map((childNode) => (
    <NodeInHtml
      key={childNode.id}
      display="actualSize"
      parent={node}
      onClick={onClick}
      nodeWrapper={nodeWrapper}
      imageScale={imageScale}
      node={childNode}
    />
  ));

  if (node.overflowParams) {
    const overflowParams = node.overflowParams;

    nodeProps.style.overflow = "";
    nodeProps.style.position = "absolute";

    // In reportView, the scrolling nodes need to be
    // displayed in full height and width.
    if (display === "reportView") {
      nodeProps.style.background = `url(${overflowParams.image}) 0 0 no-repeat`;
      nodeProps.style.height = overflowParams.height * imageScale;
      nodeProps.style.width = overflowParams.width * imageScale;
    } else {
      const nodeViewWithScroll = (
        <WithScroll
          data-id={node.id}
          data-name={node.name}
          data-has-scroll
          initialParams={{
            scrollLeft: overflowParams.scrollLeft * imageScale,
            scrollTop: overflowParams.scrollTop * imageScale,
          }}
        >
          <div
            onClick={nodeProps.onClick}
            style={{
              position: "relative",
              background: `url(${overflowParams.image}) 0 0 no-repeat`,
              height: overflowParams.height * imageScale + "px",
              width: overflowParams.width * imageScale + "px",
              overflow: "hidden",
            }}
          >
            {staticNodes}
          </div>
        </WithScroll>
      );

      if (parent && parent.numberOfFixedChildren) {
        return (
          <div style={nodeProps.style}>
            {fixedChildren.map((childNode) => (
              <NodeInHtml
                key={childNode.id}
                display="actualSize"
                parent={node}
                onClick={onClick}
                nodeWrapper={nodeWrapper}
                imageScale={imageScale}
                node={childNode}
                fixed
              />
            ))}
            {nodeViewWithScroll}
          </div>
        );
      }

      return <div style={nodeProps.style}>{nodeViewWithScroll}</div>;
    }
  }

  return (
    <div data-id={node.id} data-name={node.name} {...nodeProps}>
      {staticNodes}
    </div>
  );
};

const NodeInHtml = (props) => {
  const {
    parent,
    node,
    onClick,
    nodeWrapper,
    imageScale,
    horizontalCompressionCoef,
    display,
  } = props;
  const { fixedChildren } = getChildren(node);

  const [state, setState] = useState({
    windowHeight: 0,
    windowWidth: 0,
    // this param is used in getClickData to send clicks correctly in case of window.Width < node.width
    horizontalCompressionCoef: horizontalCompressionCoef || 1,
  });

  const layout = useRef();
  const scaling = useRef();
  const fixed = useRef();
  const wrapper = useRef();

  useEffect(() => {
    if (parent) return;

    const handleResize = () => {
      const windowWidth = !["actualSize", "reportView"].includes(display)
        ? layout.current.parentNode.clientWidth
        : node.width;

      const windowHeight = !["actualSize", "reportView"].includes(display)
        ? layout.current.parentNode.clientHeight
        : node.height;

      layout.current.style.height = windowHeight + "px";
      layout.current.style.width = windowWidth + "px";

      // used only to scale the main node
      let scaleRate = 1 / imageScale;

      let horizontalCompressionCoef = 1;

      if (windowWidth < node.width) {
        horizontalCompressionCoef = windowWidth / node.width;
        scaleRate = scaleRate * horizontalCompressionCoef;
      }

      setState({ windowHeight, windowWidth, horizontalCompressionCoef });

      scaling.current.style.position = "relative";
      scaling.current.style.transformOrigin = "0 0";
      // scaling happens only for the main node (!parent === true)
      // for the rest of nodes passing only horizontalCompressionCoef to use it inside getClickData method
      scaling.current.style.transform = `scale(${scaleRate})`;
      scaling.current.style.width = `${windowWidth / scaleRate}px`;
      scaling.current.style.height =
        display === "actualSize"
          ? `${node.node / scaleRate}px`
          : `${windowHeight / scaleRate}px`;

      wrapper.current.style.position = "relative";
      wrapper.current.style.width = node.width * imageScale + "px";
      wrapper.current.style.height = node.height * imageScale + "px";

      if (display === "reportView" && node.overflowParams) {
        wrapper.current.style.width =
          node.overflowParams.width * imageScale + "px";
        wrapper.current.style.height =
          node.overflowParams.height * imageScale + "px";
        scaling.current.style.width = node.overflowParams.width + "px";
        scaling.current.style.height = node.overflowParams.height + "px";
        layout.current.style.width = node.overflowParams.width + "px";
        layout.current.style.height = node.overflowParams.height + "px";
      }

      fixed.current.style.position = "absolute";
      fixed.current.style.width = node.width * imageScale + "px";
      fixed.current.style.height =
        Math.min(windowHeight / scaleRate, node.height * imageScale) + "px";

      const marginTop =
        (windowHeight / scaleRate - node.height * imageScale) / 2;
      if (marginTop > 0) {
        wrapper.current.style.marginTop = marginTop + "px";
        fixed.current.style.marginTop = marginTop + "px";
      }

      const marginLeft =
        (windowWidth / scaleRate - node.width * imageScale) / 2;
      if (marginLeft > 0) {
        wrapper.current.style.marginLeft = marginLeft + "px";
        fixed.current.style.marginLeft = marginLeft + "px";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!parent) {
    return (
      <div
        data-id={node.id}
        ref={layout}
        className="layout relative overflow-hidden"
      >
        <div ref={scaling} className="scaling-layout">
          <div ref={fixed}>
            {fixedChildren.map((childNode) => (
              <NodeInHtml
                key={childNode.id}
                display="actualSize"
                parent={node}
                onClick={onClick}
                nodeWrapper={nodeWrapper}
                imageScale={imageScale}
                horizontalCompressionCoef={state.horizontalCompressionCoef}
                node={childNode}
                fixed
              />
            ))}
          </div>

          {["actualSize", "reportView"].includes(display) && (
            <div ref={wrapper} className="wrapper">
              <NodeView
                horizontalCompressionCoef={state.horizontalCompressionCoef}
                {...props}
              />
            </div>
          )}

          {!["actualSize", "reportView"].includes(display) && (
            <WithScroll
              data-has-scroll
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
              }}
            >
              <div ref={wrapper} className="wrapper">
                <NodeView
                  horizontalCompressionCoef={state.horizontalCompressionCoef}
                  {...props}
                />
              </div>
            </WithScroll>
          )}
        </div>
      </div>
    );
  }

  return (
    <NodeView
      horizontalCompressionCoef={state.horizontalCompressionCoef}
      {...props}
    />
  );
};

export default NodeInHtml;

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "../tailwind.generated.css";

const Modal = ({
  isOpen,
  width,
  height = undefined,
  setIsOpen,
  background = true,
  children,
  disallowClickOutside = undefined,
  backgroundColor = "#FFFFFF",
  noRoudnedCorners = undefined,
  backdrop = undefined,
}) => {
  const isMobile = window.screen.width <= 480;

  const myRef = useRef();

  const modalBackgroundColor = () => {
    if (!background) {
      return {};
    } else {
      return { backgroundColor: backgroundColor ? backgroundColor : "#FFFFFF" };
    }
  };

  const handleClickOutside = (e) => {
    if (
      e.target.classList.contains("modal-container") &&
      !disallowClickOutside
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.body.style.overflow = "visible";
      };
    }
  }, [isOpen]);

  return ReactDOM.createPortal(
    isOpen ? (
      <div
        className="modal-container"
        style={{
          ...(backdrop ? { backdropFilter: "blur(8px)" } : {}),
        }}
      >
        <div
          className="modal-dialog"
          style={{
            ...(isMobile
              ? { width: "100%", height: "100%" }
              : { width: width }),
            ...modalBackgroundColor(),
            ...(height && !isMobile && { height: height }),
            ...(!height && !isMobile && { maxHeight: "90vh" }),
            ...(noRoudnedCorners
              ? {}
              : isMobile
              ? {}
              : { borderRadius: "1rem" }),
          }}
          ref={myRef}
        >
          {children}
        </div>
      </div>
    ) : null,
    document.body
  );
};

export default Modal;

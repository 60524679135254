import React, { useState, useEffect } from "react";
import clsx from "clsx";
import "../tailwind.generated.css";

import { useEditor, EditorContent } from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";

const SmartInput = ({
  placeholder,
  initialValue,
  onBlur,
  onChange,
  focusOnMount,
  id,
  className,
  large,
  extralarge,
}) => {
  // focusOnMount doesn't work on iOS Safari because of the platform limitations

  const [value, setValue] = useState(initialValue ? initialValue : "");

  const editor = useEditor({
    extensions: [StarterKit, Placeholder.configure({ placeholder })],
    editorProps: {
      attributes: {
        id: id,
        class: clsx(
          className && className,
          large && "text-xl",
          extralarge && "text-2xl"
        ),
      },
    },
    content: value,
    onUpdate({ editor }) {
      const text = editor.getText();
      if (onChange) {
        onChange(text, id);
      }
      setValue(text);
    },
    onBlur({ editor }) {
      if (onBlur) {
        const text = editor.getText();
        onBlur(text, id);
      }
    },
  });

  useEffect(() => {
    if (focusOnMount) {
      editor.commands.focus();
    }
  }, []);

  return (
    <>
      <EditorContent editor={editor} />
    </>
  );
};

export default SmartInput;

export interface IBlockTestResponse {
  answerId: string;
  responseTime?: number;
  submittedAt: number;
}

export interface IOpenQuestionResponse extends IBlockTestResponse {
  textValue: string;
}

export interface IChoiceResponse extends IBlockTestResponse {
  selectedOptions: { id: string; value: string }[];
}

export interface IPreferenceResponse extends IBlockTestResponse {
  selectedOptions: { id: string; value: string }[];
}

export function isOpenQuestionsResponse(
  response: IBlockTestResponse
): response is IOpenQuestionResponse {
  return "textValue" in response;
}

export function isChoiceResponse(
  response: IBlockTestResponse
): response is IChoiceResponse {
  return "selectedOptions" in response;
}

export function isPreferenceResponse(
  response: IBlockTestResponse
): response is IPreferenceResponse {
  return "selectedOptions" in response;
}

import React, { Suspense, useEffect, useContext, useState } from "react";

import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Toast from "./components/Toast";
import Loader from "./components/Loader";
import UpgradeModal from "./components/Billing/UpgradeModal";
import Questionnaire from "./components/Questionnaire/Questionnaire";
import { getUserData } from "./actions";
import appContext from "./AppContext";
import { useAuth, useSendNotification } from "./hooks";
import { db } from "./firebase";

import { usePageViews } from "./utils/gtm";

import AuthByToken from "./components/AuthByToken";
import Admin from "./components/Admin/Admin";

const Home = React.lazy(() => import("./components/UserAccount/Pages/Home/Home"));
const Settings = React.lazy(() => import("./components/Settings/Settings"));
const Test = React.lazy(() => import("./components/Test"));
const SplitGroup = React.lazy(() => import("./components/UserAccount/Pages/SplitGroup/SplitGroup"));
const TestPage = React.lazy(() => import("./components/TestPage"));

const WithUserData = ({ children }) => {
  const { dispatch, state } = useContext(appContext);
  const [isLoading, setIsLoading] = useState(true);
  const userAuth = useAuth();
  const sendNotification = useSendNotification();

  useEffect(() => {
    if (!userAuth) return;

    const resetCallbacks = [];

    getUserData(userAuth).then((result) => {
      window.Intercom("boot", {
        app_id: "zbknt5pa",
        email: userAuth.email,
        user_id: userAuth.uid,
      });
      dispatch(result);
      const projectId = result.payload.credits.projectId;
      //set tests listener
      let isInitialDataLoaded = false;
      const unsubscribe = db
        .collection("tests")
        .where(
          projectId ? "projectId" : "userId",
          "==",
          projectId ? projectId : userAuth.uid
        )
        .onSnapshot((testsSnapshot) => {
          if (!isInitialDataLoaded) {
            isInitialDataLoaded = true;
          } else {
            testsSnapshot.docChanges().forEach((change) => {
              const {
                hiringCounter = 0,
                invalidAnswers = [],
                answersMetaData = {},
                answersCounter = 0,
              } = change.doc.data();
              dispatch({
                type: "UPDATE_TEST",
                payload: {
                  testId: change.doc.id,
                  data: {
                    hiringCounter,
                    invalidAnswers,
                    answersMetaData,
                    answersCounter,
                  },
                },
              });
            });
          }
        });

      resetCallbacks.push(unsubscribe);
      setIsLoading(false);
    })
      .catch(e => {
        if (e.cause?.status === 504) {
          sendNotification('error', 'Oops, something went wrong. Maybe you`re offline?');
        }
        else {
          sendNotification('error', 'Oops, something went wrong.');
        }
        console.error(e);
      });

    return () => resetCallbacks.forEach((fn) => fn());
  }, [userAuth]);

  if (isLoading) {
    return <Loader />;
  }

  if (!state.user.questionnaireCompleted) {
    return <Questionnaire />;
  }

  return children;
};

const Routes = () => {
  usePageViews();

  return (
    <Switch>
      <Route exact path="/authByToken">
        <AuthByToken />
      </Route>
      <Route exact path="/admin">
        <Admin />
      </Route>
      <Route exact path="/">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <Home />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/settings">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <Settings />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/f/:folderId">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <Home />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/sg/:splitGroupId">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <SplitGroup />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/tests/:testId">
        <Suspense fallback={<Loader />}>
          <WithUserData>
            <TestPage />
          </WithUserData>
        </Suspense>
      </Route>
      <Route exact path="/tests/:testId/preview">
        <Suspense fallback={<Loader />}>
          <Test isPreview={true} />
        </Suspense>
      </Route>
      <Route exact path="/:testId">
        <Suspense fallback={<Loader />}>
          <Test isPreview={false} />
        </Suspense>
      </Route>
    </Switch>
  );
};

const UserApp = () => {
  return (
    <>
      <UpgradeModal />
      <Toast />
      <Router>
        <Routes />
      </Router>
    </>
  );
};

export default UserApp;

import React from 'react';

import { FigmaClicks, FigmaHeatmap } from "../Clickmaps";
import { IClick, ScaleFunction } from '../Clickmaps/Models';
export const tabsLabels = {
	HEATMAP: "Heatmap",
	CLICKS: "Clicks",
	IMAGE: "Image",
};

export interface IFigmaClicksMapLayerProps {
	width: number;
	height: number;
	responses: any[];
	withScale: ScaleFunction;
	activeTab: string;
	clicks: IClick[];

	showClicksOrder: boolean;
}

export function FigmaClicksMapLayerTabs(props: IFigmaClicksMapLayerProps) {
	return (
		<>
			<div
				style={{ display: props.activeTab === tabsLabels.CLICKS ? "block" : "none" }}
			>
				<FigmaClicks width={props.width} height={props.height} withScale={props.withScale} showClicksOrder={props.showClicksOrder} clicks={props.clicks} />
			</div>
			<div
				style={{ display: props.activeTab === tabsLabels.HEATMAP ? "block" : "none" }}
			>
				<FigmaHeatmap width={props.width} height={props.height} clicks={props.clicks} />
			</div>
		</>
	);
}
import React from "react";
import "../../../tailwind.generated.css";
import _ from "lodash";

import Condition from "./ReportFilterCondition";
import Button from "../../Button";
import Dropdown, { DropdownOption } from "../../Dropdown";

import { getBlockTitle } from "../../../utils";
import { IconPlusWithoutPadding } from "../../../icons";

import { IconHire, IconTag  } from '../../../icons';

import { generateId } from "../../../utils";

import {
  IReportTestData,
  IReportFilter,
  FilterType,
  FilterConditionOperator,
  IReportFilterCondition,
} from "../../../models/Report";

function dropdownValueToFilterType(value: string) {
  switch (value) {
    case "source":
      return FilterType.source;
    case "urlParameter":
      return FilterType.urlParameter;
    default:
      return FilterType.block;
  }
};

export interface IFiltersProps {
  data: IReportTestData;
  filter: IReportFilter;
  handleUpdateFilter: (
    update: (current: {
      conditions: { [key: string]: IReportFilterCondition };
    }) => void
  ) => void;
}

const Filters = ({ data, filter, handleUpdateFilter }: IFiltersProps) => {
  console.log(data);
  console.log(filter);

  const handleAddCondition = (type: FilterType, blockId?: string) => {
    const conditionId = generateId() as string;

    if (!blockId && type === FilterType.block) {
      return;
    }

    const condition: IReportFilterCondition = {
      id: conditionId,
      type: type,
      operator: FilterConditionOperator.and,
      ...(type === FilterType.block && {
        blockId: blockId,
        condition: null,
      }),
      ...(type === FilterType.urlParameter && {
        tag: undefined,
        condition: null,
      }),
      value: undefined,
      createdAt: _.now(),
    };

    if (type) {
      handleUpdateFilter((current) => {
        return {
          ...current,
          conditions: {
            ...current.conditions,
            [conditionId]: condition,
          },
        };
      });
    }
  };

  const handleUpdateCondition = (
    id: string,
    update: { [key: string]: string }
  ) => {
    handleUpdateFilter((current) => {
      return {
        ...current,
        conditions: {
          ...current.conditions,
          [id]: {
            ...current.conditions[id],
            ...update,
          },
        },
      };
    });
  };

  const handleDeleteCondition = (id: string) => {
    handleUpdateFilter((current) => {
      const conditions = { ...current.conditions };
      delete conditions[id];
      return {
        ...current,
        conditions,
      };
    });
  };

  function isDefined<T>(argument: T | undefined): argument is T {
    return argument !== undefined;
  }

  const questionFilterItems: DropdownOption[] = data.publishedContent
    .filter(
      (block) =>
        block.type !== "context" &&
        block.type !== "fiveseconds" &&
        _.get(data, ["answers", block.blockId, "responses"], []).length > 0
    )
    .map((block, i) => {
      const blockTitle = getBlockTitle(block.type, 24);
      if (!blockTitle) return undefined;
      return {
        name: (
          <span className="flex items-center">
            <span className="mr-2">{i + 1}.</span>
            <div className="mr-2">{blockTitle.icon}</div>
            {blockTitle.name}
          </span>
        ),
        value: block.blockId,
      };
    })
    .filter(isDefined);

  const respondentsFilterItems: DropdownOption[] = [
    {
      name: (
        <span className="flex items-center justify-start">
          <div className="mr-2">
            <IconHire
              width={24}
              height={24}
              className="fill-current text-white"
            />
          </div>
          Source
        </span>
      ),
      value: "source",
    },
    {
      name: (
        <span className="flex items-center justify-start">
          <div className="mr-2">
            <IconTag
              width={24}
              height={24}
              className="fill-current text-white"
            />
          </div>
          URL Tag
        </span>
      ),
      value: "urlParameter",
    },
  ];

  return (
    <>
      <div className="my-4 inline-block">
        {Object.keys(filter.conditions)
          .sort(
            (keyA, keyB) =>
              filter.conditions[keyA].createdAt -
              filter.conditions[keyB].createdAt
          )
          .map((conditionId, i) => {
            return (
              <>
                <Condition
                  i={i}
                  filter={filter}
                  id={conditionId}
                  key={conditionId}
                  data={data}
                  handleUpdateCondition={(key: string, value: string) => {
                    handleUpdateCondition(conditionId, { [key]: value });
                  }}
                  handleDeleteCondition={() => {
                    handleDeleteCondition(conditionId);
                  }}
                />
              </>
            );
          })}
        <Dropdown
          type="single"
          items={[
            { sectionName: "Respondents" },
            ...respondentsFilterItems,
            { sectionName: "Blocks" },
            ...questionFilterItems,
          ]}
          handler={(value) => {
            const type = dropdownValueToFilterType(value);
            handleAddCondition(type, type === FilterType.block ? value : undefined);
          }}
          header={undefined}
          light={false}
        >
          <Button
            id="addFilter"
            type="primary"
            name="Add filter"
            className="my-2"
            icon={
              <IconPlusWithoutPadding
                width={16}
                height={16}
                className="fill-current text-white mr-2"
              />
            }
          />
        </Dropdown>
      </div>
    </>
  );
};

export default Filters;

import React from "react";
import "../../../tailwind.generated.css";

import TextDropdown from "../../TextDropdown";
import SmartInput from "../../SmartInput";

import { getBlockTitle } from "../../../utils";

import { ReactComponent as IconDelete } from "../../../icons/delete.svg";
import { ReactComponent as IconTag } from "../../../icons/tag.svg";

import {
  IReportTestData,
  IReportFilter,
  IBlockFilterCondition,
  ISourceFilterCondition,
  IUrlFilterCondition,
} from "../../../models/Report";

import {
  blockFilterConditions,
  sourceFilterValues,
  urlParameterConditions,
} from "./constants";
import { IconHire } from "../../../icons";

const SourceCondition = ({
  data,
  condition,
  handleUpdateCondition,
}: {
  data: IReportTestData;
  condition: ISourceFilterCondition;
  handleUpdateCondition: (key: string, value: string) => void;
}) => {
  const haveCustomPanelReplies = Object.keys(data.answersMetaData).some(
    (answerId) => {
      return data.answersMetaData[answerId].source === "customPanel";
    }
  );

  return (
    <div className="h-10 flex items-center">
      <IconHire
        width={24}
        height={24}
        className="fill-current text-black mr-2"
      />
      <div className="mr-1 font-medium">Show only respondents from</div>
      <div className="h-6 mr-2">
        <TextDropdown
          className="inline-block"
          small
          items={
            haveCustomPanelReplies
              ? sourceFilterValues
              : sourceFilterValues.filter(
                  (item) => item.value !== "customPanel"
                )
          }
          value={condition.value}
          placeholder="Pick an option"
          onChange={(value) => {
            handleUpdateCondition("value", value);
          }}
        />
      </div>
    </div>
  );
};

const UrlParameterCondition = ({
  data,
  condition,
  handleUpdateCondition,
}: {
  data: IReportTestData;
  condition: IUrlFilterCondition;
  handleUpdateCondition: (key: string, value: string) => void;
}) => {
  console.log("component rerendered, new updater received");
  const urlTagsList: Array<string> = [];
  Object.keys(data.answersMetaData).forEach((answerId) => {
    const urlTags = data.answersMetaData[answerId].urlTags;
    if (urlTags !== undefined) {
      Object.keys(urlTags).forEach((tag) => {
        if (!urlTagsList.includes(tag)) {
          urlTagsList.push(tag);
        }
      });
    }
  });

  const getValueField = () => {
    if (condition.condition === "inAnswer") {
      return null;
    } else if (
      condition.condition === "contains" ||
      condition.condition === "is"
    ) {
      return (
        <SmartInput
          className="text-base"
          initialValue={""}
          placeholder="Enter value"
          id={condition.id}
          onChange={(value: string) => {
            handleUpdateCondition("value", value);
          }}
          onBlur={undefined}
          focusOnMount={undefined}
          large={undefined}
          extralarge={undefined}
        />
      );
    } else {
      return null;
    }
  };

  const valueField = getValueField();

  return (
    <div className="h-10 flex items-center">
      <IconTag
        width={24}
        height={24}
        className="fill-current text-black mr-2"
      />
      <TextDropdown
        className="inline-block mr-2"
        small
        items={urlTagsList.map((tag) => {
          return {
            name: tag,
            value: tag,
          };
        })}
        value={condition.tag}
        placeholder="Pick a tag"
        onChange={(value) => {
          handleUpdateCondition("tag", value);
        }}
      />
      {urlTagsList.length > 0 && (
        <TextDropdown
          className="inline-block mr-2"
          small
          items={urlParameterConditions}
          value={condition.condition as string}
          placeholder="Pick a condition"
          onChange={(value) => {
            handleUpdateCondition("condition", value);
          }}
        />
      )}
      {valueField && <div className="mr-2">{valueField}</div>}
    </div>
  );
};

const BlockCondition = ({
  data,
  condition,
  handleUpdateCondition,
}: {
  data: IReportTestData;
  condition: IBlockFilterCondition;
  handleUpdateCondition: (key: string, value: string) => void;
}) => {
  const block = data.publishedContent.find(
    (block) => block.blockId === condition.blockId
  );
  const blockIndex = data.publishedContent.findIndex(
    (block) => block.blockId === condition.blockId
  );

  const blockType = block?.type;

  if (!block || !blockType) {
    return null;
  }

  const blockTitle = getBlockTitle(blockType, 20);
  const question = {
    name: blockTitle?.name,
    icon: blockTitle?.icon,
    i: blockIndex,
  };
  const blockConditions = blockFilterConditions[blockType];

  const getValueField = () => {
    if (blockType === "openquestion") {
      if (
        condition.condition === "contains" ||
        condition.condition === "doesNotContain"
      ) {
        return (
          <SmartInput
            className="text-base default-input w-48"
            initialValue={""}
            placeholder="Enter value"
            id={condition.id}
            onChange={(value: any) => {
              handleUpdateCondition("value", value);
            }}
            onBlur={undefined}
            focusOnMount={undefined}
            large={undefined}
            extralarge={undefined}
          />
        );
      } else {
        return null;
      }
    }
    if (blockType === "choice" || blockType === "preference") {
      if (
        condition.condition === "contains" ||
        condition.condition === "doesNotContain"
      ) {
        //@ts-ignore
        const dropdownOptions = block.replies.map((reply) => {
          return { name: reply.replyValue, value: reply.id };
        });
        //@ts-ignore
        if (block.other) {
          dropdownOptions.push({ name: "Other", value: "other" });
        }
        return (
          <TextDropdown
            className="inline-block"
            small
            items={dropdownOptions}
            value={condition.value}
            placeholder="Choose a reply value"
            onChange={(value) => {
              handleUpdateCondition("value", value);
            }}
          />
        );
      } else {
        return null;
      }
    }
  };

  return (
    <div className="h-10 flex items-center">
      <div className="h-6 mr-2">
        <div className="flex items-center justify-start font-medium">
          <span>{question.i + 1}.</span>
          <span className="mx-1">{question.icon}</span>
          <span>{question.name}</span>
        </div>
      </div>
      {condition.blockId && (
        <div className="h-6 mr-2">
          <TextDropdown
            className="inline-block"
            small
            items={blockConditions}
            value={condition.condition as string}
            placeholder="Pick a condition"
            onChange={(value) => {
              handleUpdateCondition("condition", value);
            }}
          />
        </div>
      )}
      {condition.condition && condition.condition !== "hasAnswer" && (
        <div className="flex justify-center mr-2">{getValueField()}</div>
      )}
    </div>
  );
};

const ReportFilterCondition = ({
  i,
  filter,
  id,
  data,
  handleUpdateCondition,
  handleDeleteCondition,
}: {
  i: number;
  filter: IReportFilter;
  id: string;
  data: IReportTestData;
  handleUpdateCondition: (key: string, value: string) => void;
  handleDeleteCondition: () => void;
}) => {
  const condition = filter.conditions[id];
  const type = condition.type;

  console.log(filter.conditions[id]);

  return (
    <div className="filterCondition">
      {i !== 0 && (
        <div className="h-10 flex items-center">
          <TextDropdown
            small
            items={[
              { name: "and", value: "and" },
              { name: "or", value: "or" },
            ]}
            value={condition.operator as string}
            onChange={(value) => {
              handleUpdateCondition("operator", value);
            }}
            position={undefined}
            className={undefined}
            placeholder={undefined}
          />
        </div>
      )}
      <div className="flex items-center">
        {type === "block" && (
          <BlockCondition
            condition={condition as IBlockFilterCondition}
            data={data}
            handleUpdateCondition={handleUpdateCondition}
          />
        )}
        {type === "source" && (
          <SourceCondition
            condition={condition as ISourceFilterCondition}
            data={data}
            handleUpdateCondition={handleUpdateCondition}
          />
        )}
        {type === "urlParameter" && (
          <UrlParameterCondition
            condition={condition as IUrlFilterCondition}
            data={data}
            handleUpdateCondition={handleUpdateCondition}
          />
        )}
        <div className="">
          <IconDelete
            className="fill-current text-gray-700 hover:text-red-600 cursor-pointer filterConditionDelete"
            width={24}
            height={24}
            onClick={() => {
              handleDeleteCondition();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportFilterCondition;

import React, { useEffect } from "react";
import "../../../tailwind.generated.css";
import clsx from "clsx";

import Tabs from "../../Tabs";

import MatrixTable from "./MatrixTable";

import _ from "lodash";

const CardSortReport = ({ block, responses }) => {
  const columns = [...block.categories];
  if (block.doNotRequireToSortAll === true) {
    columns.push({ id: "unsorted", value: "Unsorted" });
  }

  const rows = [...block.cards];

  const cardsData = responses.reduce((acc, response) => {
    block.cards.forEach((card) => {
      if (!acc[card.id]) {
        acc[card.id] = {};
      }
      columns.forEach((category) => {
        if (!acc[card.id][category.id]) {
          acc[card.id][category.id] = 0;
        }
        if (
          response.sorting[category.id] &&
          response.sorting[category.id].find(
            (cardInCategory) => card.id === cardInCategory.id
          )
        ) {
          acc[card.id][category.id] += 1;
        }
      });
    });
    return acc;
  }, {});

  const categoriesData = responses.reduce((acc, response) => {
    columns.forEach((category) => {
      if (!acc[category.id]) {
        acc[category.id] = {};
      }
      block.cards.forEach((card) => {
        if (!acc[category.id][card.id]) {
          acc[category.id][card.id] = 0;
        }
        if (
          response.sorting[category.id] &&
          response.sorting[category.id].find(
            (cardInCategory) => card.id === cardInCategory.id
          )
        ) {
          acc[category.id][card.id] += 1;
        }
      });
    });
    return acc;
  }, {});

  return (
    <>
      <Tabs>
        <div label="Matrix">
          <MatrixTable
            blockId={block.blockId}
            rows={rows}
            columns={columns}
            cardsData={cardsData}
          />
        </div>
        {/* Categories */}
        <div label="Categories">
          <div className="grid grid-cols-4 col-auto text-sm text-gray-700">
            <div className="col-span-1">Categories</div>
            <div className="col-span-2">Cards</div>
            <div className="col-span-1">Frequency</div>
          </div>
          {columns.map((category, i, array) => {
            const categoryCardIds = Object.keys(
              categoriesData[category.id]
            ).filter((cardId) => categoriesData[category.id][cardId] > 0);
            return (
              <div key={category.id}>
                <div className="grid grid-cols-4 col-auto text-lg my-4">
                  <div className="my-2 pr-4 col-span-1">{category.value}</div>
                  <div className="pr-4 col-span-2">
                    {categoryCardIds.map((cardId) => {
                      return (
                        <div className="my-2" key={cardId}>
                          {block.cards.find((card) => card.id === cardId).value}
                        </div>
                      );
                    })}
                  </div>
                  <div className="pr-4 col-span-1">
                    {" "}
                    {categoryCardIds.map((cardId) => {
                      return (
                        <div className="my-2" key={cardId}>
                          {
                            responses.filter(
                              (response) =>
                                response.sorting[category.id] &&
                                response.sorting[category.id].find(
                                  (cardInCategory) =>
                                    cardInCategory.id === cardId
                                )
                            ).length
                          }
                        </div>
                      );
                    })}
                  </div>
                </div>
                {i + 1 !== array.length && (
                  <div className="h-px bg-gray-300"></div>
                )}
              </div>
            );
          })}
        </div>
        {/* Cards */}
        <div label="Cards">
          <div className="grid grid-cols-4 col-auto text-sm text-gray-700">
            <div className="col-span-1">Cards</div>
            <div className="col-span-2">Categories</div>
            <div className="col-span-1">Frequency</div>
          </div>
          {block.cards.map((card, i, array) => {
            const cardCategoryIds = Object.keys(cardsData[card.id]).filter(
              (categoryId) => cardsData[card.id][categoryId] > 0
            );
            return (
              <div key={card.id}>
                <div className="grid grid-cols-4 col-auto text-lg my-4">
                  <div className="my-2 pr-4 col-span-1">{card.value}</div>
                  <div className="pr-4 col-span-2">
                    {cardCategoryIds.map((categoryId) => {
                      return (
                        <div className="my-2" key={categoryId}>
                          {
                            columns.find(
                              (category) => category.id === categoryId
                            ).value
                          }
                        </div>
                      );
                    })}
                  </div>
                  <div className="pr-4 col-span-1">
                    {" "}
                    {cardCategoryIds.map((categoryId) => {
                      return (
                        <div className="my-2" key={categoryId}>
                          {
                            responses.filter(
                              (response) =>
                                response.sorting[categoryId] &&
                                response.sorting[categoryId].find(
                                  (cardInCategory) =>
                                    cardInCategory.id === card.id
                                )
                            ).length
                          }
                        </div>
                      );
                    })}
                  </div>
                </div>
                {i + 1 !== array.length && (
                  <div className="h-px bg-gray-300"></div>
                )}
              </div>
            );
          })}
        </div>
      </Tabs>
    </>
  );
};

export default CardSortReport;

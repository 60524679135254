import React, { useState, useRef, useEffect } from "react";
import lodash from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import { getNodeParams, getOverflowParams } from "../../utils/figma";

const WithScroll = ({ children, initialParams, viewStyle = {}, ...params }) => {
  const scrollRef = useRef();

  useEffect(() => {
    const { current: scrollBlock } = scrollRef;
    const viewBlock = scrollBlock.view;

    if (initialParams) {
      const { scrollLeft, scrollTop } = initialParams;
      if (scrollLeft) scrollBlock.scrollLeft(scrollLeft);
      if (scrollTop) scrollBlock.scrollTop(scrollTop);
    }

    let position = {
      top: 0,
      left: 0,
      x: 0,
      y: 0,
    };

    viewBlock.style.cursor = "default";

    const mouseMoveHandler = (e) => {
      // How far the mouse has been moved
      const dx = e.clientX - position.x;
      const dy = e.clientY - position.y;

      // Scroll the element
      scrollBlock.scrollTop(position.top - dy);
      scrollBlock.scrollLeft(position.left - dx);
    };

    const mouseUpHandler = () => {
      viewBlock.style.cursor = "default";
      viewBlock.style.removeProperty("user-select");

      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

    const mouseDownHandler = (e) => {
      viewBlock.style.cursor = "default";
      viewBlock.style.userSelect = "none";

      position.left = scrollBlock.getScrollLeft();
      position.top = scrollBlock.getScrollTop();
      position.x = e.clientX;
      position.y = e.clientY;

      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    viewBlock.addEventListener("mousedown", mouseDownHandler);

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };
  }, []);

  return (
    <Scrollbars
      {...params}
      renderView={(props) => <div {...props} />}
      ref={scrollRef}
    >
      {children}
    </Scrollbars>
  );
};

const getClickData = ({ clientX, clientY, target }, thisNode, scaleRate) => {
  const { left, top } = target.getBoundingClientRect();
  return {
    transitionNodeID: thisNode.transitionNodeID,
    clickData: {
      nodeId: thisNode.id,
      x: (clientX - left) * scaleRate,
      y: (clientY - top) * scaleRate,
    },
  };
};

const ChildrenNodes = ({
  goToNode,
  parentNode: parent,
  rootNode: root,
  scaleRate,
  figmaParams,
  node,
  onClick,
  isFixed,
}) => {
  const rootNode = getNodeParams(root);
  const parentNode = getNodeParams(parent);
  const thisNode = getNodeParams(node);

  const { imageScale } = figmaParams;

  const wrapperParams = {
    style: {
      position: "absolute",
      width: thisNode.width,
      height: thisNode.height,
    },
  };

  const blockParams = {
    style: {
      position: "absolute",
      width: thisNode.width * imageScale,
      height: thisNode.height * imageScale,
      overflow: "hidden",
    },
    onClick: (e) => {
      e.stopPropagation();
      const { transitionNodeID } = thisNode;
      if (transitionNodeID) goToNode(transitionNodeID);
      if (onClick) onClick(getClickData(e, thisNode, scaleRate));
    },
  };

  if (!isFixed) {
    lodash.set(
      blockParams,
      "style.left",
      `${(thisNode.x - parentNode.x) * imageScale}px`
    );
    lodash.set(
      blockParams,
      "style.top",
      `${(thisNode.y - parentNode.y) * imageScale}px`
    );
  }

  if (isFixed) {
    lodash.set(wrapperParams, "style.zIndex", "20");
    lodash.set(blockParams, "style.transform", `scale(${1 / imageScale}`);
    lodash.set(blockParams, "style.transformOrigin", "0 0");

    if (thisNode.horizontal === "LEFT")
      lodash.set(wrapperParams, "style.left", `${thisNode.x - parentNode.x}px`);
    else
      lodash.set(
        wrapperParams,
        "style.right",
        `${parentNode.width - (thisNode.x - parentNode.x) - thisNode.width}px`
      );

    if (thisNode.vertical === "TOP")
      lodash.set(wrapperParams, "style.top", `${thisNode.y - parentNode.y}px`);
    else
      lodash.set(
        wrapperParams,
        "style.bottom",
        `${parentNode.height - (thisNode.y - parentNode.y) - thisNode.height}px`
      );
  }

  if (isFixed || thisNode.overflowDirection) {
    lodash.set(
      blockParams,
      "style.background",
      `url(${rootNode.image}) -${(thisNode.x - rootNode.x) * imageScale}px -${
        (thisNode.y - rootNode.y) * imageScale
      }px no-repeat`
    );
  }

  if (thisNode.image) {
    lodash.set(
      blockParams,
      "style.background",
      `url(${thisNode.image}) 0 0 no-repeat`
    );
  }

  if (thisNode.transitionNodeID) {
    lodash.set(blockParams, "style.cursor", "pointer");
  }

  let output =
    thisNode.children &&
    thisNode.children.map((childNode) => (
      <ChildrenNodes
        goToNode={goToNode}
        rootNode={root}
        parentNode={node}
        node={childNode}
        scaleRate={scaleRate}
        figmaParams={figmaParams}
        key={childNode.id}
        onClick={onClick}
      />
    ));

  if (
    ["HORIZONTAL_SCROLLING", "VERTICAL_SCROLLING", "BOTH_SCROLLING"].includes(
      thisNode.overflowDirection
    )
  ) {
    const scrollStyle = {
      position: "relative",
      width: thisNode.width * imageScale,
      height: thisNode.height * imageScale,
      backgroundColor: "#fff",
    };

    const innerStyle = {
      position: "relative",
      width: thisNode.width * imageScale,
      height: thisNode.height * imageScale,
    };

    const { width, height, scrollTop, scrollLeft } = getOverflowParams(node);

    innerStyle.width = width * imageScale;
    innerStyle.height = height * imageScale;

    const initialParams = {
      scrollTop: scrollTop * imageScale,
      scrollLeft: scrollLeft * imageScale,
    };

    output = (
      <WithScroll initialParams={initialParams} style={scrollStyle}>
        <div onClick={blockParams.onClick} style={innerStyle}>
          <div
            style={{
              left: scrollLeft * imageScale,
              top: scrollTop * imageScale,
              position: "absolute",
              width: thisNode.width * imageScale,
              height: thisNode.height * imageScale,
            }}
          >
            {output}
          </div>
        </div>
      </WithScroll>
    );

    blockParams.onClick = undefined;
  }

  output = <div {...blockParams}>{output}</div>;

  if (thisNode.isFixed) {
    output = <div {...wrapperParams}>{output}</div>;
  }

  return output;
};

const Screen = ({
  goToNode,
  onClick,
  active,
  node,
  node: {
    image,
    children,
    absoluteBoundingBox: { width, height },
  },
}) => {
  const [scaleRate, setScaleRate] = useState(1);
  const figmaParams = { imageScale: 2 };
  const refs = useRef({ blocks: {} });

  const staticNodes = [];
  const fixedNodes = [];

  children.forEach((childNode) => {
    if (childNode.isFixed) fixedNodes.push(childNode);
    else staticNodes.push(childNode);
  });

  useEffect(() => {
    const { blocks } = refs.current;
    const { imageScale } = figmaParams;

    // Styles
    const handleResize = () => {
      const { wrapper, staticNodesWrapper, staticNodes, secondWrapper } =
        blocks;

      const windowHeight = wrapper.parentNode.clientHeight;
      const windowWidth = wrapper.parentNode.clientWidth;

      secondWrapper.style.transformOrigin = "0 0";
      secondWrapper.style.position = "absolute";
      secondWrapper.style.overflow = "hidden";

      if (windowWidth < width) {
        secondWrapper.style.transform = `scale(${windowWidth / width})`;
        secondWrapper.style.width = `${width}px`;
        secondWrapper.style.height = `${
          windowHeight / (windowWidth / width)
        }px`;
        setScaleRate(windowWidth / width);
      } else {
        secondWrapper.style.transform = `scale(1)`;
        secondWrapper.style.width = `${width}px`;
        secondWrapper.style.height = `${windowHeight}px`;
        setScaleRate(1);
      }

      wrapper.style.position = "absolute";
      wrapper.style.width = `${Math.min(windowWidth, width)}px`;
      wrapper.style.height = `${windowHeight}px`;
      wrapper.style.left = "50%";
      wrapper.style.marginLeft = `-${Math.min(windowWidth, width) / 2}px`;
      wrapper.style.top = "0 px";
      wrapper.style.overflow = "hidden";
      wrapper.style.transition = "opacity 300ms";

      if (active) {
        wrapper.style.opacity = "1";
        wrapper.style.zIndex = "20";
      } else {
        wrapper.style.opacity = "0";
        wrapper.style.zIndex = "0";
      }

      staticNodesWrapper.style.width = `${width}px`;
      staticNodesWrapper.style.height = `${height}px`;
      staticNodesWrapper.style.overflow = "hidden";

      staticNodes.style.width = `${width * imageScale}px`;
      staticNodes.style.height = `${height * imageScale}px`;
      staticNodes.style.background = `url(${image}) 0 0 no-repeat`;
      staticNodes.style.transform = `scale(${1 / imageScale})`;
      staticNodes.style.transformOrigin = "0 0";
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [active]);

  return (
    <div ref={(element) => (refs.current.blocks.wrapper = element)}>
      <div ref={(element) => (refs.current.blocks.secondWrapper = element)}>
        {fixedNodes.map((childNode) => (
          <ChildrenNodes
            goToNode={goToNode}
            rootNode={node}
            parentNode={node}
            node={childNode}
            figmaParams={figmaParams}
            scaleRate={scaleRate}
            key={childNode.id}
            onClick={onClick}
            isFixed
          />
        ))}
        <WithScroll className="relative w-full h-full">
          <div
            ref={(element) =>
              (refs.current.blocks.staticNodesWrapper = element)
            }
          >
            <div
              ref={(element) => (refs.current.blocks.staticNodes = element)}
              onClick={(e) => {
                e.stopPropagation();
                const { transitionNodeID } = node;
                if (transitionNodeID) goToNode(transitionNodeID);
                if (onClick) onClick(getClickData(e, node, scaleRate));
              }}
            >
              {staticNodes.map((childNode) => (
                <ChildrenNodes
                  goToNode={goToNode}
                  rootNode={node}
                  parentNode={node}
                  node={childNode}
                  figmaParams={figmaParams}
                  scaleRate={scaleRate}
                  key={childNode.id}
                  onClick={onClick}
                />
              ))}
            </div>
          </div>
        </WithScroll>
      </div>
    </div>
  );
};

export default ({
  nodesForHtml,
  nodeImages,
  className = "",
  style,
  startNodeId,
  onClick,
  onLoad,
}) => {
  const [state, setState] = useState({
    activeNodeId: startNodeId,
    loadedImagesNum: 0,
  });

  useEffect(() => {
    if (!onLoad) return;
    const { loadedImagesNum } = state;
    if (loadedImagesNum === Object.keys(nodeImages).length) onLoad();
  }, [state.loadedImagesNum]);

  const setActiveNode = (activeNodeId) => {
    if (!nodesForHtml[activeNodeId]) return;
    setState((state) =>
      lodash.merge(lodash.cloneDeep(state), { activeNodeId })
    );
  };

  return (
    <div className={[className, "relative bg-black"].join(" ")} style={style}>
      <div className="invisible inset-0 w-px h-px overflow-hidden absolute">
        {lodash.values(nodeImages).map((imageSrc, index) => (
          <img
            className="invisible"
            key={`figma-htmlview-image-${index}`}
            src={imageSrc}
            onLoad={() => {
              setState((state) => {
                const newNumber = state.loadedImagesNum + 1;
                return { ...state, loadedImagesNum: newNumber };
              });
            }}
          />
        ))}
      </div>

      {Object.keys(nodesForHtml).map((nodeId) => (
        <Screen
          node={nodesForHtml[nodeId]}
          active={state.activeNodeId === nodeId}
          goToNode={setActiveNode}
          onClick={(data) => {
            const { transitionNodeID, ...rest } = data;
            if (!nodesForHtml[transitionNodeID]) onClick(rest);
            else onClick(data);
          }}
          key={nodeId}
        />
      ))}
    </div>
  );
};

import React from 'react';
import { IconOpenQuestion, IconChoice, IconFirstClick, IconContext, IconFigma, IconPreference, IconFiveSeconds, IconCardSort } from '../../icons';
import { BlockType } from "../../models/Test";

export const blockTypeMap: Record<BlockType, ITestBlockThumb> = {
	cardsort: {
		name: "Card sort",
		icon: <IconCardSort />,
		description: "See how users group ideas",
		getIcon
	},
	choice: {
		name: "Choice",
		icon: <IconChoice />,
		description: "Ask a question with multiple answer options",
		getIcon
	},
	context: {
		name: "Context",
		icon: <IconContext />,
		description: "Provide some context during the test",
		getIcon
	},
	figma: {
		name: "Figma", 
		icon: <IconFigma />, 
		description: "Test your Figma prototype",
		getIcon
	},
	firstclick: {
		name: "First click",
		icon: <IconFirstClick />,
		description: "Ask to click on the interface in order to complete a task",
		getIcon
	},
	fiveseconds: {
		name: "Five seconds",
		icon: <IconFiveSeconds />, 
		description: "Show an image for a limited amount of time",
		getIcon
	},
	openquestion: {
		name: "Open question",
		icon: <IconOpenQuestion />,
		description: "Collect text answers",
		getIcon
	},
	preference: {
		name: "Preference",
		icon: <IconPreference />, 
		description: "Compare 2 or more images",
		getIcon
	},
};

function getIcon(this: any, props: any) {
	return React.cloneElement(this.icon, props)
}

export interface ITestBlockThumb {
	name: string;
	icon: React.ReactElement;
	description: string;
	getIcon(props: any): React.ReactElement;
}
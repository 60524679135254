import {
  IReportTestData,
  IReportFilter,
  IBlockTestResponse,
  IBlockFilterCondition,
  isChoiceResponse,
  isOpenQuestionsResponse,
  isPreferenceResponse,
  IUrlFilterCondition,
  ISourceFilterCondition,
  isBlockFilterCondition,
  isUrlFilterCondition,
  isSourceFilterCondition,
  FilterConditionOperator,
  IReportFilterCondition,
} from "./../../models/Report/index";
import _ from "lodash";

const isInvalidAnswer = (invalidAnswers: string[], answerId: string) => {
  return invalidAnswers.some((id) => id === answerId);
};

const isDeletedAnswer = (deletedAnswers: string[], answerId: string) => {
  return deletedAnswers.some((id) => id === answerId);
};

export const removeInvalidOrDeletedAnswers = (testData: IReportTestData) => {
  const invalidAnswers = testData.invalidAnswers || [];
  const deletedAnswers = testData.deletedAnswers || [];

  const answers = { ...testData.answers };

  Object.keys(answers).forEach((blockId) => {
    if (!testData.publishedContent.find((block) => block.blockId === blockId)) {
      delete answers[blockId];
      return;
    }
    // todo array.filter
    answers[blockId].responses.forEach((answer, i) => {
      if (
        isDeletedAnswer(deletedAnswers, answer.answerId) ||
        isInvalidAnswer(invalidAnswers, answer.answerId)
      ) {
        answers[blockId].responses.splice(i, 1);
        return;
      }
    });
  });

  return answers;
};

export const getTotalUniqueResponses = (data: IReportTestData) => {
  const publishedContent = data.publishedContent || [];
  const answers = data.answers;
  const counter: { answerIdData: string[]; total: number } = {
    answerIdData: [],
    total: 0,
  };

  if (!Object.keys(answers).length) return counter;

  publishedContent.forEach((block) => {
    if (answers[block.blockId]) {
      answers[block.blockId].responses.forEach((response) => {
        const isInvalidAnswer = data.invalidAnswers?.some(
          (id) => id === response.answerId
        );
        const isDeletedAnswer = data.deletedAnswers?.some(
          (id) => id === response.answerId
        );
        if (
          !counter.answerIdData.some(
            (answerId) => answerId === response.answerId
          ) &&
          !isInvalidAnswer &&
          !isDeletedAnswer
        ) {
          counter.answerIdData.push(response.answerId);
          counter.total++;
        }
      });
    }
  });
  return counter;
};

export const getConditionFilteredResponses = (
  data: IReportTestData,
  filter: IReportFilter,
  answers: {
    [x: string]: {
      responses: Required<IBlockTestResponse>[];
    };
  }
) => {
  const answerIdData = getTotalUniqueResponses(data).answerIdData;
  if (_.isEmpty(filter.conditions)) {
    return answerIdData;
  }
  const answersMetaData = data.answersMetaData;

  const resolveSourceCondition = (condition: ISourceFilterCondition) => {
    // check if condition is valid, if not return all answers
    if (!condition.value) {
      return answerIdData;
    }
    return answerIdData.filter((answerId) => {
      const answerMetaData = answersMetaData[answerId];
      return _.get(answerMetaData, "source") === condition.value;
    });
  };

  const resolveUrlCondition = (condition: IUrlFilterCondition) => {
    // check if condition is valid, if not return all answers
    if (
      (!condition.value && condition.condition !== "inAnswer") ||
      !condition.condition ||
      !condition.tag
    )
      return answerIdData;

    switch (condition.condition) {
      case "inAnswer":
        return answerIdData.filter((answerId) => {
          const answerMetaData = answersMetaData[answerId];
          const urlTags = answerMetaData.urlTags;
          if (!urlTags || Object.keys(urlTags).length === 0) return false;
          console.log(urlTags);
          return Object.keys(urlTags).some((tag) => tag === condition.tag);
        });
      case "is":
        return answerIdData.filter((answerId) => {
          const answerMetaData = answersMetaData[answerId];
          const urlTags = answerMetaData.urlTags;
          if (!urlTags) return false;
          return Object.keys(urlTags).some(
            (tag) => tag === condition.tag && urlTags[tag] === condition.value
          );
        });
      case "contains":
        return answerIdData.filter((answerId) => {
          const answerMetaData = answersMetaData[answerId];
          const urlTags = answerMetaData.urlTags;
          if (!urlTags) return false;
          return Object.keys(urlTags).some((tag) => {
            return (
              _.includes(_.toLower(urlTags[tag]), _.toLower(condition.value)) &&
              tag === condition.tag
            );
          });
        });
    }
  };

  const resolveBlockCondition = (condition: IBlockFilterCondition) => {
    // check if condition is valid
    if (
      !condition.blockId ||
      !condition.condition ||
      (condition.condition !== "hasAnswer" && !condition.value)
    ) {
      // returning all data, condition is not valid
      return answerIdData;
    }

    const result: string[] = [];
    const block = data.publishedContent.find(
      (block) => block.blockId === condition.blockId
    );
    if (!block) {
      return answerIdData;
    }
    const blockAnswers = answers[condition.blockId];
    const blockType = block.type;

    switch (condition.condition) {
      case "hasAnswer":
        blockAnswers.responses.forEach((response) => {
          result.push(response.answerId);
        });
        return result;
      case "contains":
        switch (blockType) {
          case "openquestion":
            blockAnswers.responses.forEach((response) => {
              if (isOpenQuestionsResponse(response)) {
                if (
                  _.includes(
                    _.toLower(response.textValue),
                    _.toLower(condition.value)
                  )
                ) {
                  result.push(response.answerId);
                }
              }
            });
            return result;
          case "choice":
            blockAnswers.responses.forEach((response) => {
              if (isChoiceResponse(response)) {
                if (
                  response.selectedOptions.find(
                    (option) => option.id === condition.value
                  )
                ) {
                  result.push(response.answerId);
                }
              }
            });
            return result;
          case "preference":
            blockAnswers.responses.forEach((response) => {
              if (isPreferenceResponse(response)) {
                if (
                  response.selectedOptions.find(
                    (option) => option.id === condition.value
                  )
                ) {
                  result.push(response.answerId);
                }
              }
            });
            return result;
        }
        return result;
      case "doesNotContain":
        switch (blockType) {
          case "openquestion":
            result.push(...answerIdData);
            blockAnswers.responses.forEach((response) => {
              if (isOpenQuestionsResponse(response) && condition.value) {
                if (response.textValue.includes(condition.value)) {
                  const answerIdIndex = result.findIndex(
                    (el) => el === response.answerId
                  );
                  result.splice(answerIdIndex, 1);
                }
              }
            });
            return result;
          case "choice":
            result.push(...answerIdData);
            blockAnswers.responses.forEach((response) => {
              if (isChoiceResponse(response)) {
                if (
                  response.selectedOptions.find(
                    (option) => option.id === condition.value
                  )
                ) {
                  const answerIdIndex = result.findIndex(
                    (el) => el === response.answerId
                  );
                  result.splice(answerIdIndex, 1);
                }
              }
            });
            return result;
          case "preference":
            result.push(...answerIdData);
            blockAnswers.responses.forEach((response) => {
              if (isPreferenceResponse(response)) {
                if (
                  response.selectedOptions.find(
                    (option) => option.id === condition.value
                  )
                ) {
                  const answerIdIndex = result.findIndex(
                    (el) => el === response.answerId
                  );
                  result.splice(answerIdIndex, 1);
                }
              }
            });
            return result;
        }
    }
  };

  const resolveCondition = (
    condition: IReportFilterCondition
  ) => {
    if (isBlockFilterCondition(condition)) {
      return resolveBlockCondition(condition);
    }
    if (isSourceFilterCondition(condition)) {
      return resolveSourceCondition(condition);
    }
    if (isUrlFilterCondition(condition)) {
      return resolveUrlCondition(condition);
    }
  };

  return Object.keys(filter.conditions).reduce((acc, conditionId) => {
    const conditionResult = resolveCondition(filter.conditions[conditionId]);
    if (!conditionResult) {
      return acc;
    }
    return filter.conditions[conditionId].operator === FilterConditionOperator.and
      ? (acc = _.intersection(conditionResult, acc))
      : (acc = _.uniq([...conditionResult, ...acc]));
  }, answerIdData);
};

export const calcMedian = (numbers: number[]) => {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
};

import React from "react";

function Clicks({ width, height, responses, withScale }: { width: number, height: number, responses: any[], withScale: any }) {

  const getClickStyle = (position: { top: number, left: number }) => ({
    left: `calc(${position.left * 100}% - 8px)`,
    top: `calc(${position.top * 100}% - 8px)`,
    width: withScale(16),
    height: withScale(16),
    position: 'absolute'
  });

  return (
    <div className="clickmap relative" style={{ width, height }}>
      {responses.map((response) => (
        <div
          key={response.answerId}
          style={getClickStyle(response.clickData) as any}
          className="absolute bg-red-500 rounded-full opacity-95"
        />
      ))}
    </div>
  );
}

export default Clicks;

import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "../tailwind.generated.css";
import clsx from "clsx";

const styleSettings = {
  default: {
    background: "bg-gray-900",
    color: "text-white",
    padding: "p-2",
    fontsize: "text-sm",
  },
  light: {
    background: "bg-white",
    color: "text-gray-900",
    border: "border-2 border-gray-300",
    shadow: "drop-shadow-sm",
  },
  dark: {
    background: "bg-gray-900",
    color: "text-white",
  },
};

const Popover = ({
  getContent,
  anchorClassName,
  children,
  position,
  onOpen = null,
  onClose = null,
  styleMode = "dark",
  padding = undefined,
  color = undefined,
  className = "p-2 text-white text-sm",
}) => {
  const popoverAnchorRef = useRef();
  const popoverRef = useRef();
  const [coordinates, setCoordinates] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const visibility = useRef(isVisible);

  const style = {
    ...styleSettings.default,
    ...styleSettings[styleMode],
  };
  if (typeof padding == "string") {
    style.padding = padding;
  }
  if (typeof color == "string") {
    style.color = color;
  }
  const styleClasses = Object.values(style).join(" ");

  const openPopover = () => {
    setIsVisible(true);
  };

  const closePopover = () => {
    setIsVisible(false);
    onClose && onClose();
  };

  const handleClickOutside = (e) => {
    if (
      !popoverRef.current.contains(e.target) &&
      !popoverAnchorRef.current.contains(e.target)
    ) {
      closePopover();
    }
  };

  useEffect(() => {
    const calcCoordinates = () => {
      if (!popoverAnchorRef.current || !popoverRef.current) {
        return null;
      }
      const anchor = popoverAnchorRef.current.getBoundingClientRect();
      const popover = popoverRef.current.getBoundingClientRect();

      const getMargin = (position) => {
        switch (position) {
          case "top":
            return {
              top: -popover.height - 8,
              left: anchor.width / 2 - popover.width / 2,
            };
          case "top-left":
            return {
              top: -popover.height - 8,
              left: 0,
            };
          case "right":
            return {
              top: anchor.height / 2 - popover.height / 2,
              left: popover.width + 8,
            };
          case "bottom":
            return {
              top: anchor.height + 8,
              left: anchor.width / 2 - popover.width / 2,
            };
          case "bottom-left":
            return {
              top: anchor.height + 8,
              left: 0,
            };
          case "bottom-right":
            return {
              top: anchor.height + 8,
              left: -popover.width + anchor.width,
            };
          case "left":
            return {
              top: anchor.height / 2 - popover.height / 2,
              left: -popover.width - 8,
            };
          default:
            return {
              top: -popover.height - 8,
              left: anchor.width / 2 - popover.width / 2,
            };
        }
      };

      setCoordinates({
        top: Math.round(anchor.top + window.scrollY + getMargin(position).top),
        left: Math.round(
          anchor.left + window.scrollX + getMargin(position).left
        ),
      });
    };
    calcCoordinates();
    window.addEventListener("resize", calcCoordinates);
    window.addEventListener("scroll", calcCoordinates, true);
    return () => {
      window.removeEventListener("resize", calcCoordinates);
      window.removeEventListener("scroll", calcCoordinates);
    };
  }, []);

  useEffect(() => {
    visibility.current = isVisible;
    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
      onOpen && onOpen();
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isVisible]);

  return (
    <>
      {ReactDOM.createPortal(
        <div
          className={`${className} ${styleClasses} popover absolute top-0 left-0 rounded-xl font-normal z-9999 transition-all duration-75 ease-in opacity-0
            ${isVisible ? "opacity-100 visible" : "invisible"}`}
          style={
            coordinates && {
              transform: `translate3d(${coordinates.left}px, ${coordinates.top}px, 0)`,
            }
          }
          ref={popoverRef}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {getContent(setIsVisible)}
        </div>,
        document.body
      )}
      <div
        className={clsx(anchorClassName && anchorClassName, "cursor-pointer")}
        ref={popoverAnchorRef}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (visibility.current) closePopover();
          else openPopover();
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Popover;

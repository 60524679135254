import lodash from 'lodash';

export const actionTypes = {
  GET: 'getClickmap'
};
export const storageName = 'clickmaps';

export interface ClickmapCollection extends Record<string, IClickmap> { }

export interface IClickmap {
  areas: IArea[];
  height: number;
  width: number;
  ownerId: string;
}

export interface IArea {
  left: number;
  top: number;
  height: number;
  width: number;
  index: number;
  clicksCount?: number
  share?: number;
}


export class ClickmapStorage {

  static getClickmaps(): ClickmapCollection {
    const sourceClickAreas = localStorage.getItem(storageName);
    return sourceClickAreas ? JSON.parse(sourceClickAreas) : {};
  }

  static updateClickmaps(clickmaps: ClickmapCollection) {
    localStorage.setItem(storageName, JSON.stringify(clickmaps));
  }

  static getClickmap(ownerId: string) {
    try {
      return { ...lodash.get(ClickmapStorage.getClickmaps(), ownerId), ownerId };
    } catch (error) {
      console.error(error);
    }
  }

  static updateClickmap(ownerId: string, clickmap: ClickmapCollection) {
    try {
      STORAGE.updateClickmaps(lodash.set(ClickmapStorage.getClickmaps(), [ownerId], clickmap));
    } catch (error) {
      console.error(error);
    }
  }

  static deleteClickmap(ownerId: string) {
    try {
      STORAGE.updateClickmaps(lodash.omit(STORAGE.getClickmaps(), [ownerId]));
    } catch (error) {
      console.error(error);
    }
  }
}

export const STORAGE = {
  getClickmaps: () => {
    const sourceClickAreas = localStorage.getItem(storageName);
    return sourceClickAreas ? JSON.parse(sourceClickAreas) : {};
  },
  updateClickmaps: (clickmaps: ClickmapCollection) => {
    localStorage.setItem(storageName, JSON.stringify(clickmaps));
  },
  getClickmap: (ownerId: string) => {
    try {
      return { ...lodash.get(STORAGE.getClickmaps(), ownerId), ownerId } as IClickmap;
    } catch (error) {
      console.error(error);
    }
  },

  updateClickmap: (ownerId: string, clickmap: ClickmapCollection) => {
    try {
      STORAGE.updateClickmaps(
        lodash.set(STORAGE.getClickmaps(), [ownerId], clickmap)
      );
    } catch (error) {
      console.error(error);
    }
  },
  deleteClickmap: (ownerId: string) => {
    try {
      STORAGE.updateClickmaps(
        lodash.omit(STORAGE.getClickmaps(), [ownerId])
      );
    } catch (error) {
      console.error(error);
    }
  }
};

import React, { useEffect, useState } from "react";
import "../../../tailwind.generated.css";
import clsx from "clsx";

import _ from "lodash";

const MatrixTable = ({
  blockId,
  rows,
  columns,
  cardsData,
  individual,
  response,
}) => {
  const [withScroll, setWithScroll] = useState(null);

  useEffect(() => {
    const tableElement = document.getElementById(
      `matrixTableWrapper-${blockId}`
    );
    const withScroll = tableElement.scrollWidth > tableElement.clientWidth;
    setWithScroll(withScroll);
  }, []);

  return (
    <div className="relative">
      {withScroll && (
        <div className="absolute w-4 h-full right-0 top-0 bg-gradient-to-r from-transparent to-white z-30"></div>
      )}
      <div
        className={clsx("relative overflow-x-auto", withScroll && "pr-4")}
        id={`matrixTableWrapper-${blockId}`}
      >
        <table className="border-separate">
          <thead>
            <tr>
              <th className="sticky text-center px-2 py-4 left-0"></th>
              {columns.map((column) => (
                <th
                  className="text-center px-2 py-4 relative font-normal text-sm"
                  key={column.id}
                >
                  <div
                    className={clsx(
                      "w-24",
                      column.id === "unsorted" && "text-gray-700"
                    )}
                  >
                    {column.value}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody id="matrixTbody">
            {rows.map((row) => {
              if (!individual) {
                const sortedCardsAppearance = _.sortedUniq(
                  Object.keys(cardsData[row.id])
                    .map((categoryId) => cardsData[row.id][categoryId])
                    .sort((a, b) => b - a)
                );
                const top1value = sortedCardsAppearance[0];
                const top2value = sortedCardsAppearance[1];
                const getCellColor = (cellValue) => {
                  if (cellValue === 0 || individual) {
                    return "bg-gray-200";
                  }
                  if (cellValue === top1value) {
                    return "bg-acid-200";
                  }
                  if (cellValue === top2value) {
                    return "bg-acid-100";
                  }
                  return "bg-white";
                };
                return (
                  <tr key={row.id} className="h-12">
                    <td
                      className="sticky px-3 align-middle text-sm left-0 bg-white text-ellipsis w-40 overflow-hidden"
                      style={{ minWidth: "160px" }}
                    >
                      {row.value}
                    </td>
                    {columns.map((column) => (
                      <td
                        className={clsx(
                          "px-2 py-4 text-center min-w-5xs h-12 text-xl rounded-md",
                          getCellColor(cardsData[row.id][column.id])
                        )}
                        key={column.id}
                      >
                        {cardsData[row.id][column.id]}
                      </td>
                    ))}
                  </tr>
                );
              } else {
                return (
                  <tr key={row.id} className="h-12">
                    <td
                      className="sticky px-3 align-middle text-sm left-0 bg-white text-ellipsis w-40 overflow-hidden"
                      style={{ minWidth: "160px" }}
                    >
                      {row.value}
                    </td>
                    {columns.map((column) => (
                      <td
                        className={clsx(
                          "px-2 py-4 text-center min-w-5xs h-12 text-xl rounded-md bg-gray-200"
                        )}
                        key={column.id}
                      >
                        {response.sorting[column.id] &&
                        response.sorting[column.id].find(
                          (cardInCategory) => cardInCategory.id === row.id
                        )
                          ? "✓"
                          : ""}
                      </td>
                    ))}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatrixTable;

import React, { useState, useEffect } from "react";
import "../../../tailwind.generated.css";

import lodash from "lodash";
import FigmaScreenModal from "./FigmaScreenModal";
import FigmaReportView from "./FigmaReportView";
import { getNodeAsset } from "../../../utils/figma";

import { calcMedian } from "../utils";
import TooltipWithIcon from "../../TooltipWithIcon";

const isHtmlView = (clicks) =>
  clicks.some((click) => {
    return Boolean(lodash.get(click, "nodeId"));
  });

const getFormattedClicks = (clicks, answerId) => {
  const formattedClicks = [];

  const canShowClicksOrder = !clicks.some((click) => {
    return !click.timestamp;
  });

  if (canShowClicksOrder) {
    clicks.sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
  }

  clicks.forEach((click, index) => {
    const formattedClick = {
      handled: click.handled,
      answerId,
      timestamp: click.timestamp,
      number: canShowClicksOrder ? index + 1 : undefined,
    };

    if (isHtmlView(clicks)) {
      formattedClick.clickData = {
        nodeId: lodash.get(click, "nodeId"),
        left: lodash.get(click, "x"),
        top: lodash.get(click, "y"),
      };
    } else {
      formattedClick.clickData = {
        left: lodash.get(click, "coordinates.x", 0),
        top: lodash.get(click, "coordinates.y", 0),
      };
    }

    formattedClicks.push(formattedClick);
  });

  return formattedClicks;
};

const getFormattedTesters = (
  responses,
  nodeNames,
  nodeImages,
  figmaVersion
) => {
  const results = [];

  lodash.cloneDeep(responses).forEach((response) => {
    const { answerId, givenUp, time, path, nodeEventData } = response;
    const testerIndex = results.length;
    const screens = [];

    path.forEach((nodeKey, index) => {
      const { clicks = [], timeSpent = 0 } = nodeEventData[index] || {};
      screens.push({
        name: getNodeAsset([nodeKey, figmaVersion], nodeNames),
        nodeKey,
        caption: `${index + 1} of ${path.length}`,
        image: getNodeAsset([nodeKey, figmaVersion], nodeImages),
        path: ["byTester", testerIndex, "screens", index],
        prevScreenPath:
          index > 0 ? ["byTester", testerIndex, "screens", index - 1] : null,
        nextScreenPath:
          index != path.length - 1
            ? ["byTester", testerIndex, "screens", index + 1]
            : null,
        clicks: getFormattedClicks(clicks, answerId),
        stats: [
          ["Total clicks", clicks.length],
          ["Missclicks", clicks.filter(({ handled }) => !handled).length],
          ["Time spent", `${Math.floor(timeSpent / 100) / 10} s`],
        ],
      });
    });

    results.push({
      answerId,
      givenUp,
      time: `${Math.floor(time / 100) / 10} s`,
      number: testerIndex + 1,
      screens,
    });
  });

  return results;
};

const getFormattedScreens = (
  responses,
  nodeNames,
  nodeImages,
  figmaVersion
) => {
  const results = {
    screens: [],
  };

  const paths = [];
  const eventData = {};

  lodash.cloneDeep(responses).forEach(({ path, nodeEventData, answerId }) => {
    path.forEach((nodeKey, index) => {
      const { clicks = [], timeSpent = 0 } = nodeEventData[index] || {};

      if (eventData[nodeKey]) {
        eventData[nodeKey].clicks.push(...getFormattedClicks(clicks, answerId));
        eventData[nodeKey].time.push(timeSpent);
      } else {
        eventData[nodeKey] = {
          clicks: getFormattedClicks(clicks, answerId),
          time: [timeSpent],
        };
      }

      paths.push({
        nodeKey,
        index,
      });
    });
  });

  const sortedPaths = lodash.uniqBy(lodash.sortBy(paths, "index"), "nodeKey");

  sortedPaths.forEach(({ nodeKey }, index) => {
    const { time, clicks } = eventData[nodeKey];
    const averageTime = lodash.sum(time) / time.length;
    const medianTime = calcMedian(time);

    results.screens.push({
      name: getNodeAsset([nodeKey, figmaVersion], nodeNames),
      nodeKey,
      caption: "All users",
      image: getNodeAsset([nodeKey, figmaVersion], nodeImages),
      path: ["byScreen", "screens", index],
      prevScreenPath: index > 0 ? ["byScreen", "screens", index - 1] : null,
      nextScreenPath:
        index != sortedPaths.length - 1
          ? ["byScreen", "screens", index + 1]
          : null,
      clicks,
      stats: [
        ["Total clicks", clicks.length],
        ["Missclicks", clicks.filter(({ handled }) => !handled).length],
        ["Average time", `${Math.floor(averageTime / 100) / 10} s`],
        ["Median time", `${Math.floor(medianTime / 100) / 10} s`],
      ],
    });
  });

  return results;
};

const FigmaReport = ({
  responses,
  isSummaryReport = true,
  testId,
  block,
  block: { blockId, fileVersion, nodeImages, nodeNames, nodesForHtml, text },
}) => {
  const [state, setState] = useState(null);
  const [activeScreenPath, setActiveScreenPath] = useState(null);

  useEffect(() => {
    setState({
      byTester: getFormattedTesters(
        responses,
        nodeNames,
        nodeImages,
        fileVersion
      ),
      byScreen: getFormattedScreens(
        responses,
        nodeNames,
        nodeImages,
        fileVersion
      ),
    });
  }, [responses]);

  // calculate average response time

  const averageCompletionTime =
    responses.reduce((acc, response) => {
      return acc + response.time;
    }, 0) / responses.length;

  // calculate median response time

  const numbers = responses.map((response) => response.time);

  const medianCompletionTime = (calcMedian(numbers) / 1000).toFixed(2);

  const gaveUpTotal = responses.reduce((acc, response) => {
    return response.givenUp === true ? acc + 1 : acc;
  }, 0);

  const succeedTotal = responses.length - gaveUpTotal;
  const currentScreen = lodash.get(state, activeScreenPath);

  return (
    <>
      {isSummaryReport && (
        <>
          <div className="flex justify-between items-center mb-4">
            <div className="text-xl font-medium">{text}</div>
          </div>

          <div className="my-6 text-xl max-w-sm">
            <div className="flex justify-between">
              <span>Succeed</span>
              <span>{succeedTotal}</span>
            </div>
            <div className="bg-gray-300 my-1" style={{ height: "2px" }}></div>
            <div className="flex justify-between">
              <span>Gave Up</span>
              <span>{gaveUpTotal}</span>
            </div>
            <div className="bg-gray-300 my-1" style={{ height: "2px" }}></div>
            <div className="flex justify-between">
              <span>Average time</span>
              <span>{Math.floor(averageCompletionTime / 1000)} s</span>
            </div>
            <div className="bg-gray-300 my-1" style={{ height: "2px" }}></div>
            <div className="flex justify-between">
              <span className="flex items-center">
                <span>Median time</span>
                <TooltipWithIcon
                  className="ml-2 inline"
                  size={20}
                  text={
                    <>
                      Median is the value separating the higher half from the
                      lower half of testers responses.
                      <br />
                      <br />
                      It is generally unaffected by outliers: responses with a
                      very long response time.
                    </>
                  }
                />
              </span>
              <span>{medianCompletionTime} s</span>
            </div>
          </div>
        </>
      )}

      {state && (
        <FigmaReportView
          setActiveScreenPath={setActiveScreenPath}
          byTester={state.byTester}
          byScreen={state.byScreen}
          isSummaryReport={isSummaryReport}
          prototype={block.prototypeData}
        />
      )}

      {activeScreenPath && (
        <FigmaScreenModal
          screen={currentScreen}
          prototype={block.prototypeData}
          key={currentScreen.nodeKey}
          setActiveScreenPath={setActiveScreenPath}
          clickmapOwnerId={`figma-${testId}-${blockId}-${currentScreen.nodeKey}`}
          nodeForHtml={
            nodesForHtml &&
            lodash.get(currentScreen.clicks, "0.clickData.nodeId") &&
            nodesForHtml[currentScreen.nodeKey]
          }
          imageUrl={lodash.get(state, [...activeScreenPath, "image"])}
          type={lodash.head(activeScreenPath)}
        />
      )}
    </>
  );
};

export default FigmaReport;

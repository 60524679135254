import React, { useState } from "react";
import "../../tailwind.generated.css";
import date from "date-and-time";

import FigmaReport from "./FigmaReport/index";
import ClicksTab from "./FirstClickReport/ClicksTab";
import MatrixTable from "./CardSortReport/MatrixTable";
import UrlTag from "./UrlTag";

import { getBlockTitle } from "../../utils";

import Loader from "../Loader";
import Button from "../Button";
import Modal from "../Modal";
import { Img } from "react-image";
import { ReactComponent as IconChoice } from "../../icons/choice.svg";
import { ReactComponent as IconOpenQuestion } from "../../icons/openquestion.svg";
import { ReactComponent as IconFirstClick } from "../../icons/firstclick.svg";
import { ReactComponent as IconFigma } from "../../icons/figma.svg";
import { ReactComponent as IconPreference } from "../../icons/preference.svg";
import { ReactComponent as IconDelete } from "../../icons/delete.svg";

const icon = {
  openquestion: <IconOpenQuestion width={32} height={32} />,
  choice: <IconChoice width={32} height={32} />,
  firstclick: <IconFirstClick width={32} height={32} />,
  figma: <IconFigma width={32} height={32} />,
  preference: <IconPreference width={32} height={32} />,
  delete: (
    <IconDelete
      className="fill-current text-gray-700 hover:text-red-600"
      width={24}
      height={24}
    />
  ),
};

const BlockResponse = ({ testData, blockResponse }) => {
  const blockData = testData.publishedContent.find(
    (e) => e.blockId === blockResponse.blockId
  );

  if (blockResponse.blockType === "openquestion") {
    // Open question Block Results
    return (
      <>
        <div className="flex items-center justify-center">
          {icon.openquestion}
          <div className="ml-2 flex-1 leading-none text-gray-700">
            {blockData.text}
          </div>
        </div>
        <div className="text-xl mt-4">{blockResponse.data.textValue}</div>
      </>
    );
  }
  if (blockResponse.blockType === "choice") {
    // Choice Block Results
    return (
      <>
        <div className="flex items-center justify-center">
          {icon.choice}
          <div className="ml-2 flex-1 leading-none text-gray-700">
            {blockData.text}
          </div>
        </div>
        <div className="text-xl mt-4">
          {blockResponse.data.selectedOptions.map((option) => (
            <span
              key={option.id}
              className="px-2 py-1 text-xl rounded-full inline-block border-gray-400 border-2 mr-2 mb-2"
            >
              {option.value}
            </span>
          ))}
        </div>
      </>
    );
  }
  if (blockResponse.blockType === "preference") {
    const findReplyDataById = (id) => {
      const reply = blockData.replies.find((element) => element.id === id);
      return { replyValue: reply.replyValue, image: reply.image };
    };

    // Preference Block Results
    return (
      <>
        <div className="flex items-center justify-center">
          {icon.preference}
          <div className="ml-2 flex-1 leading-none text-gray-700">
            {blockData.text}
          </div>
        </div>
        <div className="text-xl mt-4">
          {blockResponse.data.selectedOptions.map((option) => {
            return (
              <div className="flex items-center justify-start" key={option.id}>
                <Img
                  className="rounded-lg w-16 h-16 object-contain bg-gray-200"
                  src={findReplyDataById(option.id).image}
                  alt=""
                  loader={
                    <div className="bg-gray-200 rounded-lg w-16 h-16">
                      <Loader />
                    </div>
                  }
                />
                <span className="ml-4">
                  {findReplyDataById(option.id).replyValue}
                </span>
              </div>
            );
          })}
        </div>
      </>
    );
  }
  if (blockResponse.blockType === "firstclick") {
    // Question Block Results
    return (
      <>
        <div className="flex items-center justify-center">
          {icon.firstclick}
          <div className="ml-2 flex-1 leading-none text-gray-700">
            {blockData.text}
          </div>
        </div>
        <div className="text-xl mt-4">
          <div className="my-4">
            Response time:{" "}
            {Math.floor(blockResponse.data.clickData.responseTime / 1000)}s
          </div>
          <ClicksTab
            imageUrl={blockData.image}
            responses={[blockResponse.data]}
            summaryReport={false}
          />
        </div>
      </>
    );
  }

  if (blockResponse.blockType === "figma") {
    // Figma Block Results
    return (
      <>
        <div className="flex items-center justify-center">
          {icon.figma}
          <div className="ml-2 flex-1 leading-none text-gray-700">
            {blockData.text}
          </div>
        </div>
        <div className="text-xl mt-4">
          <FigmaReport
            block={blockData}
            responses={[blockResponse.data]}
            isSummaryReport={false}
          />
        </div>
      </>
    );
  }

  if (blockResponse.blockType === "cardsort") {
    const blockTitle = getBlockTitle("cardsort", 36);

    const columns = [...blockData.categories];
    if (blockData.doNotRequireToSortAll === true) {
      columns.push({ id: "unsorted", value: "Unsorted" });
    }

    const rows = [...blockData.cards];
    // Card sort block results
    return (
      <>
        <div className="flex items-center justify-center">
          {blockTitle.icon}
          <div className="ml-2 flex-1 leading-none text-gray-700">
            Sort each card into the category that makes sense to you:
          </div>
        </div>
        <MatrixTable
          blockId={blockData.blockId}
          individual={true}
          response={blockResponse.data}
          columns={columns}
          rows={rows}
        />
      </>
    );
  }

  return <></>;
};

const Response = ({
  testData,
  responseData,
  handleDeleteResponse,
  responseIndex,
  sharedByLink,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const hasResponseDate = responseData.blockAnswers[0].data.submittedAt;
  const responseDate = new Date(responseData.blockAnswers[0].data.submittedAt);

  const urlTags = responseData.urlTags;

  return (
    <div className="rounded-xl bg-white p-5 my-4 responseBlock">
      <div className="flex justify-between items-center">
        <div className="font-medium mb-2">
          {hasResponseDate && date.format(responseDate, "D MMM, HH:mm")}
        </div>
        {!sharedByLink && (
          <>
            <div>
              <div
                className="cursor-pointer responseBlockDelete"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              >
                {icon.delete}
              </div>
            </div>
            {isDeleteModalOpen && (
              <Modal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                width="40%"
                background
              >
                <div className="p-6">
                  <div className="flex text-3xl tracking-tight mb-3 font-medium">
                    Delete answer
                  </div>
                  <div>Are you sure? This is irreversible.</div>
                  <div className="flex mt-4 justify-between">
                    <Button
                      name="Cancel"
                      type="secondary"
                      handler={() => {
                        setIsDeleteModalOpen(false);
                      }}
                    />
                    <Button
                      name="Yes, delete this response"
                      type="danger"
                      isLoading={isDeleting}
                      handler={async () => {
                        try {
                          setIsDeleting(true);
                          await handleDeleteResponse(responseData.answerId);
                        } catch (error) {
                          setIsDeleting(false);
                          setIsDeleteModalOpen(false);
                          console.error(error);
                        }
                      }}
                    />
                  </div>
                </div>
              </Modal>
            )}
          </>
        )}
      </div>
      {urlTags && Object.keys(urlTags).length !== 0 && (
        <div className="my-2 flex items-center justify-start">
          {Object.keys(urlTags).map((key) => (
            <UrlTag tag={key} value={urlTags[key]} />
          ))}
        </div>
      )}
      {responseData.blockAnswers.map((blockResponse, i) => {
        return (
          <div key={`response-block-${i}`}>
            {i !== 0 && <div className="w-full h-px bg-gray-300 my-5"></div>}
            <BlockResponse testData={testData} blockResponse={blockResponse} />
          </div>
        );
      })}
    </div>
  );
};

const Responses = ({
  testData,
  handleDeleteResponse,
  sharedByLink,
  filteredAnswers,
  answersMetaData,
}) => {
  const answersByBlock = { ...testData.answers };
  const responseData = [];

  const getResponseIndexById = (answerId) => {
    const index = responseData.findIndex((e) => e.answerId === answerId);
    return index > -1 ? index : null;
  };

  const isInvalidResponse = (answerId) => {
    return testData.invalidAnswers?.some((id) => id === answerId);
  };

  const isDeletedResponse = (answerId) => {
    return testData.deletedAnswers?.some((id) => id === answerId);
  };

  const isFilteredResponse = (answerId) => {
    return !filteredAnswers.some((id) => id === answerId);
  };

  testData.publishedContent.forEach((block) => {
    const blockId = block.blockId;
    const blockType = block.type;
    if (
      blockType !== "context" &&
      blockType !== "fiveseconds" &&
      answersByBlock[blockId]
    ) {
      answersByBlock[blockId].responses.forEach((response) => {
        const blockResponse = { ...response };
        delete blockResponse.answerId;
        if (
          !isInvalidResponse(response.answerId) &&
          !isDeletedResponse(response.answerId) &&
          !isFilteredResponse(response.answerId)
        ) {
          const responseIndex = getResponseIndexById(response.answerId);
          if (responseIndex !== null) {
            responseData[responseIndex].blockAnswers.push({
              data: blockResponse,
              blockId: blockId,
              blockType: blockType,
            });
          } else {
            responseData.push({
              answerId: response.answerId,
              urlTags: answersMetaData[response.answerId]?.urlTags,
              blockAnswers: [
                { data: blockResponse, blockId: blockId, blockType: blockType },
              ],
            });
          }
        }
      });
    }
  });

  return responseData.map((response, i) => {
    return (
      <Response
        key={`response-${i}`}
        testData={testData}
        responseData={response}
        handleDeleteResponse={handleDeleteResponse}
        responseIndex={i}
        sharedByLink={sharedByLink}
      />
    );
  });
};

export default Responses;

import React from "react";
import { IFolder, ISplitGroup, ITest } from './models/Test';
import { IUser } from './models/User';

export const DefaultAppState: IAppState = {
  user: {} as IUser,
  splitGroups: [],
  tests: {},
  folders: {},
  heatmaps: {},
  credits: {},
  team: {},
  app: {
    notification: {
      type: null,
      text: null,
    },
    ui: {
      isUpgradeModalOpen: false,
    },
  },
};

const DefaultAppContext: IAppContext = {
  state: DefaultAppState,
  dispatch: (result: any) => result
};

export const AppContext = React.createContext(DefaultAppContext);

export default AppContext;




export interface IAppContext {
  state: IAppState;
  dispatch<T>(result: IActionResult<T>): React.Dispatch<IActionResult<T>>;
}

export interface IAppState {
  user: IUser;
  tests: Record<string, ITest>;
  splitGroups: ISplitGroup[];
  folders: Record<string, IFolder>;
  heatmaps: any;
  credits: any;
  team: any;
  app: {
    notification: {
      type: null | string;
      text: null | string;
    },
    ui: {
      isUpgradeModalOpen: boolean;
    };
  }
}

export interface IActionResult<T> {
  type: string;
  payload: {
    data?: T;
    tests?: T;
  }
}

import React, { useState, useEffect } from "react";
import SignInLayout, { getSignInButtonClassName } from "./Layouts/SignInLayout";
import Loader from "./Loader";


import { useParams, Link } from "react-router-dom";
import { getInvite, acceptInvite } from "../actions";
import { Formik } from "formik";
import { Redirect } from 'react-router'
import { supportLink } from "../helpers";

const getErrorMessage = (errorCode: string) => {
  if (errorCode === "FORBIDDEN") {
    return {
      title: "Participant limit exhausted",
      description: "The project's participant limit has been exhausted, try asking your invitee to increase the limit."
    };
  }

  if (errorCode === "ALREADY_JOINED") {
    return {
      title: "You already joined",
      description: "You are already a member of this team."
    };
  }

  return {
    title: "Invitation does not exist",
    description: "This invitation may have expired, try asking for another one."
  };
}

type RouterParams = {
  inviteCode: string;
};

type Invite = {
  teamName: string,
};

type InviteState = {
  isLoading: boolean,
  errorCode: string,
  invite: Invite | null,
  inviteAccepted: boolean,
};

const Invite = () => {
  const { inviteCode } = useParams<RouterParams>();
  const [state, setState] = useState<InviteState>({
    isLoading: true,
    errorCode: "",
    invite: null,
    inviteAccepted: false,
  });

  const processGetInvite = () => {
    getInvite(inviteCode).then((invite: Invite) => {
      setState((state) => ({ ...state, invite, isLoading: false }));
    }).catch((errorCode) => {
      setState((state) => ({ ...state, errorCode, isLoading: false }));
    });
  };

  const processAcceptInvite = () => {
    acceptInvite(inviteCode).then(() => {
      setState((state) => ({ ...state, inviteAccepted: true }));
    })
    .catch((errorCode) => {
      setState((state) => ({ ...state, errorCode }));
    });
  };

  useEffect(() => {
    processGetInvite();
  }, []);

  if (state.inviteAccepted) {
    return <Redirect to="/" />;
  }

  if (state.errorCode === "NETWORK_ERROR") {
    return (
      <SignInLayout title="Unable to use the network">
        <div className="max-w-xs mx-auto mb-6">
          Something seems to have happened to your network, we cannot send the request. Please check the connection and try again.
        </div>
        <button 
          style={{ display: "inline-block" }} 
          className={getSignInButtonClassName()}
          onClick={() => {
            setState((state) => ({ ...state, errorCode: "", isLoading: true }));
            if (state.invite === null) processGetInvite();
            else processAcceptInvite();
          }}>
          Try again
        </button>
      </SignInLayout>
    );
  }

  if (state.errorCode === "SERVER_ERROR") {
    return (
      <SignInLayout title="Oops, something's wrong">
        <div className="max-w-xs mx-auto mb-6">
          Oops, something went wrong. Please, <Link to={supportLink}>contact us</Link> or try again. 
        </div>
        <button 
          style={{ display: "inline-block" }} 
          className={getSignInButtonClassName()}
          onClick={() => {
            setState((state) => ({ ...state, errorCode: "", isLoading: true }));
            if (state.invite === null) processGetInvite();
            else processAcceptInvite();
          }}>
          Try again
        </button>
      </SignInLayout>
    );
  }

  if (state.errorCode) {
    const { title, description } = getErrorMessage(state.errorCode);

    return (
      <SignInLayout title={title}>
        <div className="max-w-xs mx-auto mb-6">
          {description}
        </div>
        <Link style={{ display: "inline-block" }} className={getSignInButtonClassName()} to={"/"}>
          Go to my tests
        </Link>
      </SignInLayout>
    );
  }

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <SignInLayout title={`Join ${state.invite?.teamName}`}>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          processAcceptInvite();
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-w-xs mx-auto mb-6">
              Would you like to join the team?
            </div>
            <button
              style={{ display: "inline-block" }}
              className={getSignInButtonClassName({
                disabled: isSubmitting
              })}
              type="submit"
            >
              Join
            </button>
          </form>
        )}
      </Formik>
    </SignInLayout>
  );
}

export default Invite;
import React from 'react';
import AppContext, { IAppContext } from '../../../AppContext';
import lodash from 'lodash';
import FigmaHtml from "./FigmaHtml";
import FigmaReportView from "../../Figma/FigmaReportView";
import { ClickmapStorage, actionTypes, storageName, ClickmapCollection, IArea, IClickmap } from './STORAGE';
import { IPosition } from './Models';

export { default as Clickmap } from './Clickmap';
export { default as Areas } from './Areas';
export { default as Heatmap } from './Heatmap';
export { default as Clicks } from './Clicks';
export { default as FigmaAreas } from './FigmaAreas';
export { default as FigmaClicks } from './FigmaClicks';
export { default as FigmaHeatmap } from './FigmaHeatmap';


export interface IClickmapHOCProps {
  clickmapOwnerId: string;
  imageUrl: string;
  nodeForHtml?: string;
  prototype?: any;
  screen?: any;
}

export interface IFigmaHtmlLoadedParams {
  width: number;
  height: number;
  nodePositions: Record<string, IPosition>;
}

const initialState = {
  loading: true,
  figmaNodePositions: {},
};

// WrappedComponent = ClickViews | FigmaScreenModal
export const withClickmap = (WrappedComponent: any) => {

  class ClickmapHOC extends React.Component<IClickmapHOCProps, typeof initialState> {
    imageWidth: any;
    imageHeight: any;
    clickmap: any;
    mount: any;

    constructor(props: IClickmapHOCProps) {
      super(props);
      this.state = {
        ...initialState
      };
    }

    async initializeAreas() {
      const { clickmapOwnerId, imageUrl } = this.props;
      const { dispatch } = this.context as IAppContext;

      const clickmap = ClickmapStorage.getClickmap(clickmapOwnerId);

      // parse image width and height
      const imageMeta = await this.getImageMeta(imageUrl);

      this.imageWidth = imageMeta.width;
      this.imageHeight = imageMeta.height;

      if (clickmap) {
        // check that the image has not changed
        if (clickmap.width === this.imageWidth && clickmap.height === this.imageHeight) {
          dispatch({ type: actionTypes.GET, payload: clickmap as any }); // Save data to context
          this.clickmap = clickmap;
        } else {
          // delete the clickmap if the image has changed
          ClickmapStorage.deleteClickmap(clickmapOwnerId);
        }
      }

      if (!this.mount) return;
      this.setState({ loading: false });
    }

    async initializeAreasForFigmaHtml(figmaHtmlParams: IFigmaHtmlLoadedParams) {
      if (this.imageWidth || this.imageHeight) return;

      const { clickmapOwnerId } = this.props;
      const { dispatch } = this.context as IAppContext;

      const clickmap = ClickmapStorage.getClickmap(clickmapOwnerId);

      this.imageWidth = figmaHtmlParams.width;
      this.imageHeight = figmaHtmlParams.height;
      this.setState({ figmaNodePositions: figmaHtmlParams.nodePositions });

      if (clickmap) {
        // check that the image has not changed
        if (clickmap.width === this.imageWidth && clickmap.height === this.imageHeight) {
          dispatch<IClickmap>({ type: actionTypes.GET, payload: clickmap as any }); // Save data to context
          this.clickmap = clickmap;
        } else {
          // delete the clickmap if the image has changed
          ClickmapStorage.deleteClickmap(clickmapOwnerId);
        }
      }

      if (!this.mount) return;
      this.setState({ loading: false });
    }

    componentDidMount() {
      this.mount = true;
      if (!this.props.nodeForHtml) {
        this.initializeAreas();
      }
    }

    componentDidUpdate(prevProps: IClickmapHOCProps) {
      if (
        prevProps.imageUrl !== this.props.imageUrl ||
        prevProps.clickmapOwnerId !== this.props.clickmapOwnerId ||
        !lodash.isEqual(prevProps.nodeForHtml, this.props.nodeForHtml)
      ) {
        this.setState({ ...initialState });
        this.clickmap = undefined as (ClickmapCollection | undefined);
        this.imageWidth = undefined;
        this.imageHeight = undefined;
        if (!this.props.nodeForHtml) {
          this.initializeAreas();
        }
      }
    }

    componentWillUnmount() {
      this.mount = false;
    }


    getImageMeta(URL: string) {
      return new Promise<{ width: number, height: number }>(resolve => {
        const image = new Image();
        image.src = URL;
        image.onload = () => resolve({
          width: image.width,
          height: image.height
        });
      })
    }

    getClickmapDefaultParams() {
      return {
        areas: [],
        width: this.imageWidth,
        height: this.imageHeight,
        ownerId: this.props.clickmapOwnerId,
      };
    }

    getClickmap() {
      const { clickmapOwnerId } = this.props;
      const { state } = this.context as IAppContext;

      return lodash.get(state, [storageName, clickmapOwnerId], this.getClickmapDefaultParams());
    }

    updateClickmapAreas = async (areas: IArea[]) => {
      const { clickmapOwnerId } = this.props;
      const { dispatch } = this.context as IAppContext;

      const updatedClickmap = this.clickmap
        ? { ...this.clickmap, areas }
        : { ...this.getClickmapDefaultParams(), areas };

      this.clickmap = updatedClickmap;
      dispatch({ type: actionTypes.GET, payload: updatedClickmap });

      ClickmapStorage.updateClickmap(clickmapOwnerId, updatedClickmap);
    }

    render() {
      let figmaImage = undefined;

      if (this.props.nodeForHtml) {
        figmaImage = {
          nodePositions: this.state.figmaNodePositions,
          component: (
            <FigmaHtml
              nodeForHtml={this.props.nodeForHtml}
              onLoad={(params: IFigmaHtmlLoadedParams) => {
                this.initializeAreasForFigmaHtml(params);
              }}
            />
          ),
        };
      }

      if (this.props.prototype && this.props.screen.nodeKey) {
        figmaImage = {
          nodePositions: this.state.figmaNodePositions,
          component: (
            <FigmaReportView
              nodeKey={this.props.screen.nodeKey}
              prototype={this.props.prototype}
              onLoad={(params: IFigmaHtmlLoadedParams) => {
                this.initializeAreasForFigmaHtml(params);
              }}
            />
          ),
        };
      }

      return (
        <WrappedComponent
          clickmap={this.getClickmap()}
          updateClickmapAreas={this.updateClickmapAreas}
          figmaImage={figmaImage}
          loading={this.state.loading}
          // TODO remove V
          {...this.props}

          clickmapOwnerId={this.props.clickmapOwnerId}
          imageUrl={this.props.imageUrl}
          nodeForHtml={this.props.nodeForHtml}
          prototype={this.props.prototype}
          screen={this.props.screen}

        />
      );
    }
  };

  ClickmapHOC.contextType = AppContext;

  return ClickmapHOC;
};

// export const getFigmaHtmlComponent = (params: any) => (props) => {
//   return <FigmaHtml {...params} {...props} />;
// }

import React from "react";
import "../../tailwind.generated.css";

import QuestionReport from "./QuestionReport";
import FirstClickReport from "./FirstClickReport/index";
import FigmaReport from "./FigmaReport/index";
import PreferenceReport from "./PreferenceReport";
import CardSortReport from "./CardSortReport/CardSortReport";

import { blockTypeMap } from "../Common";
import { BlockType } from "../../models/Test";

function ContentBlockHeader({ type, responsesNum = 0 }) {
  const blockType = blockTypeMap[type];

  return (
    <div className="flex items-center">
      <div>{blockType.getIcon({ height: 24, width: 24 })}</div>
      <div className="ml-2 font-bold font-display">{blockType.name}</div>
      <div className="ml-auto text-sm font-medium text-gray-600 flex-shrink-0">
        {responsesNum} responded
      </div>
    </div>
  );
}

const ContentBlockResults = ({ answers, blockContent, blockId, testId }) => {
  if (!answers || !blockContent || answers.responses.length < 1) {
    return null;
  }

  if (!blockContent.type) return <></>;

  return (
    <div className="block-results bg-white rounded-2xl p-6 my-6">
      <ContentBlockHeader
        type={blockContent.type}
        responsesNum={answers.responses.length}
      />
      <div className="mt-4 flex-1">
        {blockContent.type === BlockType.openquestion && (
          // Open question Block Results
          <QuestionReport
            type="freeform"
            replies={blockContent.replies}
            responses={answers.responses}
            questionText={blockContent.text}
          />
        )}

        {blockContent.type === BlockType.choice && (
          // Choice Block Results
          <QuestionReport
            type={blockContent.replyType}
            replies={blockContent.replies}
            responses={answers.responses}
            questionText={blockContent.text}
            withOther={blockContent.other}
          />
        )}

        {blockContent.type === BlockType.preference && (
          <PreferenceReport
            replies={blockContent.replies}
            responses={answers.responses}
            questionText={blockContent.text}
          />
        )}

        {blockContent.type === BlockType.firstclick && (
          <FirstClickReport
            text={blockContent.text}
            imageUrl={blockContent.image}
            responses={answers.responses}
            testId={testId}
            blockId={blockId}
          />
        )}

        {blockContent.type === BlockType.figma && (
          <FigmaReport
            block={blockContent}
            responses={answers.responses}
            testId={testId}
            blockId={blockId}
          />
        )}

        {blockContent.type === BlockType.cardsort && (
          <CardSortReport block={blockContent} responses={answers.responses} />
        )}
      </div>
    </div>
  );
};

const Summary = ({
  testId,
  testData,
  isEmpty,
  sharedByLink,
  filteredAnswers,
}) => {
  if (isEmpty) {
    return <div className="text-xl text-gray-700 my-12">Nothing here.</div>;
  }

  return (
    <>
      {testData.publishedContent.map((block) => {
        const filteredAnswersData = { ...testData.answers[block.blockId] };
        filteredAnswersData.responses = testData.answers[
          block.blockId
        ]?.responses?.filter((response) => {
          const isInvalidResponse = testData.invalidAnswers?.some(
            (id) => id === response.answerId
          );
          const isDeletedResponse = testData.deletedAnswers?.some(
            (id) => id === response.answerId
          );
          const isFilteredResponse = !filteredAnswers?.some(
            (id) => id === response.answerId
          );
          return (
            !isInvalidResponse && !isDeletedResponse && !isFilteredResponse
          );
        });

        return filteredAnswersData.responses ? (
          <ContentBlockResults
            answers={filteredAnswersData}
            blockId={block.blockId}
            blockContent={block}
            testId={testId}
            key={block.blockId}
          />
        ) : null;
      })}
    </>
  );
};

export default Summary;

import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "../tailwind.generated.css";
import _ from "lodash";

import appContext from "../AppContext";

import { deleteAnswer, fetchPrototype } from "../actions";
import {
  getConditionFilteredResponses,
  getTotalUniqueResponses,
  removeInvalidOrDeletedAnswers,
} from "./Report/utils";

import { getFormattedPrototype } from "../utils/figma";

import Tabs from "./Tabs";
import Popover from "./Popover";
import Summary from "./Report/Summary";
import Responses from "./Report/Responses";
import Filters from "./Report/Filters/Filters.tsx";
import Loader from "./Loader";
import PageNotFound from "./PageNotFound";

import { IconLink, IconExport, IconMore } from '../icons';

const Report = ({ sharedByLink }) => {
  const { state } = useContext(appContext);
  const { testId } = useParams();

  const [testData, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    conditions: {},
  });

  const getTestWithToken = async (testId, sharingToken) => {
    const URL = `/api/tests/${testId}?sharingToken=${sharingToken}`;

    const testResponse = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (testResponse.ok) {
      return await testResponse.json();
    }

    return undefined;
  };

  useEffect(() => {
    (async () => {
      const test = sharedByLink
        ? await getTestWithToken(
            testId,
            new URLSearchParams(window.location.search).get("sharingToken")
          )
        : state.tests[testId];

      if (!_.has(test, "answers")) {
        setIsLoading(false);
        return;
      }

      // Upload figma prototypes
      const prototypesUpload = [];

      Object.keys(test.answers).forEach((blockId) => {
        const blockIndex = _.findIndex(test.publishedContent, [
          "blockId",
          blockId,
        ]);
        const block = test.publishedContent[blockIndex];

        // If this block is removed
        if (blockIndex < 0) return;

        if (block.type === "figma" && block.prototypeId) {
          prototypesUpload.push(
            new Promise(async (resolve, reject) => {
              const response = await fetchPrototype(block.prototypeId);
              if (response.ok) {
                test.publishedContent[blockIndex].prototypeData =
                  getFormattedPrototype(await response.json());
                return resolve();
              }
              console.error("Unable to load the prototype");
              reject();
            })
          );
        }
      });

      await Promise.all(prototypesUpload);

      setData(test);
      setIsLoading(false);
    })();
  }, [testId]);

  const handleDeleteResponse = async (answerId) => {
    await deleteAnswer(answerId, testId);
    setData((state) => {
      const newState = _.cloneDeep(state);
      if (newState.deletedAnswers) {
        newState.deletedAnswers.push(answerId);
      } else {
        newState.deletedAnswers = [answerId];
      }
      return newState;
    });
  };

  const handleUpdateFilter = (update) => {
    setFilter((current) => {
      return {
        ...current,
        ...update(current),
      };
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (testData === null) {
    return <PageNotFound />;
  }

  // removing deleted and invalid answers

  const answers = removeInvalidOrDeletedAnswers(testData);

  // applying filters

  const getFilteredResponses = (data, filter, answers) => {
    return getConditionFilteredResponses(data, filter, answers);
  };

  const filteredAnswers = getFilteredResponses(testData, filter, answers);

  const totalUniqueResponses = getTotalUniqueResponses(testData).total;

  // console.log(totalUniqueResponses);
  // console.log(filteredAnswers);

  return (
    <>
      <div className="container flex justify-between mx-auto pt-8 items-center">
        <div className="text-3xl tracking-tight font-bold font-display">
          {testData.name}
        </div>
        <div className="flex items-center justify-center">
          <Popover
            position="bottom-right"
            getContent={() => (
              <div className="p-1 w-auto text-base">
                <div className="flex flex-col items-start">
                  <a
                    className="cursor-pointer font-medium flex items-center justify-center my-1 hover:opacity-75 transition-all duration-75 ease-in"
                    href={`/api/tests/${testId}/export?sharingToken=${testData.sharingToken}`}
                    target="_blank" rel="noopener noreferrer"
                  >
                    <IconExport
                      width={24}
                      height={24}
                      className="mr-2 fill-current text-white"
                    />
                    Export .csv
                  </a>
                  {!sharedByLink && (
                    <a
                      className="cursor-pointer font-medium flex items-center justify-center my-1 hover:opacity-75 transition-all duration-75 ease-in"
                      href={`/${testId}/report?sharingToken=${testData.sharingToken}`}
                      target="_blank" rel="noopener noreferrer"
                    >
                      <IconLink className="mr-2 fill-current text-white" />
                      Share
                    </a>
                  )}
                </div>
              </div>
            )}
            anchorClassName="p-1 bg-gray-800 rounded-full hover:opacity-75 cursor-pointer transition-opacity duration-75 ease-in"
          >
            <IconMore
              width={24}
              height={24}
              className="fill-current text-white"
            />
          </Popover>
        </div>
      </div>

      <div className="mx-auto container">
        <Filters
          data={testData}
          filter={filter}
          handleUpdateFilter={handleUpdateFilter}
        />
        <div>
          {filteredAnswers.length > 0 ? (
            <Tabs>
              <div label="Summary">
                <Summary
                  testId={testId}
                  testData={testData}
                  sharedByLink={sharedByLink}
                  filteredAnswers={filteredAnswers}
                />
              </div>
              <div label={`Responses (${filteredAnswers.length})`}>
                <Responses
                  testData={testData}
                  handleDeleteResponse={handleDeleteResponse}
                  sharedByLink={sharedByLink}
                  filteredAnswers={filteredAnswers}
                  answersMetaData={testData.answersMetaData}
                />
              </div>
            </Tabs>
          ) : (
            <div className="text-xl mt-4 text-gray-700">
              {totalUniqueResponses > 0
                ? "Nothing here. Try to change your filter conditions."
                : "No responses yet"}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Report;

import React, { useState, useEffect } from "react";
import "../../tailwind.generated.css";

import Button from "../Button";
import Modal from "../Modal";
import cardSortIllustration from "../../illustrations/cardsort_instruction.gif";

import { locale, interfaceText } from "../../helpers";

import _ from "lodash";

import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import clsx from "clsx";

const CardSort = ({
  blockData: {
    cards,
    categories,
    shuffleCards,
    shuffleCategories,
    withImages,
    withDescription,
  },
  blockAnswer,
  responseStart,
  setResponseStart,
  handleBlockAnswer,
  isValidated,
  nextStepOnClick,
}) => {
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(true);

  useEffect(() => {
    handleBlockAnswer("sorting", {
      ..._.chain(shuffleCategories ? _.shuffle(categories) : categories)
        .keyBy("id")
        .mapValues(() => {
          return [];
        })
        .value(),
      unsorted: shuffleCards ? _.shuffle(cards) : cards,
    });
  }, []);

  const response = blockAnswer.sorting;

  const onCardDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newResponse = { ...response };
    const draggedCard = response[result.source.droppableId].find(
      (card) => card.id === result.draggableId
    );

    const oldCategoryId = result.source.droppableId;
    const newCategoryId = result.destination.droppableId;

    newResponse[oldCategoryId].splice(result.source.index, 1);
    newResponse[newCategoryId].splice(result.destination.index, 0, draggedCard);
    // set block answer
    handleBlockAnswer("sorting", newResponse);
    return;
  };

  const getDraggableStyle = (style, snapshot) => {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  };

  if (!response) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col h-full p-4 md:p-8 break-words">
        <DragDropContext onDragEnd={onCardDragEnd}>
          <div className="grid xl:grid-cols-5 lg:grid-cols-4 grid-cols-2 flex-1 gap-8">
            <div className="col-span-1">
              <div className="font-medium mb-4 text-base text-gray-700">
                {interfaceText.test[locale].cardSortCards} (
                {response.unsorted.length}{" "}
                {interfaceText.test[locale].cardSortLeft})
              </div>
              <div>
                <Droppable droppableId="unsorted">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {response.unsorted.length > 0 ? (
                        response.unsorted.map((card, i) => {
                          return (
                            <Draggable
                              key={card.id}
                              draggableId={card.id}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getDraggableStyle(
                                    provided.draggableProps.style,
                                    snapshot
                                  )}
                                  className="p-3 rounded-lg bg-gray-200 leading-snug my-2"
                                >
                                  {withImages &&
                                    card.image &&
                                    card.image !== "" && (
                                      <img
                                        src={card.image}
                                        className="rounded-md object-cover p-2 mx-auto"
                                        style={{ maxHeight: "240px" }}
                                      />
                                    )}
                                  <span className="block">{card.value}</span>
                                  {withDescription && (
                                    <span className="block text-sm text-gray-700">
                                      {card.description}
                                    </span>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      ) : (
                        <>{interfaceText.test[locale].cardSortFinished}</>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
            <div className="xl:col-span-4 lg:col-span-3 col-span-1">
              <div className="font-medium mb-4 text-base text-gray-700">
                {interfaceText.test[locale].cardSortCategories}
              </div>
              <div className="grid auto-rows-auto xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 gap-4">
                {categories.map((category) => {
                  return (
                    <Droppable droppableId={category.id} key={category.id}>
                      {(provided, snapshot) => (
                        <div
                          className={clsx(
                            "h-full p-3 rounded-lg border-2 bg-white leading-snug",
                            snapshot.isDraggingOver
                              ? "border-blue-600 shadow-md"
                              : "border-gray-300 border-dashed"
                          )}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {category.value}
                          {response[category.id].map((card, i) => {
                            return (
                              <Draggable
                                key={card.id}
                                draggableId={card.id}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getDraggableStyle(
                                      provided.draggableProps.style,
                                      snapshot
                                    )}
                                    className="p-3 rounded-lg bg-gray-200 leading-snug my-2"
                                  >
                                    {withImages &&
                                      card.image &&
                                      card.image !== "" && (
                                        <img
                                          src={card.image}
                                          className="rounded-md object-cover p-2 mx-auto"
                                          style={{ maxHeight: "240px" }}
                                        />
                                      )}
                                    <span className="block">{card.value}</span>
                                    {withDescription && (
                                      <span className="block text-sm text-gray-700 mt-1">
                                        {card.description}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                })}
              </div>
            </div>
          </div>
        </DragDropContext>
      </div>
      {responseStart && (
        <div
          className="fixed bottom-8"
          style={{
            zIndex: "9999",
            transform: "translate(-50%, 0)",
            left: "50%",
          }}
        >
          <Button
            type="primary"
            name={interfaceText.test[locale].cardSortNextButton}
            handler={() => {
              nextStepOnClick();
            }}
            large
            disabled={!isValidated}
            className="whitespace-nowrap"
          />
        </div>
      )}
      <Modal
        isOpen={isInstructionModalOpen}
        setIsOpen={setIsInstructionModalOpen}
        width="440px"
        background
        disallowClickOutside
      >
        <div className="p-6">
          <div className="flex text-3xl tracking-tighter font-bold font-display">
            {interfaceText.test[locale].cardSort}
          </div>
          <img src={cardSortIllustration} className="mt-4 rounded-lg" />
          <div className="my-4">{interfaceText.test[locale].cardSortTask}</div>
          <Button
            type="primary"
            name={interfaceText.test[locale].cardSortStart}
            handler={() => {
              setIsInstructionModalOpen(false);
              setResponseStart(_.now);
            }}
            large
          />
        </div>
      </Modal>
    </>
  );
};

export default CardSort;

import React from "react";
import "../../tailwind.generated.css";

import { ReactComponent as IconTag } from "../../icons/tag.svg";

const UrlTag = ({ tag, value }: { tag: string; value: string }) => {
  return (
    <div className="flex items-center justify-center mr-2 rounded-full px-2 py-1 border-2 border-gray-300">
      <IconTag
        width={24}
        height={24}
        className="fill-current text-black mr-1"
      />
      <div className="mr-1 text-gray-700">{tag}</div>
      <div className="mr-1 text-black">{value}</div>
    </div>
  );
};

export default UrlTag;

import React, { useEffect, useRef } from 'react';

export default ({
    frameSrc,
    className = "",
    style,
    onClick,
    onNodeChanged,
    onLoad,
  }) => {
    const figmaRef = useRef();

    useEffect(() => {
      const handler = ({ source, data: { type, data } }) => {
        if (figmaRef.current.contentWindow === source) {
          if (type === "INITIAL_LOAD" && onLoad) onLoad();
          if (type === "PRESENTED_NODE_CHANGED" && onNodeChanged) onNodeChanged(data);
          if (type === "MOUSE_PRESS_OR_RELEASE" && onClick) onClick(data);
        }
      };

      window.addEventListener("message", handler);
  
      return () => {
        window.removeEventListener("message", handler);
      };
    }, [frameSrc]);

      return (
        <iframe
          className={className}
          style={style}
          src={frameSrc}
          ref={figmaRef}
        />
      );
  };
import React, { useContext } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { storage } from "./firebase";

import { ReactComponent as IconChoice } from "./icons/choice.svg";
import { ReactComponent as IconOpenQuestion } from "./icons/openquestion.svg";
import { ReactComponent as IconFirstClick } from "./icons/firstclick.svg";
import { ReactComponent as IconFigma } from "./icons/figma.svg";
import { ReactComponent as IconPreference } from "./icons/preference.svg";
import { ReactComponent as IconCardSort } from "./icons/cardsort.svg";
import { ReactComponent as IconContext } from "./icons/context.svg";
import { ReactComponent as IconFiveSeconds } from "./icons/fiveseconds.svg";
import { getBlockLogic } from "./utils/tests";

export const generateId = () => {
  return uuidv4();
};

export const getBlockTitle = (blockType, iconSize) => {
  switch (blockType) {
    case "openquestion":
      return {
        name: "Open question",
        icon: iconSize ? (
          <IconOpenQuestion width={iconSize} height={iconSize} />
        ) : undefined,
      };
    case "choice":
      return {
        name: "Choice",
        icon: iconSize ? (
          <IconChoice width={iconSize} height={iconSize} />
        ) : undefined,
      };
    case "firstclick":
      return {
        name: "First click",
        icon: iconSize ? (
          <IconFirstClick width={iconSize} height={iconSize} />
        ) : undefined,
      };
    case "figma":
      return {
        name: "Figma",
        icon: iconSize ? (
          <IconFigma width={iconSize} height={iconSize} />
        ) : undefined,
      };
    case "preference":
      return {
        name: "Preference",
        icon: iconSize ? (
          <IconPreference width={iconSize} height={iconSize} />
        ) : undefined,
      };
    case "cardsort":
      return {
        name: "Card sort",
        icon: iconSize ? (
          <IconCardSort width={iconSize} height={iconSize} />
        ) : undefined,
      };
    case "context":
      return {
        name: "Context",
        icon: iconSize ? (
          <IconContext width={iconSize} height={iconSize} />
        ) : undefined,
      };
    case "fiveseconds":
      return {
        name: "5 seconds",
        icon: iconSize ? (
          <IconFiveSeconds width={iconSize} height={iconSize} />
        ) : undefined,
      };
  }
  return null;
};

export const testValidator = (content) => {
  const errors = [];
  const testContentEntries = content;
  if (testContentEntries.length === 0) {
    errors.push("Добавьте хотя бы один блок");
    return { isValid: false, errors: errors };
  }
  testContentEntries.forEach((block) => {
    if (
      block.type === "openquestion" ||
      block.type === "choice" ||
      block.type === "preference"
    ) {
      // Open Question & Choice Validator
      if (block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: "Please, enter a question",
        });
      }
      if (block.replyType === "multi" || block.replyType === "single") {
        block.replies.forEach((reply) => {
          if (
            !reply.replyValue ||
            reply.replyValue.length < 1 ||
            (block.type === "preference" && !reply.image)
          ) {
            errors.push({
              blockId: block.blockId,
              text: "Reply value shouldn't be empty",
            });
            return;
          }
        });
      }
    }
    if (block.type === "firstclick") {
      // Firstclick Validator
      if (block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: "Instruction text shouldn't be empty",
        });
      }
      if (block.image.length < 1) {
        errors.push({ blockId: block.blockId, text: "Please, add an image" });
      }
    }
    if (block.type === "cardsort") {
      // Cardsort Validator
      if (block.cards.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: "Please, add at least one card",
        });
      }
      if (block.categories.length < 2) {
        errors.push({
          blockId: block.blockId,
          text: "Please, add at least 2 categories",
        });
      }
    }
    if (block.type === "context") {
      // Context Validator
      if (block.text.length < 1) {
        errors.push({ blockId: block.blockId, text: "Please, add a text" });
      }
    }
    if (block.type === "fiveseconds") {
      // Context Validator
      if (block.image.length < 1) {
        errors.push({ blockId: block.blockId, text: "Please, add an image" });
      }
    }
    if (block.type === "figma") {
      // Figma Validator
      if (block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: "Please, add an instruction",
        });
      }
      if (!block.prototypeId && !block.fileId) {
        errors.push({
          blockId: block.blockId,
          text: "Please, paste a Figma prototype export code",
        });
      }
      if (!block.goalNode) {
        errors.push({
          blockId: block.blockId,
          text: "Please, set the target screen",
        });
      }
    }
    // Logic validation
    const logic = getBlockLogic(block);

    if (logic) {
      if (logic.elseJumpTo === "") {
        errors.push({
          blockId: block.blockId,
          text: "Else go to block shouldn't be empty",
        });
      }
      // check conditions
      if (logic.statements.length > 0) {
        logic.statements.forEach((statement) => {
          if (statement.subconditions.length < 1) {
            errors.push({
              blockId: block.blockId,
              text: "Please, add at least one condition",
            });
          }
          if (statement.jumpTo === "") {
            errors.push({
              blockId: block.blockId,
              text: "Go to block value shouldn't be empty",
            });
          }
          // check subconditions
          if (statement.subconditions.length > 0) {
            statement.subconditions.forEach((subcondition) => {
              if (subcondition.value === "") {
                errors.push({
                  blockId: block.blockId,
                  text: "Condition value shouldn't be empty",
                });
              }
            });
          }
          return;
        });
      }
    }
  });
  return { isValid: errors.length === 0, errors: errors };
};

export const responseValidator = (
  blockType,
  blockReplyType,
  response,
  blockData
) => {
  const errors = [];

  if (Object.keys(response).length === 0) {
    errors.push("Пожалуйста, введите ответ");
    return { isValid: false, errors: errors };
  }
  if (blockType === "firstclick") {
    if (!_.has(response, "clickData" || response.clickData === null)) {
      errors.push("Пожалуйста, выберите точку на изображении");
    }
  }
  if (blockType === "openquestion") {
    response.textValue === "" &&
      errors.push("Пожалуйста, введите текст ответа");
  }

  if (blockType === "choice") {
    if (response.selectedOptions.length === 0) {
      errors.push("Пожалуйста, выберите хотя бы одну опцию");
    }
    const otherOption = response.selectedOptions.find(
      (option) => option.id === "other"
    );
    if (otherOption && otherOption.value === "") {
      errors.push("Пожалуйста, введите текст ответа для варианта Другое");
    }
  }

  if (blockType === "preference") {
    response.selectedOptions.length === 0 &&
      errors.push("Пожалуйста, выберите хотя бы одну опцию");
  }

  if (blockType === "cardsort") {
    response.sorting.unsorted.length > 0 &&
      blockData.doNotRequireToSortAll === false &&
      errors.push("Please, sort all of the cards");
  }

  return { isValid: errors.length === 0, errors: errors };
};

export const getFirstClickCoordinates = (e) => {
  const imageNode = document.getElementById("firstClickImage");
  const left =
    e.pageX - imageNode.getBoundingClientRect().left - window.scrollX;
  const top = e.pageY - imageNode.getBoundingClientRect().top - window.scrollY;
  const w = imageNode.getBoundingClientRect().width;
  const h = imageNode.getBoundingClientRect().height;
  return {
    left: Math.round((left / w + Number.EPSILON) * 1000) / 1000,
    top: Math.round((top / h + Number.EPSILON) * 1000) / 1000,
  };
};

export const generateVerificationCode = () => {
  return (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
};

export const isLocalFlagEnabled = (flag) => {
  if (localStorage.getItem(flag) === "true") {
    return true;
  } else {
    return false;
  }
};

export const uploadImage = async (image, path) => {
  return storage
    .ref()
    .child(path)
    .put(image)
    .then(async (snapshot) => {
      const url = await snapshot.ref.getDownloadURL();
      return url;
    });
};

export const getRedirectUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get("redirectUrl");

  if (!redirectUrl) {
    return null;
  }

  if (new RegExp("^(?:[a-z+]+:)?//", "i").test(redirectUrl)) {
    // We should return NULL for absolute URLs to avoid some kind of redirect fraud
    return null;
  }

  return redirectUrl;
};

export const addActualParamsToUrl = (relativeUrl) => {
  const params = new URLSearchParams(window.location.search);
  const formattedUrl = new URL(relativeUrl, window.location);

  params.forEach((value, key) => {
    formattedUrl.searchParams.set(key, value);
  });

  return formattedUrl.toString().replace(/^(?:\/\/|[^/]+)*\//, "");
};

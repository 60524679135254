import React, { useRef, useEffect } from "react";
import "../../tailwind.generated.css";
import { getPrototypeUrl } from "../../utils/figma";

const FigmaIframe = ({
  blockId,
  fileId,
  startNodeId,
  fileVersion,
  prototypeOptions,
  width,
  height,
  className,
  handlePrototypeEvent,
}) => {
  const figmaRef = useRef();
  const prototypeUrl = getPrototypeUrl(blockId, fileId, [
    startNodeId,
    fileVersion,
    prototypeOptions,
  ]);

  useEffect(() => {
    const handler = ({ source, data: { type, data } }) => {
      if (figmaRef.current.contentWindow === source) {
        if (type === "PRESENTED_NODE_CHANGED") {
          handlePrototypeEvent("PRESENTED_NODE_CHANGED", data);
        }
        if (type === "MOUSE_PRESS_OR_RELEASE") {
          handlePrototypeEvent("MOUSE_PRESS_OR_RELEASE", data);
        }
        if (type === "INITIAL_LOAD") {
          handlePrototypeEvent("INITIAL_LOAD");
        }
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [fileId, fileVersion, startNodeId, prototypeOptions]);

  return (
    <iframe
      ref={figmaRef}
      className={className}
      height={height}
      width={width}
      src={prototypeUrl}
    />
  );
};

export default FigmaIframe;

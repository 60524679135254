import React from "react";
import FirstClickPointer from "../../Test/FirstClickPointer";
import Tabs from "./Tabs";

export default function ClicksTab({
  responses,
  imageUrl,
  tabs,
  summaryReport = true,
}) {
  return (
    <div className="flex-auto rounded-2xl bg-gray-800 p-6">
      {tabs && <Tabs tabs={tabs} />}
      <div className="flex items-center justify-center pt-6">
        <div className="flex items-center justify-center">
          <div
            className="relative inline"
            style={{ maxWidth: summaryReport ? "800px" : "320px" }}
          >
            {responses.map((response) => (
              <FirstClickPointer
                key={`clicks-tab-${response.answerId}`}
                position={response.clickData}
                opacity={95}
              />
            ))}
            <img
              id="firstClickImage"
              src={imageUrl}
              alt=""
              className="opacity-50 hover:opacity-100 transition-opacity ease-in duration-75"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import clsx from "clsx";
import ReactDOM from 'react-dom';

const Tab = ({ isActive, label, disabled = false, tabIndex, onClick }: { isActive: boolean, label: string, disabled?: boolean, tabIndex: number, onClick: (i: number) => void }) => {
  return (
    <li
      className={clsx(
        isActive ? "tab-item-active" : disabled ? "tab-item-disabled" : "tab-item",
        tabIndex > 0 && "ml-3",
      )}
      onClick={() => {
        if (disabled !== true) {
          onClick(tabIndex);
        }
      }}
    >
      {label}
    </li>
  );
};

export interface ITabsProps {
  children: React.ReactElement<ITabItemProps>[],
  className: string,
  contentRef?: HTMLDivElement | null;
  position?: 'left' | 'center';
}

export interface ITabItemProps { label: string; disabled?: boolean, children: any }

const Tabs = ({ children, className, contentRef, position = 'left' }: ITabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const needRenderInContainer = (!!contentRef);

  const onClickTabItem = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  const renderTabChildren = () => children.map((tabContent, tabIndex) => {
    return tabIndex === activeTabIndex ? (<div key={tabContent.props.label}>{tabContent.props.children}</div>) : null;
  });

  return (
    <div className={clsx("tabs", className && className)}>
      <ol className={clsx("tab-list flex flex-row", position === 'center' ? 'justify-center' : '')}>
        {children.map((child, i) => {
          const { label, disabled } = child.props;
          return (
            <Tab
              isActive={activeTabIndex === i}
              key={label}
              label={label}
              tabIndex={i}
              onClick={onClickTabItem}
              disabled={disabled}
            />
          );
        })}
      </ol>
      <div className="tab-content">
        <>
        {!needRenderInContainer && renderTabChildren()}
        {needRenderInContainer && ReactDOM.createPortal(renderTabChildren(), contentRef)}
        </>
      </div>
    </div>
  );
};

export function TabItem(props: ITabItemProps) {
  return <>{props.children}</>
}

export default Tabs;

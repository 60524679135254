import React from "react";
import "../../tailwind.generated.css";

import Tabs from "../Tabs";
import Tests from "./Tests";

const Admin = () => {
  return (
    <div className="container mx-auto h-full pt-6">
      <Tabs>
        <div label="Tests">
          <Tests />
        </div>
        <div label="Users">
          {/* <Users /> */}
          🚧 Under construction
        </div>
      </Tabs>
    </div>
  );
};

export default Admin;

import React, { FunctionComponent } from "react";
import clsx from "clsx";

type ButtonProps = {
  name?: string;
  type: "primary" | "secondary" | "tertiary" | "danger" | "ghost" | "dashed";
  handler?: (event?: React.MouseEvent<HTMLElement>) => void;
  large?: boolean;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  small?: boolean;
  extrasmall?: boolean;
  fullWidth?: boolean;
  extralarge?: boolean;
  icon?: any;
  id?: string;
};

const Button: FunctionComponent<ButtonProps> = ({
  name = "",
  type = "primary",
  handler,
  large,
  disabled,
  className,
  isLoading,
  small,
  extrasmall,
  fullWidth,
  extralarge,
  icon,
  id,
}) => {
  return (
    <div
      id={id}
      style={{ 
        width: fullWidth ? "auto" : "fit-content", 
      }}
      className={clsx(
        className,
        "object-center block rounded-full transition-all font-medium duration-75 ease-in cursor-pointer tracking-tight p-2 leading-4",
        !type && "bg-blue-600 text-white hover:bg-blue-700",
        type === "tertiary" && "text-black bg-white",
        type === "secondary" && "hover:opacity-50 shadow-border",
        type === "primary" && "bg-blue-600 text-white hover:bg-blue-700",
        type === "danger" && "bg-red-600 text-white hover:bg-red-700",
        type === "dashed" && "text-gray-600 border-dashed border-2 border-gray-400 hover:text-gray-500",
        type === "ghost" && "hover:opacity-50",
        extralarge && type !== "ghost" && "text-2xl px-6",
        extralarge && type === "ghost" && "text-2xl",
        large && type !== "ghost" && "text-xl px-4",
        large && type === "ghost" && "text-xl",
        small && type !== "ghost" && "text-base",
        small && type === "ghost" && "text-base",
        extrasmall && type !== "ghost" && "text-xs py-1 px-1",
        extrasmall && type === "ghost" && "text-xs",
        (disabled || isLoading) && "opacity-50 cursor-not-allowed",
        fullWidth && "text-center"
      )}
      onClick={(e) => {
        if (handler && !disabled) {
          e.stopPropagation();
          handler(e);
        }
      }}
    >
      {isLoading && <>Please, wait...</>}
      {!isLoading && (
        <div className="flex items-center justify-center">
          <>{icon} {name}</>
        </div>
      )}
    </div>
  );
};

export default Button;

import React from "react";
import { CodeBlock } from "./FinalStep";

import { ReactComponent as IconStop } from "../../icons/stop.svg";

import { locale, interfaceText } from "../../helpers";

const AnswersLimitExceeded = ({ verificationCode }) => {
  return (
    <div className="answers-limit-exceeded container mx-auto h-full flex justify-center items-center">
      <div className="text-center">
        <IconStop className="mb-4 mx-auto" width={64} height={64} />
        <div className="answers-limit-exceeded__title mb-4 text-xl tracking-tight">
          {interfaceText.testIsntAcceptingNewResponses[locale]}
        </div>
        {verificationCode && (
          <CodeBlock 
            verificationCode={verificationCode} 
          />
        )}
      </div>
    </div>
  );
};

export default AnswersLimitExceeded;

import React from 'react';

import {  Heatmap, Clicks } from "../Clickmaps";
export const tabsLabels = {
	HEATMAP: "Heatmap",
	CLICKS: "Clicks",
	IMAGE: "Image",
};

export interface IClicksMapLayerProps {
	width: number;
	height: number;
	responses: any[];
	withScale: (width: number) => string;
	activeTab: string;
}

export function ClicksMapLayerTabs(props: IClicksMapLayerProps) {
	return (
		<>
			<div
				style={{ display: props.activeTab === tabsLabels.HEATMAP ? "block" : "none" }}
			>
				<Heatmap width={props.width} height={props.height} responses={props.responses} />
			</div>
			<div
				style={{ display: props.activeTab === tabsLabels.CLICKS ? "block" : "none" }}
			>
				<Clicks width={props.width} height={props.height} responses={props.responses} withScale={props.withScale} />
			</div>
		</>
	);
}
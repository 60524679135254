import React from "react";
import { ReactComponent as IconDelete } from "../../../icons/delete.svg";
import { IClickmapProps } from './Clickmap';
import { isWithin } from './Utils';

export interface IAreasProps extends Pick<IClickmapProps, 'width' | 'height' | 'areas' | 'updateAreas' | 'responses'> {}

export default function Areas({
  responses,
  areas,
  updateAreas,
  width: imageWidth,
  height: imageHeight,
}: IAreasProps) {

  const areasWithClicksCount = areas.map(a => ({ ...a }));

  responses.forEach(r => {
    const { clickData } = r;
    const clickX = clickData.left * imageWidth;
    const clickY = clickData.top * imageHeight;

    areasWithClicksCount.forEach(area => {
      if (!area.clicksCount) { area.clicksCount = 0; }

      const areaMinX = area.left;
      const areaMaxX = area.left + area.width;
      const areaMinY = area.top;
      const areaMaxY = area.top + area.height;

      if (isWithin(clickX, areaMinX, areaMaxX) && isWithin(clickY, areaMinY, areaMaxY)) {
        area.clicksCount += 1;
      }

    });
  })

  areasWithClicksCount.forEach(area => {
    area.share = (area.clicksCount as number) > 0 ? Math.round(((area.clicksCount as number) / responses.length) * 100) : 0;
  });

  const removeArea = (index: number) => {
    //.sort((a, b) => a.index - b.index)
    const newAreas = areas.filter(a => a.index !== index).map((a, i) => ({ ...a, index: i + 1 }));
    updateAreas(newAreas);
  };

  return (
    <div>
      <div className="text-xl text-gray-600 tracking-tight">Click areas</div>

      {areasWithClicksCount.length === 0 && (
        <div className="text-gray-600 mt-2 text-sm">
          Draw a rectangle on image to create a click area
        </div>
      )}

      {areasWithClicksCount.map(({ index, ...area }) => {
        return (
          <div className="flex items-center mt-3" key={index}>
            <div
              className="rounded-full bg-gray-800 text-white px-2 mr-3 text-center"
              style={{ minWidth: 32, lineHeight: "32px" }}
            >
              {index}
            </div>
            <div className="mr-1">{area.clicksCount} clicks</div>
            <div className="text-blue-700">({area.share}%)</div>
            <div className="ml-auto">
              <IconDelete
                className={`transition duration-75 ease-in fill-current text-gray-400 cursor-pointer hover:text-gray-900`}
                onClick={() => removeArea(index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

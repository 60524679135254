import React from "react";
import "../../tailwind.generated.css";

const QuestionReport = ({
  type,
  replies,
  responses,
  questionText,
  withOther,
}) => {
  const findReplyNameById = (id) => {
    if (id === "other") {
      return "Other";
    }
    return replies.find((element) => element.id === id).replyValue;
  };

  const data = {};
  const total = {};

  if (type === "multi" || type === "single") {
    total.totalSelectedOptions = 0;
    [...replies, ...(withOther ? [{ id: "other" }] : [])].forEach((reply) => {
      data[reply.id] = 0;
    });
    responses.forEach((response) => {
      response.selectedOptions.forEach((option) => {
        if (!isNaN(data[option.id])) {
          data[option.id]++;
          total.totalSelectedOptions++;
        } else {
          return;
        }
      });
    });
  }

  if (type === "multi" || type === "single") {
    const responsesWithOther = responses.filter((response) => {
      return response.selectedOptions.some((option) => option.id === "other");
    });
    return (
      <>
        <div className="flex justify-between items-center mb-4">
          <div className="text-xl font-medium">{questionText}</div>
        </div>
        {Object.keys(data).map((key) => (
          <div className="my-6" key={key}>
            <div className="h-1 bg-gray-300 rounded-full w-full relative">
              <div
                className="bg-orange-600 h-1 rounded-full"
                style={{
                  width: `${(data[key] / responses.length) * 100}%`,
                }}
              ></div>
            </div>
            <div className="flex justify-between w-full items-center mt-2">
              <div className="font-medium">{findReplyNameById(key)}</div>
              <div className="font-medium">
                {Math.round((data[key] / responses.length) * 100) +
                  "%"}
                <span className="font-normal text-gray-600 text-xs">
                  &nbsp;({data[key]})
                </span>
              </div>
            </div>
          </div>
        ))}
        {withOther && responsesWithOther.length > 0 && (
          <div>
            <div className="text-sm font-medium text-gray-600">
              Other responses:
            </div>
            <div className="flex flex-col items-start flex-wrap justify-start my-2">
              {responsesWithOther.map((response, index) => (
                <div key={`response-${index}`} className="border-gray-300 rounded-2xl border-2 px-2 py-1 mr-1 my-1 font-medium">
                  {
                    response.selectedOptions.find(
                      (option) => option.id === "other"
                    ).value
                  }
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }

  if (type === "freeform") {
    const totalResponded = responses.length;

    return (
      <>
        <div className="flex justify-between items-center mb-6">
          <div className="text-xl font-medium">{questionText}</div>
        </div>
        {responses.map((response, index) => (
          <div className="mb-4" key={`freeform-response-${index}`}>
            <div className="font-medium">{response.textValue}</div>
            <div className="text-xs text-gray-600">{response.answerId}</div>
          </div>
        ))}
      </>
    );
  }

  return null;
};

export default QuestionReport;

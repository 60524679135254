import React, { useState, useEffect } from "react";
import "../../tailwind.generated.css";

import { ReactComponent as IconClose } from "../../icons/cross.svg";
import { ReactComponent as IconBack } from "../../icons/back.svg";
import { ReactComponent as IconForward } from "../../icons/forward.svg";
import Modal from "../Modal";
import Loader from "../Loader";
import Button from "../Button";

import { locale, interfaceText } from "../../helpers";

import _ from "lodash";

const defaultBorderColor = "#EDEDED";

const BottomBar = ({ handler }) => {
  return (
    <div className="sticky bottom-0 py-8 w-full">
      <div className="flex items-center justify-center w-full">
        <span className="block text-center mx-4">
          <Button
            type="primary"
            name={interfaceText.test[locale].nextButton}
            handler={() => {
              handler();
            }}
            className="mx-auto shadow-xl"
            large
          />
        </span>
      </div>
    </div>
  );
};

const PreferenceOption = ({
  id,
  replyValue,
  image,
  onImageLoaded,
  setIsZoomModalOpen,
  setSelectedOptionId,
  handler,
  doNotShowZoomedImage,
  isSelected,
}) => {
  return (
    <div
      // onMouseOver={() => {
      //   document.getElementById(id).style.borderColor = "#2565D9";
      // }}
      // onMouseOut={() => {
      //   document.getElementById(id).style.borderColor = defaultBorderColor;
      // }}
      id={id}
      className={`preferenceOption p-4 bg-white text-xl my-3 rounded-lg border-3 cursor-pointer transition-all duration-75 ease-in border-gray-300 flex flex-col justify-between relative h-full hover:border-blue-600`}
      style={
        doNotShowZoomedImage && isSelected
          ? { borderColor: "#2565D9" }
          : undefined
      }
      key={id}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedOptionId(id);
        handler("selectedOptions", [{ id: id, replyValue: replyValue }]);
        if (!doNotShowZoomedImage) {
          setIsZoomModalOpen(true);
        }
      }}
    >
      <div className="flex items-start justify-center flex-1 p-2">
        <img
          src={image}
          alt=""
          className="h-auto flex-1"
          onLoad={() => {
            onImageLoaded(`reply.${id}.image`);
          }}
        />
      </div>
      <div className="text-center h-12 flex items-center justify-center">
        {replyValue}
      </div>
    </div>
  );
};

const PreferenceSelect = ({
  data,
  handler,
  isRandomized,
  doNotShowZoomedImage,
  onImageLoaded,
  nextStepOnClick,
}) => {
  const [options, setOptions] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [isZoomedImgLoaded, setIsZoomImgLoaded] = useState(false);

  useEffect(() => {
    setOptions(isRandomized ? _.shuffle(data) : data);
    setSelectedOptionId(null);
    setIsZoomModalOpen(false);
    setIsZoomImgLoaded(false);
  }, [data]);

  const handleSwitchOptionWithArrows = (direction) => {
    setIsZoomImgLoaded(false);

    if (direction === "forward") {
      setSelectedOptionId(
        options[
          options.findIndex((option) => option.id === selectedOptionId) + 1
        ].id
      );
    } else {
      setSelectedOptionId(
        options[
          options.findIndex((option) => option.id === selectedOptionId) - 1
        ].id
      );
    }
  };

  const isMobile = window.screen.width <= 640;
  const repliesTotal = options?.length;

  // TODO: What if >5 options?

  const columnStylesMap = {
    1: "grid-cols-1",
    2: "grid-cols-2",
    3: "grid-cols-3",
    4: "grid-cols-4",
    5: "grid-cols-5",
  };

  return (
    <>
      <div className="w-screen flex items-center justify-center">
        {options &&
          (isMobile ? (
            <div className="flex items-start justify-start overflow-x-auto px-4 preferenceScrollContainer">
              {options.map((item) => (
                <div
                  className="flex-shrink-0 p-2"
                  style={{ width: "90vw", height: "auto" }}
                  key={`options-map-item-${item.id}`}
                >
                  <PreferenceOption
                    key={item.id}
                    id={item.id}
                    replyValue={item.replyValue}
                    image={item.image}
                    onImageLoaded={onImageLoaded}
                    setIsZoomModalOpen={setIsZoomModalOpen}
                    setSelectedOptionId={setSelectedOptionId}
                    doNotShowZoomedImage={doNotShowZoomedImage}
                    handler={handler}
                    isSelected={selectedOptionId === item.id}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={`grid ${columnStylesMap[repliesTotal]}`}>
              {options.map((item) => (
                <div className="mx-5 mb-6" key={`options-map-item-${item.id}`}>
                  <PreferenceOption
                    key={item.id}
                    id={item.id}
                    replyValue={item.replyValue}
                    image={item.image}
                    onImageLoaded={onImageLoaded}
                    setIsZoomModalOpen={setIsZoomModalOpen}
                    setSelectedOptionId={setSelectedOptionId}
                    doNotShowZoomedImage={doNotShowZoomedImage}
                    handler={handler}
                    isSelected={selectedOptionId === item.id}
                  />
                </div>
              ))}
            </div>
          ))}
      </div>
      {doNotShowZoomedImage && selectedOptionId && (
        <BottomBar handler={nextStepOnClick} />
      )}
      {isZoomModalOpen && (
        <Modal
          width="100%"
          height="100%"
          isOpen={isZoomModalOpen}
          setIsOpen={setIsZoomModalOpen}
          noRoudnedCorners
        >
          <div className="p-6 h-auto min-h-full flex items-center justify-center">
            {!isZoomedImgLoaded && <Loader />}
            <img
              src={
                options.find((option) => option.id === selectedOptionId).image
              }
              alt=""
              onLoad={() => {
                setIsZoomImgLoaded(true);
              }}
              className={isZoomedImgLoaded ? "block" : "hidden"}
            />
          </div>
          <div
            className="absolute text-white top-4 right-4 p-1 rounded-full bg-black"
            onClick={() => {
              setIsZoomModalOpen(false);
              setSelectedOptionId(null);
            }}
          >
            <IconClose
              width={20}
              height={20}
              className="fill-current text-white cursor-pointer hover:opacity-50 transition-all duration-75 ease-in"
            />
          </div>
          <div className="absolute bottom-0 py-8 w-full bg-gradient-to-b from-transparent to-gray-800">
            <div className="flex items-center justify-center w-full">
              <IconBack
                width={32}
                height={32}
                className={`fill-current shadow-md ${
                  selectedOptionId === options[0].id
                    ? "text-gray-500 cursor-not-allowed"
                    : "text-black cursor-pointer hover:text-gray-700"
                } transition-all duration-75 ease-in bg-white p-1 rounded-full`}
                onClick={() => {
                  if (selectedOptionId !== options[0].id) {
                    handleSwitchOptionWithArrows("back");
                  }
                }}
              />
              <span className="block text-center mx-4">
                <Button
                  type="primary"
                  name={
                    <>
                      {interfaceText.test[locale].preferenceNextButton}&nbsp;
                      <span className="opacity-50">
                        {"(" +
                          (options.findIndex(
                            (option) => option.id === selectedOptionId
                          ) +
                            1) +
                          "/" +
                          repliesTotal +
                          ")"}
                      </span>
                    </>
                  }
                  handler={() => {
                    nextStepOnClick();
                  }}
                  className="mx-auto shadow-xl"
                  large
                />
              </span>
              <IconForward
                width={32}
                height={32}
                className={`fill-current shadow-md ${
                  selectedOptionId === options[repliesTotal - 1].id
                    ? "text-gray-500 cursor-not-allowed"
                    : "text-black cursor-pointer hover:text-gray-700"
                } transition-all duration-75 ease-in bg-white p-1 rounded-full`}
                onClick={() => {
                  if (selectedOptionId !== options[repliesTotal - 1].id) {
                    handleSwitchOptionWithArrows("forward");
                  }
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PreferenceSelect;

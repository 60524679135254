import { db, getAuthToken, Timestamp } from "./firebase";
import _ from "lodash";
import uniqid from "uniqid";
import { generateVerificationCode, generateId } from "./utils";
import { getBlockLogic } from "./utils/tests";
import { makeAuthorizedRequest } from "./http/Client";

export const updateCredits = (value) => {
  return {
    type: "UPDATE_CREDITS",
    payload: {
      value: value,
    },
  };
};

export const getUserData = async (user) => {
  const authData = {
    userId: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
  };

  const userDataResponse = await makeAuthorizedRequest(
    "/api/user/getUserData",
    "POST",
    authData
  );

  if (userDataResponse.ok) {
    return {
      type: "GET_USER_DATA",
      payload: await userDataResponse.json(),
    };
  }
  throw new Error("http_error", { cause: userDataResponse });
};

export const fetchTest = async (testId, sharingToken) => {
  const URL = sharingToken
    ? `/api/tests/${testId}?sharingToken=${sharingToken}`
    : `/api/tests/${testId}`;

  return await fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getTest = async (testId) => {
  const testResponse = await makeAuthorizedRequest(
    "/api/user/getTest",
    "POST",
    { testId }
  );

  if (testResponse.ok) {
    return {
      type: "GET_TEST",
      payload: {
        testId: testId,
        data: await testResponse.json(),
      },
    };
  }

  throw new Error("http_error", { cause: testResponse });
};

export const getTests = async (folderId) => {
  const testsResponse = await makeAuthorizedRequest(
    "/api/user/getTests",
    "POST",
    { folderId }
  );

  if (testsResponse.ok) {
    return {
      type: "GET_TESTS",
      payload: {
        tests: await testsResponse.json(),
      },
    };
  }

  throw new Error("http_error", { cause: testsResponse });
};

// todo: this is not an action
export const createAnswer = async (testId, meta, urlParams) => {
  return await fetch(`/api/tests/${testId}/answers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ meta, urlParams }),
  });
};

const getInitialTestData = (userId, folderId, projectId) => ({
  answers: {},
  name: "New test",
  status: "draft",
  userId: userId,
  createdAt: Timestamp.now(),
  verificationCode: generateVerificationCode(),
  sharingToken: uniqid(),
  content: [],
  contentPreview: [],
  backgroundColor: "#FFFFFF",
  buttonsColor: "#0163E1",
  buttonsTextColor: "#FFFFFF",
  folderId: folderId,
  answersCounter: 0,
  ownerId: projectId || userId,
  ...(projectId ? { projectId: projectId } : {}),
});

export const createTest = async (userId, folderId, projectId) => {
  const testInitialData = getInitialTestData(userId, folderId, projectId);

  const testDoc = await db.collection("tests").add(testInitialData);

  return {
    type: "CREATE_TEST",
    payload: {
      data: { [testDoc.id]: testInitialData },
    },
  };
};

export const createFolder = async (userId, projectId) => {
  const folderInitialData = {
    name: "New folder",
    createdAt: Timestamp.now(),
    userId: userId,
    ownerId: projectId || userId,
    ...(projectId ? { projectId: projectId } : {}),
  };

  const doc = await db.collection("folders").add(folderInitialData);

  return {
    type: "CREATE_FOLDER",
    payload: {
      data: { [doc.id]: folderInitialData },
    },
  };
};

export const deleteFolder = async (folderId) => {
  const authToken = await getAuthToken();
  const deleteFolderResponse = await fetch(`/api/folders/${folderId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Barrier ${authToken}`,
    },
  });

  if (!deleteFolderResponse.ok) {
    throw "Impossible to delete folder.";
  }

  return {
    type: "DELETE_FOLDER",
    payload: {
      folderId: folderId,
    },
  };
};

export const duplicateTest = async (testId) => {
  const test = await db
    .collection("tests")
    .doc(testId)
    .get()
    .then((doc) => {
      return doc.data();
    });

  const folderId = _.get(test, "folderId", "root");
  const testData = getInitialTestData(test.userId, folderId, test.projectId);

  testData.status = "draft";
  testData.name = `${test.name} (copy)`;
  testData.splitGroup = test.splitGroup || null;

  const idsMap = _.mapValues(_.keyBy(test.content, "blockId"), () =>
    generateId()
  );

  test.content.forEach((blockContent) => {
    const sourceBlockId = blockContent.blockId;
    const newBlockId = idsMap[sourceBlockId];
    const block = {
      ..._.cloneDeep(blockContent),
      blockId: newBlockId,
    };

    const logic = getBlockLogic(block);

    if (logic) {
      const elseJumpTo = logic.elseJumpTo;
      if (idsMap[elseJumpTo]) logic.elseJumpTo = idsMap[elseJumpTo];
      logic.statements.forEach((statement, index) => {
        const jumpTo = statement.jumpTo;
        if (idsMap[jumpTo]) logic.statements[index].jumpTo = idsMap[jumpTo];
      });

      block.logic = logic;
      console.log(logic);
    }

    testData.content.push(block);
  });

  test.contentPreview.forEach((blockPreview) => {
    const sourceBlockId = blockPreview.blockId;
    const newBlockId = idsMap[sourceBlockId];
    const block = {
      blockId: newBlockId,
      type: blockPreview.type,
    };

    testData.contentPreview.push(block);
  });

  const testDoc = await db.collection("tests").add(testData);

  return {
    type: "CREATE_TEST",
    payload: {
      data: { [testDoc.id]: testData },
    },
  };
};

export const getPublishedTestContent = async (testId) => {
  const publishedContent = await db
    .collection("tests")
    .doc(testId)
    .get()
    .then((doc) => {
      return doc.data().publishedContent;
    });

  return {
    type: "GET_PUBLISHED_TEST_CONTENT",
    payload: {
      content: publishedContent,
      testId: testId,
    },
  };
};

export const updateTest = async (data, testId) => {
  const authToken = await getAuthToken();
  const URL = "/api/user/updateTest";

  try {
    await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
      body: JSON.stringify({ data: data, testId: testId }),
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return {
    type: "UPDATE_TEST",
    payload: {
      data: data,
      testId: testId,
    },
  };
};

export const updateFolder = async (data, folderId) => {
  db.collection("folders")
    .doc(folderId)
    .update({
      ...data,
    });

  return {
    type: "UPDATE_FOLDER",
    payload: {
      data: data,
      folderId: folderId,
    },
  };
};

export const sendAnswer = async (data, answerId, blockId, testId) => {
  return await fetch(`/api/tests/${testId}/answers/${answerId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data, blockId }),
  });
};
// todo: this is not an action
export const aproveAnswer = async (
  answerId,
  testId,
  isAnswersLimitExceeded
) => {
  return await fetch(`/api/tests/${testId}/answers/${answerId}/approve`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isAnswersLimitExceeded: !!isAnswersLimitExceeded }),
  });
};

export const deleteAnswer = async (answerId, testId) => {
  await fetch(`/api/tests/${testId}/answers/${answerId}/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return {
    type: "DELETE_ANSWER",
    payload: {
      testId: testId,
      answerId: answerId,
    },
  };
};

export const setUserData = async (userId, data) => {
  db.collection("users")
    .doc(userId)
    .update({
      ...data,
    });

  return {
    type: "SET_USER_DATA",
    payload: {
      userId: userId,
      data: data,
    },
  };
};

export const setNotification = (type, text) => {
  return {
    type: "SET_NOTIFICATION",
    payload: {
      type: type,
      text: text,
    },
  };
};

export const setUi = (key, value) => {
  return {
    type: "SET_UI",
    payload: {
      key: key,
      value: value,
    },
  };
};

export const fetchPrototype = async (code) =>
  await fetch(`/api/prototypes/${code}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

// Teams actions

export const getTeam = async (projectId) => {
  const authToken = await getAuthToken();
  const URL = `/api/teams/getTeam`;

  const testsResponse = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Barrier ${authToken}`,
    },
    body: JSON.stringify({ projectId: projectId }),
  });

  if (testsResponse.ok) {
    return {
      type: "GET_TEAM",
      payload: {
        team: await testsResponse.json(),
      },
    };
  }

  return undefined;
};

export const getInvite = async (inviteCode) => {
  const authToken = await getAuthToken();
  const URL = `/api/invites/${inviteCode}`;

  let inviteResponse;
  let response;

  try {
    inviteResponse = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
    });
  } catch (error) {
    console.error(error);
    throw "NETWORK_ERROR";
  }

  if (inviteResponse.ok) {
    return (await inviteResponse.json()).invite;
  }

  try {
    response = await inviteResponse.json();
  } catch (error) {
    throw "SERVER_ERROR";
  }

  throw response.code;
};

export const acceptInvite = async (inviteCode) => {
  const authToken = await getAuthToken();
  const URL = `/api/invites/${inviteCode}/accept`;

  let inviteResponse;
  let response;

  try {
    inviteResponse = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
    });
  } catch (error) {
    console.error(error);
    throw "NETWORK_ERROR";
  }

  if (!inviteResponse.ok) {
    try {
      response = await inviteResponse.json();
    } catch (error) {
      throw "SERVER_ERROR";
    }

    throw response.code;
  }
};

export const createInvite = async (projectId) => {
  const authToken = await getAuthToken();
  const URL = "/api/invites";

  let response;
  let message;

  try {
    response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
      body: JSON.stringify({
        projectId,
      }),
    });
  } catch (error) {
    throw "NETWORK_ERROR";
  }

  try {
    message = await response.json();
  } catch (error) {
    throw "SERVER_ERROR";
  }

  if (!response.ok) {
    throw message.code;
  }

  return process.env.NODE_ENV === "development"
    ? `http://${window.location.host}/invite/${message.invite.code}`
    : `https://app.pthwy.design/invite/${message.invite.code}`;
};
export const updateRole = async (projectId, memberId, role) => {
  const authToken = await getAuthToken();
  const URL = `/api/teams/updateTeamMemberRole`;

  const testsResponse = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Barrier ${authToken}`,
    },
    body: JSON.stringify({
      projectId: projectId,
      memberId: memberId,
      role: role,
    }),
  });

  if (testsResponse.ok) {
    return {
      type: "UPDATE_ROLE",
      payload: {
        memberId: memberId,
        role: role,
      },
    };
  }

  return undefined;
};

export const removeMember = async (projectId, memberId) => {
  const authToken = await getAuthToken();
  const URL = `/api/teams/removeTeamMember`;

  const testsResponse = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Barrier ${authToken}`,
    },
    body: JSON.stringify({
      projectId: projectId,
      memberId: memberId,
    }),
  });

  if (testsResponse.ok) {
    return {
      type: "REMOVE_MEMBER",
      payload: {
        memberId: memberId,
      },
    };
  }

  return undefined;
};

export const updateTeam = async (projectId, field, value) => {
  const authToken = await getAuthToken();
  const URL = `/api/teams/updateTeam`;

  const testsResponse = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Barrier ${authToken}`,
    },
    body: JSON.stringify({
      projectId: projectId,
      field: field,
      value: value,
    }),
  });

  if (testsResponse.ok) {
    return {
      type: "UPDATE_TEAM",
      payload: {
        field: field,
        value: value,
      },
    };
  }

  return undefined;
};
